import CodeIcon from '@mui/icons-material/Code';
import { Tag } from '@components';
import CSharpLogo from './svg/cloud_resource_runtime_logos/csharp.png';
import JavaLogo from './svg/cloud_resource_runtime_logos/java.png';
import NodeJsLogo from './svg/cloud_resource_runtime_logos/nodejs.png';
import PythonLogo from './svg/cloud_resource_runtime_logos/python.png';

export const SUPPORTED_RUNTIMES = {
  CSHARP: {
    component: <img alt="CSharp icon" height={14} src={CSharpLogo} width={14} />,
    keywords: ['csharp', 'c#'],
  },
  JAVA: {
    component: <img alt="Java icon" height={16} src={JavaLogo} width={12} />,
    keywords: ['java'],
  },
  NODE: {
    component: (
      <img
        alt="NodeJS icon"
        height={22}
        src={NodeJsLogo}
        style={{
          left: -2,
          marginLeft: -4,
          marginRight: -8,
          position: 'relative',
        }}
        width={22}
      />
    ),
    keywords: ['node'],
  },
  PYTHON: {
    component: <img alt="Python icon" height={14} src={PythonLogo} width={14} />,
    keywords: ['python'],
  },
};

const RuntimeLogo = props => {
  const { runtime } = props;

  const lowerCaseRuntime = runtime.toLocaleLowerCase();
  for (const k in SUPPORTED_RUNTIMES) {
    for (const keyword of SUPPORTED_RUNTIMES[k].keywords) {
      // NOTE: This logic is flimsy but until the backend solidifies the possible values for this we get to parse it.
      if (lowerCaseRuntime.indexOf(keyword) >= 0) {
        return SUPPORTED_RUNTIMES[k].component;
      }
    }
  }

  return <CodeIcon />;
};

const TagLanguageRuntime = ({ runtime }) => {
  return <Tag icon={<RuntimeLogo runtime={runtime} />} label={runtime} />;
};

export default TagLanguageRuntime;
