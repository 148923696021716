import { memo } from 'react';
import { Grid, Tooltip } from '@mui/material';
import Box from '@mui/material/Box';
import { makeStyles } from '@mui/styles';
import { palette } from '@dt/theme';

const useStyles = makeStyles({
  icon: {
    marginRight: 2,
    padding: 2,
  },
  label: {
    alignItems: 'center',
    backgroundColor: palette.gray45,
    borderRadius: 2,
    color: palette.gray30,
    display: 'flex',
    fontSize: 12,
    padding: '0 6px',
    width: 'auto',
  },
  text: {
    padding: '4px 2px',
  },
});

function CountAsset({ name, count, Icon }) {
  const classes = useStyles();

  return (
    <Box component={Grid} item>
      <Tooltip aria-label={`${count} ${name}`} disableInteractive title={`${count} ${name}`}>
        <Box className={classes.label}>
          {Icon ? <Icon className={classes.icon} fontSize={'small'} /> : null}
          <span className={classes.text}>{`${count} ${name}`}</span>
        </Box>
      </Tooltip>
    </Box>
  );
}

export default memo(CountAsset);
