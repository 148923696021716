import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { memo, useEffect, useMemo, useState } from 'react';
import { Waypoint } from 'react-waypoint';
import SearchIcon from '@mui/icons-material/Search';
import { Grid, List, ListItem, TextField } from '@mui/material';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
// @ts-ignore JS component
import { useLazyQuery } from '@dt/apollo-link-schema-rest';
// @ts-ignore JS component
import { ErrorState } from '@dt/components';
import asset_tags from '@dt/graphql-support/horizon/ts/asset_tags';
import debounce from 'lodash/fp/debounce';
import TableFiltersAssetTagKeyItem from './TableFiltersAssetTagKeyItem';
const InventoryTableFilterAssetTagsComponent = function InventoryTableFilterAssetTags({ selectedAssetTags, onToggleAssetTagKey, onToggleAssetTagValue, }) {
    const [fetchAssetTagKeysQuery, { data, error, loading, called, fetchMore, pageNumber }] = useLazyQuery(asset_tags.list, {
        fetchPolicy: 'network-only',
    });
    useEffect(() => {
        if (!called) {
            fetchAssetTagKeysQuery({});
        }
    }, [fetchAssetTagKeysQuery, called]);
    const [filter, setFilter] = useState('');
    const handleChange = useMemo(() => debounce(500)((filter) => fetchAssetTagKeysQuery(filter
        ? {
            variables: {
                filter_by_text: filter,
            },
        }
        : {})), [fetchAssetTagKeysQuery]);
    return (_jsxs(Grid, { container: true, spacing: 1, children: [_jsx(Grid, { item: true, xs: 12, children: _jsx(TextField, { InputProps: {
                        endAdornment: _jsx(SearchIcon, {}),
                    }, fullWidth: true, id: "asset-keys-filter", label: 'Filter', onChange: e => {
                        setFilter(e.target.value);
                        handleChange(e.target.value);
                    }, value: filter }) }), _jsx(Grid, { item: true, xs: 12, children: _jsxs(List, { component: "nav", children: [error ? (_jsx(ErrorState, { error: error })) : !pageNumber && loading ? ([0, 1, 2, 3, 4].map((_, key) => (_jsx(ListItem, { button: true, dense: true, children: _jsx(Skeleton, { animation: "wave", height: 40, width: `100%` }) }, key)))) : (data?.asset_tag_key_list.asset_tag_keys.map((assetTagKey, key) => assetTagKey && (_jsx(TableFiltersAssetTagKeyItem, { assetTagKey: assetTagKey, onToggleAssetTagKey: onToggleAssetTagKey, onToggleAssetTagValue: onToggleAssetTagValue, selectedAssetTags: selectedAssetTags }, key)))), fetchMore && (_jsxs(_Fragment, { children: [_jsxs(Box, { component: 'div', children: [_jsx(ListItem, { button: true, dense: true, children: _jsx(Skeleton, { animation: "wave", height: 40, width: `100%` }) }), _jsx(ListItem, { button: true, dense: true, children: _jsx(Skeleton, { animation: "wave", height: 40, width: `100%` }) }), _jsx(ListItem, { button: true, dense: true, children: _jsx(Skeleton, { animation: "wave", height: 40, width: `100%` }) })] }), _jsx(Waypoint, { onEnter: () => {
                                        if (fetchMore) {
                                            fetchMore();
                                        }
                                    } })] }))] }) })] }));
};
export default memo(InventoryTableFilterAssetTagsComponent);
