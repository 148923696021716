export const getDomainFromUrl = url => {
  const anchor = global.document.createElement('a');
  anchor.href = url;
  return anchor.hostname;
};

export const ExternalLogo = ({ domain, alt, title, size }) => {
  return (
    <div style={{ height: size, width: size }} title={title}>
      <img alt={alt} height={size} src={`https://logo.clearbit.com/${domain}`} width={size} />
    </div>
  );
};
