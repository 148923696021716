import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import InfoIcon from '@mui/icons-material/Info';
import Text from '@dt/ahura/src/components/Text';
// @ts-ignore: #TODO-IMPLEMENT-TYPES-THEME
import { palette } from '@dt/theme';
const productMapping = {
    api: 'API',
    cloud: 'Cloud',
    dashboard: 'Dashboard',
    mobile: 'Mobile',
    openscan: 'Supply Chain Security',
    web: 'Web',
};
const NavigationNoAccess = ({ product }) => {
    return (_jsxs("div", { style: {
            alignItems: 'center',
            backgroundColor: palette.blue50,
            display: 'flex',
            justifyContent: 'center',
            padding: 16,
        }, children: [_jsx(InfoIcon, { style: { color: palette.blue10, marginRight: 16 } }), _jsx(Text, { component: "div", variant: "titleXS", children: `${productMapping[product]} Secure is not currently licensed. Please contact` }), _jsx("a", { href: "mailto:support@datatheorem.com", children: _jsx(Text, { component: "div", style: { color: 'rgb(0,0,238)' }, variant: "titleXS", children: "\u00A0support@datatheorem.com\u00A0" }) }), _jsx(Text, { component: "div", variant: "titleXS", children: "for temporary access and/or live demo of your results." })] }));
};
export default NavigationNoAccess;
