import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Waypoint } from 'react-waypoint';
import { Button, DialogActions, DialogContent, Grid, MenuItem, Select } from '@mui/material';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
// @ts-ignore: #TODO-IMPLEMENT-TYPES-MATERIAL-COMPONENTS
import { Text } from '@dt/material-components';
import PolicyViolationExportToJiraDialogHeader from './PolicyViolationExportToJiraDialogHeader';
const PolicyViolationExportToJiraDialogStep1 = ({ handleClose, handleNext, jiraIntegration, setJiraIntegration, integrations, fetchMore, }) => {
    return (_jsxs(_Fragment, { children: [_jsx(PolicyViolationExportToJiraDialogHeader, { handleClose: handleClose, title: "Export Policy Violation to Jira" }), _jsx(DialogContent, { children: _jsxs(Grid, { container: true, spacing: 1, children: [_jsx(Grid, { item: true, xs: 12, children: _jsx(Box, { mb: 2, mt: 2, children: _jsx(Text, { component: "span", variant: "body", children: "Choose which Jira integration the policy violation should be exported to:" }) }) }), _jsx(Grid, { item: true, xs: 12, children: _jsxs(Select, { displayEmpty: true, fullWidth: true, onChange: e => {
                                    const integrationId = e.target.value;
                                    const integration = integrations.find(({ id }) => id === integrationId);
                                    if (integration) {
                                        setJiraIntegration(integration);
                                    }
                                }, value: jiraIntegration?.id, variant: "outlined", children: [[
                                        _jsx(MenuItem, { disabled: true, value: "", children: _jsx(Text, { component: "span", mb: 1, variant: "body", children: "Choose Jira integration" }) }, "select-header"),
                                        integrations?.map((row) => (_jsx(MenuItem, { value: row.id, children: _jsx(Text, { component: "span", mb: 1, variant: "body", children: row.name }) }, row.id))),
                                    ], fetchMore && (_jsxs(_Fragment, { children: [_jsx(MenuItem, { dense: true, children: _jsx(Skeleton, { animation: "wave", height: 40, width: `100%` }) }), _jsx(Waypoint, { onEnter: () => {
                                                    if (fetchMore) {
                                                        fetchMore();
                                                    }
                                                } })] }))] }) })] }) }), _jsxs(DialogActions, { children: [_jsx(Button, { onClick: handleClose, children: "Cancel" }), _jsx(Button, { color: "primary", disabled: !jiraIntegration, onClick: handleNext, children: "Next" })] })] }));
};
export default PolicyViolationExportToJiraDialogStep1;
