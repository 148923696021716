import Skeleton from '@mui/material/Skeleton';
import { AssetTypeEnumValues } from '@dt/graphql-support/enums';
import { SharedLinksButton } from '@dt/material-components';

const sharedLinksAssetPropMap = {
  ['CLOUD_RESOURCE']: 'cloud_resource_id',
  ['GRAPHQL_API']: 'graphql_api_id',
  ['NETWORK_SERVICE']: 'network_service_id',
  ['RESTFUL_API']: 'restful_api_id',
  ['WEB_APPLICATION']: 'web_application_id',
};

export const AssetsSharedLinksButton = ({ isLoading, asset }) => {
  // Hide secure share until backend is implemented
  if (
    [AssetTypeEnumValues.KUBERNETES_CLUSTER, AssetTypeEnumValues.KUBERNETES_CLUSTER_COMPONENT].includes(
      asset?.asset_type,
    )
  ) {
    return null;
  }

  if (isLoading || !asset) {
    return <Skeleton height={40} variant="rectangular" width={150} />;
  }
  if (!sharedLinksAssetPropMap[asset.asset_type]) {
    return null;
  }

  const props = { [sharedLinksAssetPropMap[asset.asset_type]]: asset.id };

  return <SharedLinksButton {...props} />;
};
