import { memo } from 'react';
import LaunchIcon from '@mui/icons-material/Launch';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import { Grid, Tooltip, Typography } from '@mui/material';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { makeStyles } from '@mui/styles';
import { palette } from '@dt/theme';

const useStyles = makeStyles({
  cell: {
    padding: 8,
  },
  icon: {
    borderRadius: 100,
    height: 24,
    marginLeft: 4,
    width: 24,
  },
  link: {
    color: palette.gray35,
    cursor: 'pointer',
    display: 'grid',
    justifyContent: 'center',
    padding: 4,
  },
  row: {
    backgroundColor: palette.gray45,
  },
  unprotectedContainer: {
    '& p': {
      color: palette.red30,
      paddingLeft: 2,
    },
    '& svg': {
      color: palette.red30,
      fontSize: 20,
    },
    display: 'flex',
    padding: 4,
    width: 140,
  },
});

function ToolkitAssetTableSelectedRowDetails({ policyViolationId, PolicyRuleType, portalPath }) {
  const classes = useStyles();
  return (
    <TableRow classes={{ root: classes.row }}>
      <TableCell classes={{ root: classes.cell }} colSpan={4}>
        <Grid alignItems={'center'} container>
          <Grid item>
            <Tooltip
              aria-label={PolicyRuleType.description}
              disableInteractive
              placement="bottom"
              title={PolicyRuleType.description}
            >
              <Typography>{PolicyRuleType.title}</Typography>
            </Tooltip>
          </Grid>

          <Grid item>
            <div className={classes.unprotectedContainer}>
              <Typography className={classes.icon}>
                <ReportProblemIcon />
              </Typography>
              <Typography>Unprotected</Typography>
            </div>
          </Grid>
          <Grid item>
            <a
              className={classes.link}
              href={`${portalPath}/policy-violations/${policyViolationId}`}
              rel="noopener noreferrer"
              target="_blank"
            >
              <Grid alignItems={'center'} container spacing={1}>
                <Grid item>
                  <Typography>More Details</Typography>
                </Grid>
                <Grid item>
                  <LaunchIcon />
                </Grid>
              </Grid>
            </a>
          </Grid>
        </Grid>
      </TableCell>
    </TableRow>
  );
}

export default memo(ToolkitAssetTableSelectedRowDetails);
