import { Divider, ListItem, ListItemText, Typography } from '@mui/material';
import { palette } from '@dt/theme';
import { WebApplicationNetworkActivityIcon } from './WebApplicationNetworkActivityIcon';

export const WebApplicationDetectedNetworkActivity = ({ detected_network_activity }) => {
  return (
    <>
      {detected_network_activity.length > 0 ? (
        detected_network_activity
          .slice()
          .sort((a, b) => a.base_url.localeCompare(b.base_url))
          .map(a => (
            <div key={a.base_url}>
              <a href={a.base_url} rel="noopener noreferrer" target="_blank">
                <ListItem button dense>
                  <ListItemText
                    primary={<span style={{ color: palette.gray20, display: 'flex' }}>{a.base_url}</span>}
                    secondary={a.request_types.map(request_type => (
                      <WebApplicationNetworkActivityIcon key={request_type} network_activity_type={request_type} />
                    ))}
                    secondaryTypographyProps={{ component: 'div' }}
                  />
                </ListItem>
              </a>
              <Divider />
            </div>
          ))
      ) : (
        <ListItem>
          <ListItemText primary={<Typography variant="body2">No network activity detected.</Typography>} />
        </ListItem>
      )}
    </>
  );
};
