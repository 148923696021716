import { AssetTypeEnumValues } from '@dt/graphql-support/enums';

// prettier-ignore
const AssetTypeNameLookup         = Object.freeze({
  [AssetTypeEnumValues.API_OPERATION]:      'API Operation',
  [AssetTypeEnumValues.CLOUD_RESOURCE]:     'Cloud Resource',
  [AssetTypeEnumValues.DOMAIN_NAME]:        'Domain Name',
  [AssetTypeEnumValues.GRAPHQL_API]:        'GraphQL API',
  [AssetTypeEnumValues.MOBILE_APPLICATION]: 'Mobile Application',
  [AssetTypeEnumValues.NETWORK_SERVICE]:    'Network Service',
  [AssetTypeEnumValues.RESTFUL_API]:        'RESTful API',
  [AssetTypeEnumValues.WEB_APPLICATION]:    'Web Application',
  [AssetTypeEnumValues.MOBILE_SDK]:         'Mobile SDK',
  [AssetTypeEnumValues.GRPC_SERVICE]:       'gRPC Service',
  [AssetTypeEnumValues.GRPC_METHOD]:        'gRPC Method',
  [AssetTypeEnumValues.KUBERNETES_CLUSTER]: 'Kubernetes Cluster',
  [AssetTypeEnumValues.KUBERNETES_CLUSTER_COMPONENT]: 'Kubernetes Cluster Component',
  [AssetTypeEnumValues.SOAP_API]:           'SOAP API',
  [AssetTypeEnumValues.SOAP_API_OPERATION]: 'SOAP API Operation',
  [AssetTypeEnumValues.REPOSITORY]:         'Repository',
  [AssetTypeEnumValues.CLOUD_IDENTITY]:     'Cloud Identity',
});

/*
 * Gets the display name of the provided asset type.
 *
 * @param asset_type - Name of asset type
 */
export function getDisplayName(asset_type) {
  return AssetTypeNameLookup[asset_type];
}

/*
 * Renders the display name of the provided asset type.
 */
export const AssetsDisplayName = function AssetsDisplayName({ asset_type }) {
  return <>{getDisplayName(asset_type)}</>;
};
