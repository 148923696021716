import SearchIcon from '@mui/icons-material/Search';
import { TextField } from '@mui/material';

/*
 * Popout filter plugin.
 * User can type keywords.
 *
 * @param value - Current state.
 * @param onChange - State change.
 * @param onSubmit - Triggered by user enter key.
 */
const PopoutFilterPluginKeywordComponent = function PopoutFilterPluginKeyword({ value, onChange, onSubmit }) {
  return (
    <TextField
      InputProps={{
        endAdornment: <SearchIcon />,
      }}
      autoFocus
      fullWidth
      label="Enter Keyword"
      onChange={e => onChange(e.target.value)}
      onKeyDown={({ keyCode }) => {
        if (keyCode === 13) {
          onSubmit();
        }
      }}
      value={value}
    />
  );
};

export const PopoutFilterPluginKeyword = PopoutFilterPluginKeywordComponent;
