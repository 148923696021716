import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Menu, MenuItem, useTheme } from '@mui/material';
// @ts-ignore
import { useSession } from '@dt/ahura/src/session';
// @ts-ignore
import DTIcon from '@dt/brand/dt-logo-icon-only';
// @ts-ignore
import APISecureIcon from '@dt/brand/product-icon-api-secure';
// @ts-ignore
import CloudSecureIcon from '@dt/brand/product-icon-cloud-secure';
// @ts-ignore
import MobileSecureIcon from '@dt/brand/product-icon-mobile-secure';
// @ts-ignore
import WebSecureIcon from '@dt/brand/product-icon-web-secure';
// @ts-ignore
import { ExtLink } from '@dt/material-components';
// @ts-ignore
import useScsMenuItem from '../hooks/use_scs_menu_item';
const dtProducts = [
    {
        icon: DTIcon,
        name: 'Dashboard',
        to: '/dashboard',
    },
    {
        icon: APISecureIcon,
        name: 'API Secure',
        to: '/api/v2',
    },
    {
        icon: CloudSecureIcon,
        name: 'Cloud Secure',
        to: '/cloud/v2',
    },
    {
        icon: MobileSecureIcon,
        name: 'Mobile Secure',
        to: '/mobile-secure/v2',
    },
    {
        icon: WebSecureIcon,
        name: 'Web Secure',
        to: '/web/v2',
    },
];
const AppsMenu = ({ anchorEl, handleClose }) => {
    const { loading } = useSession({ unauthenticatedRedirect: true });
    const scsMenuItem = useScsMenuItem({ loading });
    const theme = useTheme();
    if (loading)
        return null;
    return (_jsx(Menu, { anchorEl: anchorEl, anchorOrigin: {
            horizontal: 'right',
            vertical: 'bottom',
        }, keepMounted: true, onClose: handleClose, open: Boolean(anchorEl), children: _jsx("div", { children: [...dtProducts, scsMenuItem].map((product, idx) => {
                const DTProductIcon = product?.icon;
                return (_jsx(ExtLink, { onClick: handleClose, target: "_self", to: product?.to, children: _jsxs(MenuItem, { children: [_jsx("div", { style: {
                                    alignItems: 'center',
                                    display: 'flex',
                                    height: 40,
                                    justifyContent: 'center',
                                    marginRight: '16px',
                                    maxHeight: 40,
                                    maxWidth: 40,
                                    minHeight: 40,
                                    minWidth: 40,
                                    width: 40,
                                }, children: _jsx(DTProductIcon, { backgroundColor: theme.palette.brand[30], foregroundColor: theme.palette.white.main }) }), product?.name] }) }, idx));
            }) }) }));
};
export default AppsMenu;
