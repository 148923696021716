import { memo, useState } from 'react';
import { Grid, LinearProgress, Typography } from '@mui/material';
import useInterval from '@dt/material-components/hooks/use_interval';

const steps = [
  'Prepping Scans & Tests',
  'Scans In Progress',
  'Analyzing Data',
  'Re-verification of scans/tests',
  'Aggregating and Verifying Results',
];

const completedProgress = offset => {
  const currentSecond = Math.floor(((new Date().getSeconds() / 20) % 1) * 100) + offset;
  return currentSecond < 100 ? currentSecond : currentSecond - 100;
};

function ToolkitAssetTableSelectedRowScanProgress({ offset }) {
  const [progress, setProgress] = useState(completedProgress(offset));
  const [step, setStep] = useState(Math.floor(Math.random() * 3));

  useInterval(() => {
    setProgress(completedProgress(offset));
    if (completedProgress(offset) >= 95) {
      setStep(step + 1 === steps.length ? 0 : step + 1);
    }
  }, 1000);

  return (
    <Grid>
      <Grid alignContent="center" container>
        <Grid item xs={12}>
          <Grid container>
            <Typography gutterBottom={true} variant={'caption'}>
              {steps[step]}
            </Typography>
            <Grid item xs={12}>
              <LinearProgress
                value={progress}
                valueBuffer={progress + Math.floor(Math.random() * 10)}
                variant="buffer"
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default memo(ToolkitAssetTableSelectedRowScanProgress);
