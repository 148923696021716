import { PureComponent } from 'react';
import { Tabs as MUITabs } from '@mui/material';
import { fontFamilies, fontSizes, palette } from '@dt/theme';

export const tabStyle = {
  color: palette.navColor,
  fontFamily: fontFamilies.primary,
  fontSize: fontSizes.medium,
  textTransform: 'none',
};

export const activeTabStyle = {
  ...tabStyle,
  color: palette.gray20,
};

export default class Tabs extends PureComponent {
  render() {
    const { children, marginLeft, marginRight, value } = this.props;

    return (
      <MUITabs
        centered
        indicatorColor="primary"
        style={{ marginLeft: marginLeft || 0, marginRight: marginRight || 0 }}
        textColor="primary"
        value={value}
        variant="fullWidth"
      >
        {children}
      </MUITabs>
    );
  }
}
