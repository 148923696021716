const ISO8601Regexp = /^([+-]?\d{4}(?!\d{2}\b))((-?)((0[1-9]|1[0-2])(\3([12]\d|0[1-9]|3[01]))?|W([0-4]\d|5[0-2])(-?[1-7])?|(00[1-9]|0[1-9]\d|[12]\d{2}|3([0-5]\d|6[1-6])))([T\s]((([01]\d|2[0-3])((:?)[0-5]\d)?|24:?00)([.,]\d+(?!:))?)?(\17[0-5]\d([.,]\d+)?)?([zZ]|([+-])([01]\d|2[0-3]):?([0-5]\d)?)?)?)?$/;
export const ISO8601UTCStringCast = (iso8601string) => {
    if (!iso8601string) {
        // Handle undefined or empty string
        return '';
    }
    const match = ISO8601Regexp.exec(iso8601string);
    // If we can't figure out what the string is, or if it already has a timezone... just return it
    if (!match || match[21]) {
        return iso8601string;
    }
    // Otherwise, append a Z which indicates UTC
    return `${iso8601string}Z`;
};
