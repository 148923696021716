import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Grid } from '@mui/material';
import PaperContainer from '../../../components/PaperContainer';
import ApiShadowAssetsPageChartConfiguration from './ApiShadowAssetsPageChartConfiguration';
import ApiShadowAssetsPageChartDonutContainer from './ApiShadowAssetsPageChartDonutContainer';
import ApiShadowAssetsPageChartLoadingContainer from './ApiShadowAssetsPageChartLoadingContainer';
const ApiShadowAssetsPageChartContainer = () => {
    return (_jsx(PaperContainer, { children: _jsxs(Grid, { container: true, spacing: 2, children: [_jsx(Grid, { item: true, xs: 6, children: _jsx(ApiShadowAssetsPageChartConfiguration, {}) }), _jsx(Grid, { item: true, xs: 6, children: _jsxs(Grid, { container: true, spacing: 2, children: [_jsx(ApiShadowAssetsPageChartLoadingContainer, {}), _jsx(Grid, { item: true, xs: 12, children: _jsx(ApiShadowAssetsPageChartDonutContainer, {}) })] }) })] }) }));
};
export default ApiShadowAssetsPageChartContainer;
