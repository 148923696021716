import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { memo, useState } from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Checkbox, IconButton, ListItem, Tooltip } from '@mui/material';
import Box from '@mui/material/Box';
import { makeStyles } from '@mui/styles';
// @ts-ignore JS component
import { Text } from '@dt/material-components';
import TableFiltersAssetTagKeyValues from './TableFiltersAssetTagKeyValues';
const useStyles = makeStyles(() => ({
    customTooltip: {
        position: 'relative',
        zIndex: 9999,
    },
    keyItem: {
        justifyContent: 'space-between',
    },
}));
const InventoryTableFilterAssetTagsComponent = function InventoryTableFilterAssetTags({ assetTagKey, selectedAssetTags, onToggleAssetTagKey, onToggleAssetTagValue, }) {
    const classes = useStyles();
    const [totalValues, setTotalValues] = useState();
    const [showValue, setShowValues] = useState(false);
    return (_jsxs(_Fragment, { children: [_jsxs(ListItem, { button: true, className: classes.keyItem, dense: true, children: [_jsxs(Box, { alignItems: 'center', display: 'flex', flexGrow: 1, onClick: () => onToggleAssetTagKey(assetTagKey.name), children: [_jsx(Checkbox, { checked: Boolean(selectedAssetTags[assetTagKey.name] === null) ||
                                    Boolean(selectedAssetTags[assetTagKey.name]?.length), indeterminate: Boolean(selectedAssetTags[assetTagKey.name]?.length) &&
                                    Number(selectedAssetTags[assetTagKey.name]?.length) < Number(totalValues) }), _jsx(Text, { component: 'div', noWrap: true, children: assetTagKey.name })] }), _jsx(Box, { flex: '0 0 30px', children: _jsx(Tooltip, { PopperProps: { style: { zIndex: 99999 } }, disableInteractive: true, title: 'Click to see all values for this tag', children: _jsx(IconButton, { "aria-label": "expand row", onClick: () => {
                                    setShowValues(prev => !prev);
                                }, size: "small", children: showValue ? _jsx(KeyboardArrowUpIcon, {}) : _jsx(KeyboardArrowDownIcon, {}) }) }) })] }), showValue && (_jsx(Box, { ml: 4, children: _jsx(TableFiltersAssetTagKeyValues, { assetTagKey: assetTagKey, onToggleAssetTagValue: onToggleAssetTagValue, selectedAssetTags: selectedAssetTags, setTotalValues: setTotalValues }) }))] }));
};
export default memo(InventoryTableFilterAssetTagsComponent);
