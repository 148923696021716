import { memo } from 'react';
import { makeStyles } from '@mui/styles';
import theme from '@dt/theme';

const useStyles = makeStyles({
  ErrorMessageBlock: {
    backgroundColor: theme().dataTheorem.palette.attention,
    borderRadius: 3,
    color: 'white',
    margin: 20,
    padding: 10,
  },
});

export default memo(function ErrorMessageBlock(props) {
  const { children } = props;
  const classes = useStyles();
  return (
    <div className={classes.ErrorMessageBlock}>
      <pre>{children}</pre>
    </div>
  );
});
