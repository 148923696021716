import { memo } from 'react';
import { Card, CardContent, Grid } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import { palette } from '@dt/theme';
import Text from './Text';

export default memo(function TotalCard(props) {
  const { color, inline, isLoading, number, title } = props;

  const content = (
    <Grid alignContent="center" alignItems="center" container direction="column" justifyContent="center" spacing={2}>
      <Grid item>
        {isLoading ? (
          <Skeleton animation="wave" height={48} variant="rectangular" width={30} />
        ) : (
          <Text color={color || palette.gray20} variant="numberXL">
            {number}
          </Text>
        )}
      </Grid>

      <Grid item>
        <Text color={palette.gray20} style={{ marginBottom: 0, marginTop: 0 }} variant="body">
          {title}
        </Text>
      </Grid>
    </Grid>
  );

  return inline ? (
    content
  ) : (
    <Card aria-label={`${number} ${title}`} style={{ maxHeight: '160px', minHeight: '160px' }}>
      <CardContent style={{ padding: '16px' }}>{content}</CardContent>
    </Card>
  );
});
