import { memo, useCallback, useEffect, useState } from 'react';
import { Checkbox, FormControl, FormControlLabel } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

const assets = [
  { label: 'Open S3 Buckets', value: 'open_s3_buckets' },
  { label: 'Google Cloud Storage', value: 'google_cloud_storage' },
  { label: 'Firebase Storage', value: 'firebase_storage' },
  { label: 'Azure Blobs', value: 'azure_blobs' },
  { label: 'Elasticsearch databases', value: 'elasticsearch_databases' },
  { label: 'Docker Registries', value: 'docker_registries' },
];

const useStyles = makeStyles({
  checkbox_root: {
    paddingBottom: '0px',
    paddingLeft: '4px',
    paddingRight: '4px',
    paddingTop: '0px',
  },
  form_control_label: {
    marginLeft: '0px',
    marginRight: '0px',
    padding: '2px',
    width: '360px',
  },
  form_control_root: {
    // minHeight: '200px',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
});

function AssetSelector({ defaultSelected = [], onChange }) {
  const css = useStyles();
  const selectedMap = Object.fromEntries(defaultSelected.map(d => [d, true]));
  const [selected, setSelected] = useState(selectedMap);

  useEffect(() => {
    if (onChange) {
      const selectedKeys = Object.keys(selected).filter(k => Boolean(selected[k]));
      onChange(selectedKeys);
    }
  }, [selected, onChange]);

  const handleChange = useCallback(value => {
    setSelected(s => ({ ...s, [value]: !s[value] }));
  }, []);

  return (
    <FormControl classes={{ root: css.form_control_root }} component="fieldset">
      {assets.map(a => (
        <FormControlLabel
          classes={{ root: css.form_control_label }}
          control={
            <Checkbox
              checked={Boolean(selected[a.value])}
              classes={{ root: css.checkbox_root }}
              color="primary"
              disableRipple
              onChange={e => handleChange(e.target.value)}
              size="small"
              value={a.value}
            />
          }
          key={a.value}
          label={a.label}
        />
      ))}
    </FormControl>
  );
}

export default memo(AssetSelector);
