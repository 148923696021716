import { memo } from 'react';
import { palette } from '@dt/theme';

function WebSecureIcon({ foregroundColor = palette.brand30, backgroundColor = palette.white }) {
  return (
    <svg
      clipRule="evenodd"
      fillRule="evenodd"
      strokeLinejoin="round"
      strokeMiterlimit="1.4"
      style={{ maxHeight: 'inherit ' }}
      viewBox="0 0 160 160"
      width="100%"
      // This is important for IE 11
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fillRule="nonzero">
        <path
          d="M160 127.19C160.001 131.499 159.154 135.766 157.505 139.747C155.857 143.729 153.44 147.346 150.393 150.393C147.346 153.44 143.729 155.857 139.747 157.505C135.766 159.154 131.499 160.001 127.19 160H32.81C24.1082 160 15.7629 156.543 9.60983 150.39C3.45676 144.237 0 135.892 0 127.19L0 32.8C0.00265164 24.1 3.46058 15.7572 9.61336 9.60629C15.7661 3.45539 24.11 1.12044e-06 32.81 1.52453e-06H127.19C131.498 -0.00131195 135.764 0.846116 139.745 2.49388C143.726 4.14165 147.343 6.55747 150.39 9.60336C153.436 12.6493 155.853 16.2655 157.502 20.2457C159.151 24.2258 160 28.4918 160 32.8V127.19Z"
          fill={backgroundColor}
        />
        <path
          d="M126.94 31.5H33.06C30.9224 31.5 28.8723 32.3492 27.3607 33.8607C25.8492 35.3723 25 37.4224 25 39.56V120.44C25 122.578 25.8492 124.628 27.3607 126.139C28.8723 127.651 30.9224 128.5 33.06 128.5H126.94C127.998 128.5 129.047 128.292 130.024 127.886C131.002 127.481 131.891 126.888 132.639 126.139C133.388 125.391 133.981 124.502 134.386 123.524C134.792 122.547 135 121.498 135 120.44V39.56C135 38.5015 134.792 37.4535 134.386 36.4756C133.981 35.4977 133.388 34.6092 132.639 33.8607C131.891 33.1123 131.002 32.5186 130.024 32.1135C129.047 31.7085 127.998 31.5 126.94 31.5ZM33.06 35.5H126.94C127.473 35.5 128.001 35.605 128.494 35.809C128.986 36.0131 129.434 36.3121 129.811 36.6891C130.188 37.0662 130.487 37.5137 130.691 38.0063C130.895 38.4989 131 39.0268 131 39.56V54.74H29V39.56C29 38.4832 29.4277 37.4505 30.1891 36.6891C30.9505 35.9278 31.9832 35.5 33.06 35.5ZM126.94 124.5H33.06C31.9832 124.5 30.9505 124.072 30.1891 123.311C29.4277 122.549 29 121.517 29 120.44V58.74H131V120.44C131 120.973 130.895 121.501 130.691 121.994C130.487 122.486 130.188 122.934 129.811 123.311C129.434 123.688 128.986 123.987 128.494 124.191C128.001 124.395 127.473 124.5 126.94 124.5Z"
          fill={foregroundColor}
        />
        <path
          d="M34.9999 48.23C36.3088 48.23 37.3699 47.1689 37.3699 45.86C37.3699 44.5511 36.3088 43.49 34.9999 43.49C33.691 43.49 32.6299 44.5511 32.6299 45.86C32.6299 47.1689 33.691 48.23 34.9999 48.23Z"
          fill={foregroundColor}
        />
        <path
          d="M42.7401 48.23C44.049 48.23 45.1101 47.1689 45.1101 45.86C45.1101 44.5511 44.049 43.49 42.7401 43.49C41.4312 43.49 40.3701 44.5511 40.3701 45.86C40.3701 47.1689 41.4312 48.23 42.7401 48.23Z"
          fill={foregroundColor}
        />
        <path
          d="M50.4801 48.23C50.9488 48.23 51.4071 48.091 51.7968 47.8306C52.1866 47.5702 52.4903 47.2 52.6697 46.767C52.8491 46.3339 52.896 45.8574 52.8046 45.3976C52.7131 44.9379 52.4874 44.5156 52.1559 44.1842C51.8245 43.8527 51.4022 43.627 50.9425 43.5355C50.4827 43.4441 50.0062 43.491 49.5731 43.6704C49.1401 43.8498 48.7699 44.1535 48.5095 44.5433C48.2491 44.933 48.1101 45.3913 48.1101 45.86C48.1101 46.4886 48.3598 47.0914 48.8043 47.5358C49.2487 47.9803 49.8515 48.23 50.4801 48.23Z"
          fill={foregroundColor}
        />
      </g>
    </svg>
  );
}

export default memo(WebSecureIcon);
