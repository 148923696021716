import { memo, useEffect, useMemo, useState } from 'react';
import { Waypoint } from 'react-waypoint';
import SearchIcon from '@mui/icons-material/Search';
import { Checkbox, Grid, List, ListItem, ListItemIcon, TextField } from '@mui/material';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import { makeStyles } from '@mui/styles';
import { useLazyQuery } from '@dt/apollo-link-schema-rest';
import { ErrorState } from '@dt/components';
import vendors from '@dt/graphql-support/horizon/vendors';
import { Text } from '@dt/material-components';
import debounce from 'lodash/fp/debounce';

const useStyles = makeStyles(theme => ({
  itemLogo: {
    justifyContent: 'center',
  },
  list: {
    padding: 0,
    paddingLeft: theme.spacing(3),
  },
  mainList: {
    height: 300,
    overflow: 'scroll',
    overflowX: 'auto',
  },
}));

const TableFilterVendors = ({ selectedList, onChange }) => {
  const classes = useStyles();

  const [fetchVendorsQuery, { data, error, loading, called, fetchMore, pageNumber }] = useLazyQuery(vendors.list);

  useEffect(() => {
    if (!called) {
      fetchVendorsQuery({});
    }
  }, [fetchVendorsQuery, called]);

  const [filter, setFilter] = useState('');
  const handleTextFilterChange = useMemo(
    () =>
      debounce(500)(filter =>
        fetchVendorsQuery(
          filter
            ? {
                variables: {
                  filter_by_text: filter,
                },
              }
            : {},
        ),
      ),
    [fetchVendorsQuery],
  );

  const handleChange = id => {
    if (selectedList.includes(id)) {
      onChange(selectedList.filter(item => item !== id));
    } else {
      onChange(selectedList.concat(id));
    }
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <TextField
          InputProps={{
            endAdornment: <SearchIcon />,
          }}
          fullWidth
          id="asset-vendors-filter"
          label={'Filter'}
          onChange={e => {
            setFilter(e.target.value);
            handleTextFilterChange(e.target.value);
          }}
          value={filter}
        />
      </Grid>
      <Grid item xs={12}>
        <List className={classes.mainList} component="nav">
          {error ? (
            <ErrorState error={error} />
          ) : !pageNumber && loading ? (
            [0, 1, 2, 3, 4].map((_, key) => (
              <ListItem button dense key={key}>
                <Skeleton animation="wave" height={40} width={`100%`} />
              </ListItem>
            ))
          ) : (
            data?.vendors_list.vendors.map(
              (vendor, key) =>
                vendor && (
                  <ListItem
                    button
                    dense
                    key={key}
                    onClick={() => handleChange(vendor?.id)}
                    selected={selectedList.includes(vendor?.id)}
                  >
                    <Checkbox checked={selectedList.includes(vendor?.id)} />
                    <ListItemIcon className={classes.itemLogo}>
                      <img alt={vendor.name} height={24} src={vendor.icon_url} width={24} />
                    </ListItemIcon>
                    <Text component={'div'} noWrap variant={'body'}>
                      {vendor.name}
                    </Text>
                  </ListItem>
                ),
            )
          )}
          {fetchMore && (
            <Waypoint
              onEnter={() => {
                if (fetchMore) {
                  fetchMore();
                }
              }}
            >
              <Box component={'div'}>
                <ListItem button dense>
                  <Skeleton animation="wave" height={40} width={`100%`} />
                </ListItem>
                <ListItem button dense>
                  <Skeleton animation="wave" height={40} width={`100%`} />
                </ListItem>
                <ListItem button dense>
                  <Skeleton animation="wave" height={40} width={`100%`} />
                </ListItem>
              </Box>
            </Waypoint>
          )}
        </List>
      </Grid>
    </Grid>
  );
};

export default memo(TableFilterVendors);
