import { jsx as _jsx } from "react/jsx-runtime";
import Box from '@mui/material/Box';
const PageContainer = ({ fullWidth = true, children, large = false }) => {
    return (_jsx(Box, { style: {
            backgroundColor: '#F4F5FC',
            margin: '0 auto',
            minHeight: '100vh',
            padding: '0 24px',
            ...(fullWidth ? {} : { maxWidth: large ? 1200 : 900 }),
        }, children: children }));
};
export default PageContainer;
