import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { palette } from '@dt/theme';
import { Box, Typography } from '@mui/material';
const variantStyle = {
    default: { bgcolor: palette.white, color: palette.gray10 },
    disabled: { bgcolor: palette.gray50, color: palette.gray10 },
    error: { bgcolor: palette.red50, color: palette.red10 },
    info: { bgcolor: palette.blue50, color: palette.blue10 },
    success: { bgcolor: palette.green50, color: palette.green10 },
    warning: { bgcolor: palette.yellow50, color: palette.yellow10 },
};
export const Message = ({ style, m, mb, mt, p, c, bg, variant, icon, message, messageHeader }) => {
    const padding = p ?? 1;
    const margin = m ?? 2;
    const marginBottom = mb ?? m ?? 2;
    const marginTop = mt ?? m ?? 2;
    const color = c ?? 'inherit';
    const bgColor = bg ?? variantStyle[variant].bgcolor;
    return (_jsxs(Box, { bgcolor: bgColor, borderRadius: 2, color: variantStyle[variant].color, m: margin, mb: marginBottom, mt: marginTop, p: padding, sx: {
            ...style,
            alignItems: 'center',
            display: 'flex',
        }, whiteSpace: "pre-line" // Used to allow messages to be multiline strings.
        , children: [icon && (_jsx(Box, { component: "div", m: 0, pr: 1, width: "auto", children: icon })), _jsxs(Box, { component: "div", m: 0, width: "auto", children: [messageHeader && (_jsx(Typography, { style: { color: color, marginBottom: '10px' }, variant: 'subtitle2', children: messageHeader })), _jsx(Typography, { variant: "body1", children: message })] })] }));
};
