import { memo, useRef, useState } from 'react';
import { CircularProgress, Typography } from '@mui/material';
import Popover from '@mui/material/Popover';
import { palette } from '@dt/theme';
import { Slice, VictoryPie } from 'victory';
import HostPieChartTooltip from './HostPieChartTooltip';

const HoverType = {
  IMPORTANT: 'IMPORTANT',
  LOGO: 'LOGO',
  PROACTIVE: 'PROACTIVE',
  RESOLVED: 'RESOLVED',
  URGENT: 'URGENT',
};

// The Size here doesn't really matter much. It's only relevant as all
// dimensions specified inside the svg will be relative to this number. For
// example, if the Size is "100", and you want a box with a width of "10" it
// will be one tenth the size of the svg element.
// To put it another way, when this code was written, all units were based off
// the Size value, so changing it actually had no effect!
const Size = 100;

// This determines the width of the ring.
const RingWidth = 8; //<-- you probably want to change this
const InnerRadius = Size / 2;

export default memo(function HostPieChart({
  label,
  text,
  logo,
  enabled,
  isLoading,
  children,
  resolvedCount,
  proactiveCount,
  importantCount,
  urgentCount,
  showClickLabel,
}) {
  const hoverTimeoutRef = useRef(null);
  const hoveringOverPopover = useRef(false);
  const [hoveredHoverType, setHoveredHoverType] = useState(null);

  const handleOnMouseOver = (hoverType, e) => {
    e.preventDefault();

    if (hoverTimeoutRef.current) {
      clearTimeout(hoverTimeoutRef.current);
    }

    setAnchor(e.currentTarget);
    setHoveredHoverType(hoverType);
  };

  const handleOnMouseOut = (hoverType, e) => {
    e.preventDefault();

    if (hoverTimeoutRef.current) {
      clearTimeout(hoverTimeoutRef.current);
    }

    const handleClosePopover = () => {
      // Keep trying to close popover.
      if (hoveringOverPopover.current) {
        hoverTimeoutRef.current = setTimeout(handleClosePopover, 700);
        return;
      }
      setAnchor(null);
      setHoveredHoverType(null);
    };
    hoverTimeoutRef.current = setTimeout(handleClosePopover, 700);
  };

  const [anchor, setAnchor] = useState(null);

  return (
    <>
      <Popover
        PaperProps={{ elevation: 0 }}
        anchorEl={anchor}
        anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
        disableRestoreFocus
        id="popoverme"
        onClose={() => setAnchor(null)}
        open={hoveredHoverType !== null && anchor !== null}
        style={{ pointerEvents: 'none' }}
        transformOrigin={{ horizontal: 'left', vertical: 'top' }}
        transitionDuration={500}
      >
        <div
          onMouseOut={() => {
            hoveringOverPopover.current = false;
          }}
          onMouseOver={() => {
            hoveringOverPopover.current = true;
          }}
          style={{ pointerEvents: 'all' }}
        >
          <HostPieChartTooltip
            important={
              hoveredHoverType === HoverType.IMPORTANT || hoveredHoverType === HoverType.LOGO ? importantCount : null
            }
            proactive={
              hoveredHoverType === HoverType.PROACTIVE || hoveredHoverType === HoverType.LOGO ? proactiveCount : null
            }
            resolved={
              hoveredHoverType === HoverType.RESOLVED || hoveredHoverType === HoverType.LOGO ? resolvedCount : null
            }
            urgent={hoveredHoverType === HoverType.URGENT || hoveredHoverType === HoverType.LOGO ? urgentCount : null}
          >
            {showClickLabel && <Typography variant="body2">Click For Details</Typography>}
          </HostPieChartTooltip>
        </div>
      </Popover>
      <div style={{ position: 'relative' }}>
        {isLoading && (
          <CircularProgress
            size={Size * 2}
            style={{
              left: 0,
              // pointerEvents makes this element ignore mouse events for the
              // purposes of stuff like mouseover. Because we are adding events
              // to the VictoryPie chart, and this Progress indicator is
              // rendered above it, we need this to make sure mouseover events
              // are sent to the chart, not this element.
              pointerEvents: 'none',

              position: 'absolute',

              top: 0,

              zIndex: 1,
            }}
            thickness={1}
          />
        )}
        <svg
          style={{ height: Size * 2, maxHeight: 'inherit', width: Size * 2 }}
          viewBox={`0 0 ${Size} ${Size}`}
          width="100%"
        >
          <circle
            cx={Size / 2}
            cy={Size / 2}
            fill={hoveredHoverType === HoverType.LOGO ? palette.gray40 : palette.gray45}
            onMouseOut={e => handleOnMouseOut(HoverType.LOGO, e)}
            onMouseOver={e => handleOnMouseOver(HoverType.LOGO, e)}
            r={InnerRadius}
          />
          <VictoryPie
            animate={{ duration: 2000, easing: 'elastic' }}
            data={[
              {
                fill: palette.green,
                hoverType: HoverType.RESOLVED,
                hoveredStrokeColor: palette.green10,
                x: 1,
                y: resolvedCount,
              },
              {
                fill: palette.blue,
                hoverType: HoverType.PROACTIVE,
                hoveredStrokeColor: palette.blue10,
                x: 2,
                y: proactiveCount,
              },
              {
                fill: palette.yellow,
                hoverType: HoverType.IMPORTANT,
                hoveredStrokeColor: palette.yellow10,
                x: 3,
                y: importantCount,
              },
              {
                fill: palette.red,
                hoverType: HoverType.URGENT,
                hoveredStrokeColor: palette.red10,
                innerRadius: Size / 2 - RingWidth * 2,
                radius: Size / 2,
                x: 4,
                y: urgentCount,
              },
            ]}
            dataComponent={
              <Slice
                innerRadius={({ datum: { innerRadius } }) => (innerRadius ? innerRadius : InnerRadius)}
                radius={({ datum: { radius } }) => (radius ? radius : Size / 2 - RingWidth / 2)}
              />
            }
            events={[
              {
                eventHandlers: {
                  onMouseOut: (e, { datum }) => {
                    handleOnMouseOut(datum.hoverType, e);

                    return [
                      {
                        mutation: ({ style }) => ({
                          style: {
                            ...style,
                            strokeWidth: 0,
                          },
                        }),
                        target: 'data',
                      },
                    ];
                  },
                  onMouseOver: (e, { datum }) => {
                    handleOnMouseOver(datum.hoverType, e);

                    return [
                      {
                        mutation: ({ style, datum }) => ({
                          style: {
                            ...style,
                            stroke: datum.hoveredStrokeColor,
                            strokeWidth: 1,
                          },
                        }),
                        target: 'data',
                      },
                    ];
                  },
                },
                target: 'data',
              },
            ]}
            height={Size}
            labels={() => null}
            standalone={false}
            style={{
              data: {
                fill: ({ datum }) => datum.fill,
              },
            }}
            width={Size}
          />
          <filter id="grayscale">
            <feColorMatrix
              type="matrix"
              values="0.3333 0.3333 0.3333 0   0
                    0.3333 0.3333 0.3333 0   0
                    0.3333 0.3333 0.3333 0   0
                    0      0      0      0.5 0"
            />
          </filter>
          {typeof logo === 'string' && (
            <image
              alt={`${label} Logo`}
              filter={!enabled ? 'url(#grayscale)' : null}
              height={Size * 0.5}
              style={{ pointerEvents: 'none' }}
              width={Size * 0.5}
              x={Size / 2 - (Size * 0.5) / 2}
              xlinkHref={logo}
              y={Size / 2 - (Size * 0.5) / 2}
            />
          )}
          {text && (
            <text
              dominantBaseline="central"
              fill={palette.gray20}
              style={{ pointerEvents: 'none' }}
              textAnchor="middle"
              x={Size / 2}
              y={Size / 2}
            >
              {text}
            </text>
          )}
        </svg>
        {children}
      </div>
    </>
  );
});
