import { memo, useCallback, useEffect, useRef, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { palette } from '@dt/theme';
import copy from './copyToClipboard.util';
import ClipboardIcon from './icons/ClipboardIcon';

const useStyles = makeStyles({
  '@keyframes FadeInUp': {
    '0%': {
      opacity: 1,
      transform: 'translate(0, 0px)',
    },
    '100%': {
      opacity: 0,
      transform: 'translate(0, -40px)',
    },
    '30%': {
      opacity: 1,
      transform: 'translate(0, -20px)',
    },
  },

  container: {
    alignItems: 'center',
    cursor: 'pointer',
    display: 'inline-flex',
    justifyContent: 'center',
    position: 'relative',
  },

  floating: {
    animationDuration: '2s',
    animationName: 'FadeInUp',
    fontSize: 12,
    left: 0,

    opacity: 0,
    position: 'absolute',
    right: 0,
    top: 0,
    transform: 'translate(0, -40px)',
  },
});

function CopyToClipboard(props) {
  const { showClipboardIcon, label, size = 14, value, color } = props;
  const classes = useStyles();
  const [copied, setCopied] = useState(false);
  const timeout = useRef();
  useEffect(
    () => () => {
      timeout.current && clearTimeout(timeout.current);
    },
    [],
  );

  const handleClick = useCallback(() => {
    // Slow down to avoid flickering the animation
    if (copied) return;

    setCopied(true);
    copy(value);

    timeout.current = setTimeout(() => setCopied(false), 2500);
  }, [copied, value]);

  return (
    <span className={classes.container} onClick={handleClick}>
      {copied && <span className={classes.floating}>Copied!</span>}
      {label ? label : 'Copy'}
      {showClipboardIcon && (
        <ClipboardIcon
          htmlColor={color || palette.gray30}
          style={{
            height: size,
            marginLeft: 5,
            width: size,
          }}
        />
      )}
    </span>
  );
}

export default memo(CopyToClipboard);
