import { PureComponent } from 'react';
import HamburgerIcon from '@mui/icons-material/Menu';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';

export default class MenuButton extends PureComponent {
  state = {
    anchorEl: null,
  };

  closeMenu = () => {
    this.setState({ anchorEl: null });
  };

  openMenu = e => {
    this.setState({ anchorEl: e.currentTarget });
  };

  render() {
    const { label, children } = this.props;

    return (
      <>
        <Button aria-label={'Open Menu'} onClick={this.openMenu} variant="outlined">
          {label}
          <HamburgerIcon
            color={'inherit'}
            style={{
              height: 16,
              marginLeft: 5,
              width: 16,
            }}
          />
        </Button>
        <Menu
          anchorEl={this.state.anchorEl}
          anchorOrigin={{
            horizontal: 'right',
            vertical: 'top',
          }}
          disableScrollLock
          onClose={this.closeMenu}
          open={!!this.state.anchorEl}
        >
          {children({
            onClick: () => {
              this.setState({ anchorEl: null });
            },
          })}
        </Menu>
      </>
    );
  }
}
