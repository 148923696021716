import { Message, Tag } from '@components';
import { Text } from '@dt/material-components';
import { palette } from '@dt/theme';
import OperationsIcon from '@mui/icons-material/Build';
import ApiIcon from '@mui/icons-material/DeviceHub';
import APIDomainIcon from '@mui/icons-material/Language';
import AssetIcon from '@mui/icons-material/LibraryBooks';
import WebAppIcon from '@mui/icons-material/Web';
import { Avatar, Card, CardContent, Grid } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import { Link } from '@reach/router';
import { truncateNumber } from '@utils/truncateNumber';
import { memo } from 'react';

const AssetGroupsCardComponent = function AssetGroupsCard({ isV2 = false, loading, productBasePath, asset_group }) {
  // Workaround for assets
  const updatedProductBasePath = productBasePath === '/dashboard' ? '/api' : productBasePath;
  let baseUrl = '';

  if (updatedProductBasePath) {
    baseUrl = isV2 ? `${updatedProductBasePath}/v2` : `${updatedProductBasePath}`;
  } else {
    baseUrl = isV2 ? `/policies/v2` : '';
  }

  if (loading) {
    return (
      <Card elevation={isV2 ? 0 : 1} style={{ borderRadius: isV2 ? 8 : 4 }}>
        <CardContent>
          <Grid container spacing={1}>
            <Grid item lg={9} xs={12}>
              <Grid alignItems="flex-start" container spacing={1} wrap="nowrap">
                <Grid item>
                  <Skeleton animation="wave" height={40} variant="circular" width={40} />
                </Grid>
                <Grid item xs={10}>
                  <Skeleton animation="wave" width={'30%'} />
                  <Skeleton animation="wave" width={'20%'} />
                </Grid>
              </Grid>
            </Grid>

            <Grid item lg={3} xs={12}>
              <Grid container justifyContent={'space-around'} spacing={0}>
                <Grid item xs={2}>
                  <Skeleton animation="wave" height={30} width={40} />
                </Grid>

                <Grid item xs={2}>
                  <Skeleton animation="wave" height={30} width={40} />
                </Grid>

                <Grid item xs={2}>
                  <Skeleton animation="wave" height={30} width={40} />
                </Grid>

                <Grid item xs={2}>
                  <Skeleton animation="wave" height={30} width={40} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    );
  }

  // This should never happen as this component is always called with asset group except for loading state
  // TODO: Refactor to avoid this unecessary test
  if (!asset_group) {
    return <Message message="Missing asset group, please try again later." variant="error" />;
  }

  const assetsOverview =
    asset_group.__typename === 'V2AssetGroup'
      ? asset_group?.included_assets_overview
      : asset_group?.asset_group_memberships_overview;

  return (
    <Link to={`${baseUrl}/asset-groups/${asset_group.id}`}>
      <Card elevation={isV2 ? 0 : 1} style={{ borderRadius: isV2 ? 8 : 4 }}>
        <CardContent>
          <Grid container spacing={1}>
            <Grid item lg={9} xs={12}>
              <Grid alignItems="flex-start" container spacing={1} wrap="nowrap">
                <Grid item>
                  <Avatar style={{ backgroundColor: palette.brand30 }}>
                    <AssetIcon />
                  </Avatar>
                </Grid>
                <Grid item xs={10}>
                  <Text component="div" noWrap={true} variant="titleS">
                    {asset_group.name}
                  </Text>
                  <Text component="div" variant="caption">
                    {`Created by ${asset_group.created_by_actor_name || 'Unknown'} on ${new Date(
                      asset_group.date_created,
                    ).toLocaleDateString()}`}
                  </Text>
                </Grid>
              </Grid>
            </Grid>

            <Grid item lg={3} xs={12}>
              <Grid container justifyContent={'space-around'} spacing={0}>
                <Grid item xs={2}>
                  <Tag
                    backgroundColor={'none'}
                    icon={<OperationsIcon />}
                    label={truncateNumber(assetsOverview?.api_operations_count || 0, 0)}
                    tooltipText={'API Operations'}
                  />
                </Grid>

                <Grid item xs={2}>
                  <Tag
                    backgroundColor={'none'}
                    icon={<ApiIcon />}
                    label={truncateNumber(assetsOverview?.restful_apis_count || 0, 0)}
                    tooltipText={'Restful APIs'}
                  />
                </Grid>

                <Grid item xs={2}>
                  <Tag
                    backgroundColor={'none'}
                    icon={<APIDomainIcon />}
                    label={truncateNumber(assetsOverview?.network_services_count || 0, 0)}
                    tooltipText={'Network Services'}
                  />
                </Grid>

                <Grid item xs={2}>
                  <Tag
                    backgroundColor={'none'}
                    icon={<WebAppIcon />}
                    label={truncateNumber(assetsOverview?.web_applications_count || 0, 0)}
                    tooltipText={'Web Applications'}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Link>
  );
};

export default memo(AssetGroupsCardComponent);
