import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { SvgIcon, Tooltip } from '@mui/material';
import Box from '@mui/material/Box';
import Text from '@dt/ahura/src/components/Text';
// @ts-ignore: #TODO-IMPLEMENT-TYPES-THEME
import { palette } from '@dt/theme';
const currentRouteStyle = (isCurrent, disabled) => ({
    backgroundColor: disabled ? '#ccc' : isCurrent ? palette.white : 'initial',
    borderRadius: 12,
    color: disabled ? '#666' : isCurrent ? palette.brand30 : palette.gray30,
    fill: isCurrent ? palette.brand30 : palette.gray30,
    height: 38,
    margin: 0,
    padding: '8px 12px',
});
const NavigationItemContent = ({ Icon, isCurrent, label, isSvg, disabled = false, tooltip = false }) => {
    return (_jsx(_Fragment, { children: _jsx(Tooltip, { disableInteractive: true, role: "group", title: tooltip ? label : '', children: !Icon ? (_jsx(Text, { style: currentRouteStyle(isCurrent, disabled), variant: "titleS", children: label })) : (_jsx(Box, { children: isSvg ? (_jsx("div", { style: currentRouteStyle(isCurrent, disabled), children: _jsx(SvgIcon, { children: Icon }) })) : (_jsx(Box, { style: currentRouteStyle(isCurrent, disabled), children: _jsx(Icon, {}) })) })) }) }));
};
export default NavigationItemContent;
