import { memo } from 'react';
import { Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  label: {
    position: 'relative',
    textAlign: 'center',
    top: -30,
  },
});

function SemiCircularDonutChart({ total, completed, size, children }) {
  const classes = useStyles();

  return (
    <Grid container justifyContent={'center'}>
      <Grid item style={{ maxHeight: size || 200 }} xs={12}>
        <svg height="100%" viewBox="0 0 42 20" width="100%">
          <circle
            aria-label={`total-${total.count}-${completed.color}`}
            cx="21"
            cy="21"
            fill="transparent"
            r="15.91549430918954"
            stroke={`${total.color}`}
            strokeWidth="6"
          ></circle>
          <circle
            aria-label={`completed-${total.count}-${completed.color}`}
            cx="21"
            cy="21"
            fill="transparent"
            r="15.91549430918954"
            stroke={`${completed.color}`}
            strokeDasharray={`${(completed.count * 50) / total.count + 50} 100`}
            strokeWidth="6"
          ></circle>
        </svg>
      </Grid>
      <Grid item>
        <Grid className={classes.label} container>
          {children}
        </Grid>
      </Grid>
    </Grid>
  );
}

export default memo(SemiCircularDonutChart);
