import { memo } from 'react';
import { Paper } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { palette } from '@dt/theme';
import ExtLink from './ExtLink';
import Text from './Text';
import ToolkitDivider from './ToolkitDivider';
import ToolkitTitle from './ToolkitTitle';

const useStyles = makeStyles({
  content_paper: {
    backgroundColor: palette.gray50,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    minHeight: '200px',
    padding: '12px',
  },

  content_row: {
    display: 'flex',
    flex: '1 1 auto',
    flexDirection: 'column',
    marginBottom: '20px',
  },

  details_link: {
    marginTop: 'auto',
  },

  header_row: {
    marginBottom: '20px',
  },

  paper: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    overflow: 'hidden',
    padding: '16px',
  },

  subheader_row: {
    overflow: 'hidden',
    overflowWrap: 'break-word',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },

  title_row: {
    marginBottom: '20px',
  },
});

function ToolkitFullCard({ name, header, subheader, content, accentColor, detailsLink, actionButton }) {
  const css = useStyles();
  return (
    <Paper classes={{ root: css.paper }} data-testid={name}>
      <div className={css.title_row}>
        <ToolkitTitle title={name} />
      </div>

      <div className={css.header_row}>{header}</div>

      <div className={css.content_row}>
        <Paper classes={{ root: css.content_paper }}>
          <div className={css.subheader_row}>
            <Text className={css.subheader} color={accentColor} variant="code">
              {subheader}
            </Text>
          </div>

          <ToolkitDivider color={accentColor} />

          <div>{content}</div>

          {detailsLink && (
            <div className={css.details_link}>
              <ExtLink target="_self" to={detailsLink}>
                <Text variant="link">View Details</Text>
              </ExtLink>
            </div>
          )}
        </Paper>
      </div>

      <div>{actionButton}</div>
    </Paper>
  );
}

export default memo(ToolkitFullCard);
