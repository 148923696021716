import { memo } from 'react';
import { makeStyles } from '@mui/styles';
import { palette, spacing } from '@dt/theme';

const useStyles = makeStyles({
  box: ({ absoluteLayout }) => ({
    ...(absoluteLayout
      ? {
          height: '100%',
          // Position absolute is used here to force reflow for right most containers.
          position: 'absolute',
        }
      : {}),
    borderRight: `1px solid ${palette.accent}`,
    display: 'flex',
    flexDirection: 'column',
    maxWidth: spacing.menuWidth,
    minWidth: spacing.menuWidth,
    overflowY: 'auto',
    paddingTop: 12,
  }),
});

export default memo(function LHSMenu({ absoluteLayout, children }) {
  const css = useStyles({ absoluteLayout });
  return <div className={css.box}>{children}</div>;
});
