import { memo } from 'react';
import UnknownIcon from '@mui/icons-material/HelpOutline';
import AkamaiLogo from '@dt/ahura/src/components/configurations/icons/ConfigurationsAkamai.svg';
import { HostedOnEnum } from '@dt/horizon-api';
import { palette } from '@dt/theme';
import ApigeeLogo from './svg/apigee_logo.svg';
import AwsLogo from './svg/aws_logo.svg';
import AxwayLogo from './svg/axway_logo.svg';
// Logos and icons
import AzureLogo from './svg/azure_logo.svg';
import BitbucketLogo from './svg/bitbucket_logo.svg';
import CloudflareLogo from './svg/cloudflare.svg';
import GcpLogo from './svg/gcp_logo.svg';
import GithubLogo from './svg/git_hub_logo.svg';
import KongLogo from './svg/kong_logo.svg';
import MulesoftLogo from './svg/mulesoft_logo.svg';

const HostedOnIcon = ({ hosted_on, size = 14 }) => {
  switch (hosted_on) {
    case HostedOnEnum.AMAZON_WEB_SERVICES:
      return <img alt="AWS icon" height={size} src={AwsLogo} width={size} />;
    case HostedOnEnum.GOOGLE_CLOUD_PLATFORM:
      return <img alt="GCP icon" height={size} src={GcpLogo} width={size} />;
    case HostedOnEnum.APIGEE:
      return <img alt="Apigee icon" height={size} src={ApigeeLogo} width={size} />;
    case HostedOnEnum.AZURE:
      return <img alt="Azure icon" height={size} src={AzureLogo} width={size} />;
    case HostedOnEnum.AXWAY:
      return <img alt="Axway icon" height={size} src={AxwayLogo} width={size} />;
    case HostedOnEnum.MULESOFT:
      return <img alt="Mulesoft icon" height={size} src={MulesoftLogo} width={size} />;
    case HostedOnEnum.KONG:
      return <img alt="Kong icon" height={size} src={KongLogo} width={size} />;
    case HostedOnEnum.GITHUB:
      return <img alt="Github icon" height={size} src={GithubLogo} width={size} />;
    case HostedOnEnum.GITHUB_ENTERPRISE_SERVER:
      return <img alt="Github icon" height={size} src={GithubLogo} width={size} />;
    case HostedOnEnum.BITBUCKET:
      return <img alt="Bitbucket icon" height={size} src={BitbucketLogo} width={size} />;
    case HostedOnEnum.CLOUDFLARE:
      return <img alt="Cloudflare icon" height={size} src={CloudflareLogo} width={size} />;
    case HostedOnEnum.AKAMAI:
      return <img alt="Akamai icon" height={size} src={AkamaiLogo} width={size} />;
    case HostedOnEnum.DETECTION_FAILED:
    case HostedOnEnum.ON_PREMISE:
    case HostedOnEnum.UNKNOWN:
      return <UnknownIcon style={{ color: palette.gray, height: size, width: size }} />;
    default:
      throw new Error(`Missing icon for ${hosted_on}, HostedOn is likely out of date`);
  }
};

export default memo(HostedOnIcon);
