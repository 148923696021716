import { PureComponent } from 'react';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { Button, Divider, Paper } from '@mui/material';
import { palette } from '@dt/theme';

function getStyles({ open, v2 }) {
  return {
    bodyWrapper: {},
    container: {
      borderRadius: v2 ? 8 : 4,
      boxShadow: v2
        ? 'none'
        : '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
      marginBottom: open ? 20 : 0,
      marginTop: open ? 20 : 0,
    },
    iconContainer: { marginLeft: 16, marginRight: 24 },
    primaryText: { color: palette.gray10 },
    secondaryText: { color: palette.gray20, fontSize: 12 },
    secondaryValue: { color: palette.gray20, fontSize: 12 },
    textContainer: { marginLeft: 24, marginRight: 16, width: 180 },
    valueContainer: { color: palette.gray20, flexGrow: 3 },
    valueInnerContainer: { display: 'flex' },
    wrapper: { alignItems: 'center', display: 'flex', minHeight: 60 },
  };
}

export default class ExpansionPanelItem extends PureComponent {
  render() {
    const { primaryText, secondaryText, value, secondaryValue, open, v2 } = this.props;

    const styles = getStyles({ open, v2 });

    return (
      <Paper style={styles.container}>
        <div onClick={this.onClick} style={styles.wrapper}>
          <div style={styles.textContainer}>
            <div style={styles.primaryText}>{primaryText}</div>
            <div style={styles.secondaryText}>{secondaryText}</div>
          </div>
          <div style={styles.valueContainer}>
            <div style={styles.valueInnerContainer}>{value}</div>
            {secondaryValue && <div style={styles.secondaryValue}>{secondaryValue}</div>}
          </div>
          <div style={styles.iconContainer}>{this.renderIcon()}</div>
        </div>
        {this.renderBody(styles)}
        {!open && !v2 && <Divider />}
      </Paper>
    );
  }

  renderIcon = () => {
    const { open, disabled } = this.props;

    if (disabled) {
      return null;
    }

    if (open) {
      return <ExpandLess htmlColor={palette.gray20} />;
    } else {
      return <ExpandMore htmlColor={palette.gray20} />;
    }
  };

  renderBody = styles => {
    const { children, open } = this.props;

    return (
      <div style={styles.bodyWrapper}>
        {children && <Divider />}
        <div>{children}</div>
        {open && (
          <div>
            <Divider />
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                paddingBottom: 10,
                paddingTop: 10,
              }}
            >
              <Button color="primary" onClick={this.onClickCancel} variant="text">
                Cancel
              </Button>
              <Button color="primary" onClick={this.onClickSave} variant="contained">
                Save
              </Button>
            </div>
          </div>
        )}
      </div>
    );
  };

  onClick = (...args) => {
    this.callback(this.props.onClick, args);
  };

  onClickSave = () => {
    this.callback(this.props.onRequestSave);
  };

  onClickCancel = () => {
    this.callback(this.props.onRequestCancel);
  };

  callback = (callback, args = []) => {
    if (typeof callback === 'function') {
      callback.apply(this, args);
    }
  };
}
