import SecurityFindingsFilterEnum from '@dt/enums/SecurityFindingsFilterEnum';
import findingsFilterCreator, { getComplianceSearchFilter } from '@dt/findings/findingsFilterCreator';
import { search } from '@dt/findings/search';
import { SORT_DIRECTION, getSortFn } from '@dt/findings/sort';
import { MobileAppReleaseTypeValues } from '@dt/graphql-support/enums';
import groupBy from 'lodash/groupBy';
import memoize from 'memoizee';
import { createSelector } from 'reselect';
import { hasSubscription } from '../util/appUtil';

const findingsSelector = state => state.securityFindings;

const appsSelector = state => state.apps;

function mapSortStringToSortOrder(sortString) {
  const order = sortString[0] === '-' ? SORT_DIRECTION.DESCENDING : SORT_DIRECTION.ASCENDING;
  const field = sortString.replace('+', '').replace('-', '');
  return [{ field, order }];
}

const sortString = state => state.strings['sortString'];

const findingsSorted = () =>
  createSelector(
    findingsSelector,
    sortString,

    (findings, sortString) =>
      getSortFn(typeof sortString === 'string' ? mapSortStringToSortOrder(sortString) : null)(findings),
  );

const makeFindingsFilterCreator = (
  search,

  category,
) => {
  const query = {
    compliancePolicy: getComplianceSearchFilter(),
  };

  if (search && search.category && typeof search.category === 'string') {
    query.category = search.category;
  }

  if (category) {
    query.category = category;
  }

  return findingsFilterCreator(query);
};

export const findingsFromCategory = createSelector(
  findingsSorted(),
  (state, props) => props.match && props.match.params.category,
  search,

  (findings, category, search) => (findings ? findings.filter(makeFindingsFilterCreator(search, category)) : []),
);

export const findingIdFromParam = createSelector(
  (state, props) => {
    const { match } = props;
    if (match && match.params.findingId) {
      return match.params.findingId;
    }
  },

  findingId => (Array.isArray(findingId) ? null : findingId),
);

export const findingFromParam = createSelector(
  findingsSorted(),
  findingIdFromParam,

  (findings, findingId) => (findings ? findings.find(finding => finding.id === findingId) : null),
);

export const paramsForCategory = createSelector(
  (state, props) => props.match && props.match.params.category,

  category => {
    const request = {};

    if (category === 'all' || category === 'compliance') {
      return request;
    }

    request['filter'] = SecurityFindingsFilterEnum.PRIORITY_ALERTS;
    return request;
  },
);

const simpleSearch = memoize((finding, search) => {
  if (!finding || !search) {
    return null;
  }

  const searchTarget = JSON.stringify(finding).toLowerCase();
  return searchTarget.indexOf(search.toLowerCase()) !== -1;
});

const getSearchTextFinding = createSelector(search, searchParams => {
  return (
    (searchParams
      ? Array.isArray(searchParams.searchText)
        ? searchParams.searchText[0]
        : searchParams.searchText
      : '') || ''
  );
});

export const findingsFromSearch = createSelector(
  findingsSorted(),
  getSearchTextFinding,

  (findings, searchText) =>
    findings
      .filter(finding => {
        return simpleSearch(finding, searchText);
      })
      .filter(
        findingsFilterCreator({
          compliancePolicy: getComplianceSearchFilter(),
        }),
      ),
);

export const linkedAppsFromFindingParam = createSelector(
  appsSelector,
  findingFromParam,

  (apps, finding) => {
    if (!apps || !finding) {
      return null;
    }

    const app = apps.find(app => app.id === finding.mobile_app_id);
    if (!app) {
      return null;
    }

    let storeOrEnterpriseApp;
    if (
      app.release_type === MobileAppReleaseTypeValues.APP_STORE ||
      app.release_type === MobileAppReleaseTypeValues.ENTERPRISE
    ) {
      storeOrEnterpriseApp = app;
    } else {
      const app_store_customer_mobile_app_id = app.app_store_customer_mobile_app_id
        ? app.app_store_customer_mobile_app_id
        : null;

      storeOrEnterpriseApp =
        app_store_customer_mobile_app_id && apps.find(a => a.id === app_store_customer_mobile_app_id);
    }

    if (!storeOrEnterpriseApp) {
      return null;
    }

    const linkedMobileApps = apps.filter(
      a =>
        storeOrEnterpriseApp &&
        a.id !== app.id &&
        a.app_store_customer_mobile_app_id === storeOrEnterpriseApp.id &&
        hasSubscription(a),
    );
    if (
      app.release_type !== MobileAppReleaseTypeValues.APP_STORE &&
      app.release_type !== MobileAppReleaseTypeValues.ENTERPRISE
    ) {
      linkedMobileApps.push(storeOrEnterpriseApp);
    }

    return linkedMobileApps;
  },
);

export const linkedIssuesFromFindingParam = createSelector(
  appsSelector,
  findingsSorted(),
  findingFromParam,

  (apps, sortedFindings, finding) => {
    if (!apps || !sortedFindings || !finding) {
      return null;
    }

    const appFindings = groupBy(sortedFindings, f => f.mobile_app_id);
    const app = apps.find(app => app.id === finding.mobile_app_id);
    if (!app) {
      return null;
    }

    let storeOrEnterpriseApp;
    if (
      app.release_type === MobileAppReleaseTypeValues.APP_STORE ||
      app.release_type === MobileAppReleaseTypeValues.ENTERPRISE
    ) {
      storeOrEnterpriseApp = app;
    } else {
      const app_store_customer_mobile_app_id = app.app_store_customer_mobile_app_id
        ? app.app_store_customer_mobile_app_id
        : null;

      storeOrEnterpriseApp =
        app_store_customer_mobile_app_id && apps.find(a => a.id === app_store_customer_mobile_app_id);
    }

    if (!storeOrEnterpriseApp) {
      return null;
    }

    const linkedMobileApps = apps.filter(
      a =>
        storeOrEnterpriseApp &&
        a.id !== app.id &&
        a.app_store_customer_mobile_app_id === storeOrEnterpriseApp.id &&
        hasSubscription(a),
    );

    if (
      app.release_type !== MobileAppReleaseTypeValues.APP_STORE &&
      app.release_type !== MobileAppReleaseTypeValues.ENTERPRISE
    ) {
      linkedMobileApps.push(storeOrEnterpriseApp);
    }

    const linkedIssues = [];
    linkedMobileApps.map(linkedApp => {
      const linkedFinding =
        appFindings &&
        appFindings[linkedApp.id] &&
        appFindings[linkedApp.id].find(appFinding => appFinding.issue_type_id === finding.issue_type_id);

      if (linkedFinding) {
        const linkedAppWithFinding = {
          linkedApp: linkedApp,
          linkedFinding: linkedFinding,
        };

        linkedIssues.push(linkedAppWithFinding);
      }
    });

    return linkedIssues;
  },
);
