import { PolicyViolationsStatusesBadgeIconImage } from './PolicyViolationsStatusesBadgeIconImage';

/*
 * Takes the type of status to render.
 */
export const PolicyViolationsStatusesIcon = ({ type }) => {
  const toVariantType = type === 'urgent' ? 'bad' : type === 'important' ? 'warning' : 'info';

  return <PolicyViolationsStatusesBadgeIconImage background={null} type={toVariantType} />;
};
