// sort-imports-ignore
import { Raven } from '@dt/global';
import logo from '@dt/brand/logo.png';
import { palette } from '@dt/theme';

const styles = {
  button: {
    backgroundColor: palette.brand,
    borderColor: palette.brand,
    borderRadius: 2,
    color: palette.white,
    cursor: 'pointer',
    fontSize: '14',
    margin: '24px auto',
    padding: '8px 16px',
    textAlign: 'center',
    textTransform: 'uppercase',
  },
  container: {
    backgroundColor: palette.gray50,
    height: '100%',
  },
  img: {
    width: '100%',
  },
  inner: {
    width: 200,
  },
  paragraph: {
    textAlign: 'center',
  },
  verticalCenter: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    height: '90vh',
    justifyContent: 'center',
    paddingTop: '10vh',
    width: '100%',
  },
};

const ErrorPage = () => (
  <div style={styles.container}>
    <div style={styles.verticalCenter}>
      <div style={styles.inner}>
        <img src={logo} style={styles.img} />
        <p style={styles.paragraph}>An error occurred.</p>
        <p style={styles.paragraph}>Our support team has been notified automatically.</p>
      </div>
      <button
        onClick={() => {
          const lastEventId = Raven.lastEventId();
          Raven.showReportDialog({ eventId: lastEventId });
        }}
        style={styles.button}
      >
        Create Issue Report
      </button>
    </div>
  </div>
);

export default ErrorPage;
