import { memo } from 'react';
import Skeleton from '@mui/material/Skeleton';

function ToolkitSkeleton() {
  return (
    <>
      <Skeleton animation="pulse" height={40} variant="circular" width={40} />
      <Skeleton animation="pulse" height={60} variant="text" width="70%" />
      <Skeleton animation="pulse" height={220} variant="rectangular" width="100%" />
      <Skeleton animation="pulse" height={50} variant="text" width={150} />
    </>
  );
}

export default memo(ToolkitSkeleton);
