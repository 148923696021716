import { memo } from 'react';
import ErrorIcon from '@mui/icons-material/Error';
import { CircularProgress, Grid } from '@mui/material';
import { Message } from '@components';

const InventoryTableExportComponent = function InventoryTableExport({ error, loading, completedCount, totalCount }) {
  return (
    <Grid container spacing={1}>
      {loading && (
        <Grid item xs={12}>
          <Message icon={<CircularProgress size={16} />} m={0} message={'Exporting...'} variant={'info'} />
        </Grid>
      )}
      {loading && totalCount > 0 && (
        <Grid item xs={12}>
          <Message m={0} message={`${completedCount} out ${totalCount} completed.`} variant={'info'} />
        </Grid>
      )}
      {error ? (
        <Grid item xs={12}>
          <Message
            icon={<ErrorIcon />}
            m={0}
            message={'An error occurred while exporting your assets.'}
            variant={'error'}
          />
        </Grid>
      ) : null}
    </Grid>
  );
};

export default memo(InventoryTableExportComponent);
