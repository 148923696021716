import { memo } from 'react';
import { makeStyles } from '@mui/styles';
import FindingsBadge from './FindingsBadge';
import FindingsBadgeType from './FindingsBadgeTypeEnum';

const useStyles = makeStyles({
  badge: {
    alignItems: 'center',
    display: 'flex',
    flexBasis: 15,
    flexGrow: 1,
    flexShrink: 1,
    justifyContent: 'center',
    marginLeft: 5,
    marginRight: 5,
  },

  container: {
    display: 'flex',
    justifyContent: ({ justify }) => justify,
  },
});

const getCountForBadgeType = (type, openMetadataCount) => {
  switch (type) {
    case FindingsBadgeType.P1:
      return openMetadataCount.P1;
    case FindingsBadgeType.BLOCKER:
      return openMetadataCount.APPLE_BLOCKER + openMetadataCount.GOOGLE_BLOCKER;
    case FindingsBadgeType.COMPLIANCE:
      return openMetadataCount.COMPLIANCE;
    case FindingsBadgeType.INSIGHT:
      return openMetadataCount.INSIGHT;
  }

  throw new Error(`${type} not supported by FindingStats.`);
};

function FindingStats(props) {
  const { openMetadataCount, badgeTypes, noLabel } = props;
  const classes = useStyles(props);
  return (
    <div className={classes.container}>
      {openMetadataCount ? (
        badgeTypes
          .map(type => [type, getCountForBadgeType(type, openMetadataCount)])
          .map(([type, count]) => (
            <div className={classes.badge} key={type}>
              <FindingsBadge count={count} noLabel={noLabel} type={FindingsBadgeType[type]} />
            </div>
          ))
      ) : (
        <div>Stats not currently available.</div>
      )}
    </div>
  );
}

FindingStats.defaultProps = {
  badgeTypes: [FindingsBadgeType.P1],
  justify: 'space-between',
  noLabel: false,
};

export default memo(FindingStats);
