import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import WarningIcon from '@mui/icons-material/Warning';
import { Avatar, Checkbox, List, ListItem, ListItemIcon, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
// @ts-ignore TODO-MATERIAL-COMPONENTS
import { Text } from '@dt/material-components';
const useStyles = makeStyles({
    itemLogo: {
        justifyContent: 'center',
    },
    list: {
        padding: 0,
        paddingLeft: '16px',
    },
    mainList: {
        maxHeight: 350,
        overflow: 'auto',
        overflowX: 'auto',
    },
});
const TableFiltersSeverity = ({ selectedList, onChange }) => {
    const classes = useStyles();
    const { palette } = useTheme();
    const handleChange = (id) => {
        if (selectedList.includes(id)) {
            onChange(selectedList.filter(item => item !== id));
        }
        else {
            onChange([id]);
        }
    };
    return (_jsxs(List, { className: classes.mainList, component: "nav", children: [_jsxs(ListItem, { button: true, dense: true, onClick: () => handleChange('FAILED'), selected: selectedList.includes('FAILED'), children: [_jsx(Checkbox, { checked: Boolean(selectedList.includes('FAILED')) }), _jsx(ListItemIcon, { className: classes.itemLogo, children: _jsx(Avatar, { style: { backgroundColor: palette.red[50], height: 24, width: 24 }, children: _jsx(WarningIcon, { style: { fill: palette.red[10], fontSize: 16 } }) }) }), _jsx(Text, { component: 'div', noWrap: true, variant: 'body', children: "FAIL" })] }), _jsxs(ListItem, { button: true, dense: true, onClick: () => handleChange('PASSED'), selected: selectedList.includes('PASSED'), children: [_jsx(Checkbox, { checked: Boolean(selectedList.includes('PASSED')) }), _jsx(ListItemIcon, { className: classes.itemLogo, children: _jsx(Avatar, { style: { backgroundColor: palette.green[50], height: 24, width: 24 }, children: _jsx(ThumbUpIcon, { style: { fill: palette.green[10], fontSize: 16 } }) }) }), _jsx(Text, { component: 'div', noWrap: true, variant: 'body', children: "PASS" })] })] }));
};
export default memo(TableFiltersSeverity);
