import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useReducer } from 'react';
import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Tag } from '@components';
// @ts-ignore
import { palette } from '@dt/theme';
const useStyles = makeStyles({
    button: {
        all: 'unset',
        color: palette.blue20,
        cursor: 'pointer',
        marginTop: '3px',
        ontSize: 13,
        textDecoration: 'underline',
    },
    link: { color: palette.blue20, fontSize: 13, marginTop: '3px', textDecoration: 'underline' },
    tags: {
        '&>span': {
            '&>span': {
                maxWidth: (props) => (props.largeTags ? 150 : 65),
            },
            justifyContent: 'flex-start',
        },
        display: 'grid',
        gap: 8,
        gridTemplateColumns: '1fr 1fr',
    },
    tagsNotExpandable: {
        '&>span': {
            '&>span': {
                maxWidth: (props) => (props.largeTags ? 150 : 65),
            },
            justifyContent: 'flex-start',
        },
        display: 'grid',
        gap: 8,
        gridTemplateColumns: '1fr 1fr 1fr',
    },
});
const InventoryLayoutApiAssetsTabTableRowTags = ({ tags, largeTags = false }) => {
    const [isExpanded, toggleIsExpanded] = useReducer(state => !state, false);
    const styles = useStyles({ largeTags });
    const validTags = [];
    tags.forEach(tag => {
        if (tag)
            validTags.push(tag);
    });
    if (validTags.length === 0) {
        return null;
    }
    const isExpandable = validTags.length > 2;
    const createTooltipText = (tag) => {
        return tag.value ? `${tag.tag}: ${tag.value}` : tag.tag;
    };
    const displayedTags = isExpanded ? validTags : validTags.slice(0, 2);
    return (_jsxs(Box, { style: { alignItems: 'flex-start', display: 'flex', gap: '8px' }, children: [_jsx("div", { className: styles.tags, children: displayedTags.map(tag => (_jsx(Tag, { label: tag.tag, noPadding: true, tooltipText: createTooltipText(tag), width: "100%" }, tag.id))) }), isExpandable && (_jsx("button", { className: styles.button, onClick: toggleIsExpanded, children: isExpanded ? 'Less -' : `+ ${validTags.length - 2} more` }))] }));
};
export default InventoryLayoutApiAssetsTabTableRowTags;
