// sort-imports-ignore
import { Raven } from '@dt/global';
import { PureComponent } from 'react';
import ErrorPage from './ErrorPage';

export default class ErrorBoundary extends PureComponent {
  state = { hasError: false };

  componentDidCatch(error, { componentStack }) {
    console.error(error);
    this.setState({ hasError: true });
    Raven.captureException(error, {
      extra: { msg: componentStack },
    });
  }

  render() {
    if (this.state.hasError) {
      return <ErrorPage />;
    }
    return this.props.children;
  }
}
