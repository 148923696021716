import { memo, useEffect, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import AutoRenewIcon from '@mui/icons-material/Autorenew';
import ErrorIcon from '@mui/icons-material/Error';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import { makeStyles } from '@mui/styles';
import { useMutation } from '@apollo/client';
import { Message } from '@components';
import { useLazyQuery } from '@dt/apollo-link-schema-rest';
import { AssetGroupsSearchIncludeEnumValues } from '@dt/graphql-support/enums';
import asset_groups from '@dt/graphql-support/horizon/asset_groups';
import policies from '@dt/graphql-support/horizon/policies';
import { Text } from '@dt/material-components';
import { useNavigate } from '@reach/router';

const useStyles = makeStyles({
  '@keyframes rotation': {
    from: {
      transform: 'rotate(0deg)',
    },
    to: {
      transform: 'rotate(359deg)',
    },
  },
  ongoingIcon: {
    animation: '$rotation 4s infinite linear',
  },
});

const AssetGroupsCreateDialogComponent = function AssetGroupsCreateDialog({ productBasePath, isV2 }) {
  const navigate = useNavigate();
  const classes = useStyles();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [assetGroupName, setAssetGroupName] = useState('');
  const [assetGroupDescription, setAssetGroupDescription] = useState('');
  const [assetGroupPolicyId, setAssetGroupPolicyId] = useState('');

  const [fetchPolicyQuery, policiesQuery] = useLazyQuery(policies.list);

  useEffect(() => {
    if (isModalOpen && !policiesQuery.called) {
      fetchPolicyQuery({
        variables: {},
      });
    }
  }, [isModalOpen, fetchPolicyQuery, policiesQuery.called]);

  // fetch all polices
  useEffect(() => {
    policiesQuery.fetchMore && policiesQuery.fetchMore();
  }, [policiesQuery, policiesQuery.fetchMore]);

  const [create, mutationResult] = useMutation(asset_groups.create, {
    update: (cache, result) => {
      if (result?.errors) {
        return;
      }

      const newAssetGroup = result?.data?.asset_group_create.asset_groups[0];

      const q = cache.readQuery({
        query: asset_groups.v2_list,
        variables: {
          include: AssetGroupsSearchIncludeEnumValues.assets_overview,
        },
      });
      cache.writeQuery({
        data: {
          asset_group_list_v2: {
            ...q?.asset_group_list_v2,
            asset_groups: [newAssetGroup, ...(q?.asset_group_list_v2.asset_groups || [])],
            pagination_information: {
              ...q?.asset_group_list_v2.pagination_information,
              total_count: q?.asset_group_list_v2.pagination_information.total_count + 1,
            },
          },
        },
        query: asset_groups.v2_list,
        variables: {
          include: AssetGroupsSearchIncludeEnumValues.assets_overview,
        },
      });

      navigate && navigate(`${productBasePath}/${isV2 ? 'v2/' : ''}asset-groups/${newAssetGroup.id}`);
    },
  });

  const error = mutationResult.error || policiesQuery.error;

  return (
    <>
      <Button color="primary" onClick={() => setIsModalOpen(true)} startIcon={<AddIcon />} variant="contained">
        Create Asset Group
      </Button>
      <Dialog onClose={() => setIsModalOpen(false)} open={isModalOpen}>
        <DialogTitle>
          <Text component={'div'} variant={'titleM'}>
            Create New Asset Group
          </Text>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={1}>
            {mutationResult.loading && (
              <Grid item xs={12}>
                <Message
                  icon={<AutoRenewIcon className={classes.ongoingIcon} />}
                  m={0}
                  message={'Asset group is building...'}
                  variant={'info'}
                />
              </Grid>
            )}
            {error && (
              <Grid item xs={12}>
                <Message
                  icon={<ErrorIcon />}
                  m={0}
                  message={error.message.replace('GraphQL error: ', '')}
                  variant={'error'}
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <TextField
                autoFocus
                fullWidth
                id="name"
                label="Name"
                margin="dense"
                onChange={e => setAssetGroupName(e.target.value)}
                type="text"
                value={assetGroupName}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                id="description"
                label="Description"
                margin="dense"
                onChange={e => setAssetGroupDescription(e.target.value)}
                type="text"
                value={assetGroupDescription}
                variant="outlined"
              />
            </Grid>
            {/* policy list */}
            <Grid item xs={12}>
              {policiesQuery.loading ? (
                <Skeleton animation="wave" height={70} width={550} />
              ) : (
                <Select
                  displayEmpty
                  fullWidth
                  inputProps={{
                    id: 'policy-id',
                    name: 'policy',
                  }}
                  labelId={'policy-id'}
                  onChange={e => setAssetGroupPolicyId(e.target.value)}
                  value={assetGroupPolicyId}
                  variant="outlined"
                >
                  <MenuItem value="">Select a policy</MenuItem>
                  {policiesQuery.data?.policies_list.policies.map(policy => (
                    <MenuItem key={policy.id} value={policy.id}>
                      {policy.name}
                    </MenuItem>
                  ))}
                </Select>
              )}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsModalOpen(false)} variant="contained">
            Cancel
          </Button>
          <Button
            color="primary"
            disabled={!(assetGroupName && assetGroupDescription && assetGroupPolicyId)}
            onClick={() => {
              create({
                variables: {
                  body: {
                    attached_policy_id: assetGroupPolicyId,
                    description: assetGroupDescription,
                    name: assetGroupName,
                  },
                },
              });
            }}
            variant="contained"
          >
            create
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default memo(AssetGroupsCreateDialogComponent);
