import { forwardRef, memo } from 'react';

function ExtLink({ to, children, target = '_blank', style, ...rest }, ref) {
  return (
    <a {...rest} href={to} ref={ref} rel="noopener noreferrer" style={{ outline: 0, ...style }} target={target}>
      {children}
    </a>
  );
}

export default memo(forwardRef(ExtLink));
