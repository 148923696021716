import { memo, useState } from 'react';
import { makeStyles } from '@mui/styles';
import useScsMenuItem from '@dt/ahura/src/hooks/use_scs_menu_item';
import DTIcon from '@dt/brand/dt-logo-icon-only';
import APISecureIcon from '@dt/brand/product-icon-api-secure';
import CloudSecureIcon from '@dt/brand/product-icon-cloud-secure';
import MobileSecureIcon from '@dt/brand/product-icon-mobile-secure';
import WebSecureIcon from '@dt/brand/product-icon-web-secure';
import { Drawer, NotificationsDrawer } from '@dt/material-components';
import { getInvariantUserAccount } from '@dt/session';
import ConfigurableLSHMenuChevron from './ConfigurableLHSMenuChevron';
import ConfigurableLHSMenuDevSecOps from './ConfigurableLHSMenuDevSecOps';
import ConfigurableLHSMenuHeader from './ConfigurableLHSMenuHeader';
import ConfigurableLHSMenuProductSwitcher from './ConfigurableLHSMenuProductSwitcher';
import ConfigurableLHSMenuRoutes from './ConfigurableLHSMenuRoutes';
import ConfigurableLHSMenuSdu from './ConfigurableLHSMenuSdu';
import ConfigurableLHSMenuSettings from './ConfigurableLHSMenuSettings';
import ConfigurableLHSMenuSkeleton from './ConfigurableLHSMenuSkeleton';

// cross-product

const useStyle = makeStyles({
  drawerContent: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'flex-start',
    position: 'relative',
  },
});

function ConfigurableLHSMenuPage(props) {
  const css = useStyle();
  const [navDrawerOpen, setNavDrawerOpen] = useState(true);
  const scsMenuItem = useScsMenuItem({ loading: props.isLoading });

  let content = null;
  if (props.isLoading) {
    content = <ConfigurableLHSMenuSkeleton />;
  } else {
    const { accountInfo } = getInvariantUserAccount();
    const { product, useMobileRouting = false } = props.configuration;

    // hack => overwrite entries pass if user is not subscribed to SCS
    // session is not accessible before this level

    const entries = !accountInfo.supply_chain_security_product_enabled
      ? props.configuration.entries?.map(
          entry =>
            (entry.to === '/supply-chain' && {
              ...entry,
              subEntries: entry.subEntries
                ? entry.subEntries.map(subEntry => ({
                    ...subEntry,
                    to: '/supply-chain#',
                  }))
                : [],
              to: '/supply-chain#',
            }) ||
            entry,
        )
      : props.configuration.entries;

    const dtProducts = [
      {
        icon: DTIcon,
        name: 'Dashboard',
        to: '/dashboard',
      },

      {
        icon: APISecureIcon,
        name: 'API Secure',
        to: '/api/v2',
      },

      {
        icon: CloudSecureIcon,
        name: 'Cloud Secure',
        to: '/cloud/v2',
      },

      {
        icon: MobileSecureIcon,
        name: 'Mobile Secure',
        to: '/mobile-secure/v2',
      },

      {
        icon: WebSecureIcon,
        name: 'Web Secure',
        to: '/web',
      },

      scsMenuItem,
    ];

    const showHeader = product.to.indexOf('/dashboard/v2/products') !== -1 || product.to.indexOf('/dashboard') !== -1;

    content = (
      <>
        {/* Header section of LHS Menu */}
        {showHeader && <ConfigurableLHSMenuHeader icon={product.icon} name={product.name} to={product.to} />}

        {/* Routes */}
        <ConfigurableLHSMenuRoutes
          drawerOpen={navDrawerOpen}
          isUsingMobileRouting={useMobileRouting}
          menuItems={entries}
          product={product}
          showHeader={showHeader}
        />

        {/* Chevron and other items below this pushed to bottom of page */}
        <ConfigurableLSHMenuChevron navDrawerOpen={navDrawerOpen} setNavDrawerOpen={setNavDrawerOpen} />

        {/* Notifications drawer */}
        <NotificationsDrawer isSidebarExpanded={navDrawerOpen} />

        <ConfigurableLHSMenuSdu isUsingMobileRouting={useMobileRouting} />

        {/* Product switcher */}
        <ConfigurableLHSMenuProductSwitcher products={dtProducts} />

        {/* DevSecOps */}
        <ConfigurableLHSMenuDevSecOps />

        {/* Settings */}
        <ConfigurableLHSMenuSettings />
      </>
    );
  }

  return (
    <Drawer open={navDrawerOpen}>
      <nav className={css.drawerContent}>{content}</nav>
    </Drawer>
  );
}

export default memo(ConfigurableLHSMenuPage);
