import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Grid } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import { Message } from '@components';
import { LegendItem, LegendLabel, LegendOrdinal } from '@visx/legend';
import ParentSize from '@visx/responsive/lib/components/ParentSize';
import { scaleOrdinal } from '@visx/scale';
import PieChart from '../../../components/charts/PieChart';
const colorPalette = [
    '#00E190',
    '#7000FF',
    '#C89EFF',
    '#FA7E3D',
    '#2D8A63',
    '#38B000',
    '#76FFF7',
    '#A5206F',
    '#D1FF4C',
    '#FF7A00',
    '#FF9BE9',
];
const ordinalColorScale = scaleOrdinal({
    range: colorPalette,
});
const legendGlyphSize = 15;
const ApiShadowAssetsPageChartDonut = ({ data, loading, error }) => {
    if (loading) {
        return _jsx(Skeleton, {});
    }
    if (error) {
        _jsx(Message, { m: 0, message: error.message, variant: 'error' });
    }
    const items = data?.shadow_assets_stats.shadow_assets_stats || [];
    const formatted = items.map(a => ({
        color: ordinalColorScale(a.shadow_reason_name),
        name: a.shadow_reason_name,
        value: a.shadow_assets_count,
    }));
    return (_jsxs(Grid, { container: true, spacing: 2, children: [_jsx(Grid, { item: true, xs: 6, children: _jsx(ParentSize, { children: ({ width }) => _jsx(PieChart, { data: formatted, height: 175, subText: 'Shadow Assets', text: '', width: width }) }) }), _jsx(Grid, { item: true, xs: 6, children: _jsx(LegendOrdinal, { labelFormat: label => `${label}`, scale: ordinalColorScale, children: labels => (_jsx("div", { style: { display: 'flex', flexDirection: 'column' }, children: labels.map((label, i) => (_jsxs(LegendItem, { margin: "0 5px", children: [_jsx("svg", { height: legendGlyphSize, width: legendGlyphSize, children: _jsx("rect", { fill: label.value, height: legendGlyphSize, width: legendGlyphSize }) }), _jsx(LegendLabel, { align: "left", margin: "0 0 0 4px", children: label.text })] }, `legend-quantile-${i}`))) })) }) })] }));
};
export default ApiShadowAssetsPageChartDonut;
