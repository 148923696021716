import { memo, useState } from 'react';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import { ExtLink } from '@dt/material-components';
import { palette } from '@dt/theme';

const useStyles = makeStyles({
  button: {
    color: palette.black,
  },
  container: {
    backgroundColor: palette.blue50,
    padding: '25px 25px',
  },
  containerFullScreen: {
    backgroundColor: palette.blue50,
    padding: '25px 25px',
  },
  link: {
    color: palette.blue,
  },
  text: {
    '& h3': {
      color: palette.black,
    },
    '& p': {
      color: palette.black,
    },
  },
});

function OnboardingBanner({ accountInfo, fullScreen = false }) {
  const classes = useStyles();
  const [dismissed, setDismissed] = useState(false);

  if (accountInfo.onboarding_completed || dismissed) return null;

  return (
    <div className={fullScreen ? classes.containerFullScreen : classes.container}>
      <Grid container>
        <Grid item xs={12}>
          <Grid container>
            <Grid className={classes.text} item xs={7}>
              <Grid
                alignItems="center"
                container
                direction="row"
                justifyContent="flex-start"
                style={{ height: '100%' }}
              >
                <Grid item xs={12}>
                  <Typography component="h3" variant="h5">
                    Get Started
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography>
                    Use Data Theorem&apos;s setup wizard for your Web, Mobile, APIs, and Cloud applications.
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={5}>
              <Grid container direction="row" justifyContent="flex-end">
                <Button
                  aria-label="Complete Onboarding"
                  onClick={() => {}}
                  style={{ borderColor: palette.black, color: palette.black }}
                  variant="outlined"
                >
                  <ExtLink target="_self" to="/management/onboarding-wizard">
                    <div className={classes.button}>
                      <div>COMPLETE ONBOARDING</div>
                    </div>
                  </ExtLink>
                </Button>

                <Button
                  aria-label="Dismiss"
                  onClick={() => {
                    setDismissed(true);
                  }}
                  style={{
                    borderColor: palette.black,
                    color: palette.black,
                    marginLeft: 10,
                  }}
                  variant="outlined"
                >
                  <div className={classes.button}>
                    <div>DISMISS</div>
                  </div>
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

export default memo(OnboardingBanner);
