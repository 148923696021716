import { memo } from 'react';
import { makeStyles } from '@mui/styles';
import { palette } from '@dt/theme';

const useStyles = makeStyles({
  badge: {
    // endregion fill
    backgroundColor: ({ backgroundColor }) => backgroundColor || palette.brand,

    borderBottomLeftRadius: ({ radius }) => radius,

    borderBottomRightRadius: ({ radius }) => radius,

    // region borderRadius
    borderTopLeftRadius: ({ radius }) => radius,

    borderTopRightRadius: ({ radius }) => radius,

    // endregion borderRadius
    color: ({ color }) => color || '#fff',

    // region size
    fontSize: ({ size }) => (size === 'large' ? '11px' : '9px'),

    fontWeight: 'bold',

    height: ({ fill }) => (fill ? '100%' : ''),

    lineHeight: ({ size }) => (size === 'large' ? '11px' : '9px'),

    padding: ({ size }) => (size === 'large' ? '3px 6px 3px 6px' : '2px 4px 2px 4px'),

    // endregion size
    // region uppercase
    textTransform: 'uppercase',

    // endregion uppercase
    whiteSpace: 'nowrap',

    // region fill
    width: ({ fill }) => (fill ? '100%' : ''),
  },

  container: {
    // endregion fill
    display: 'flex',

    height: ({ fill }) => (fill ? '100%' : ''),

    // region fill
    width: ({ fill }) => (fill ? '100%' : ''),
  },
});

function Badge(props) {
  const { label } = props;
  const classes = useStyles(props);

  return (
    <div className={classes.container}>
      <div className={classes.badge}>{label}</div>
    </div>
  );
}

Badge.defaultProps = {
  fill: false,
  radius: 3,
  size: 'large',
};
export default memo(Badge);
