import { memo } from 'react';
import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { palette } from '@dt/theme';

const useStyles = makeStyles({
  chip: {
    '&:hover': {
      backgroundColor: palette.gray40,
    },
    alignItems: 'center',
    borderColor: palette.gray45,
    borderRadius: 5,
    borderStyle: 'solid',
    borderWidth: 1,
    display: 'inline-flex',
    height: 35,
    marginRight: 10,
    padding: '0 10px',
  },
});

export const WebApplicationDetectedLibraries = memo(({ detected_libraries }) => {
  const classes = useStyles();

  return (
    <div style={{ display: 'inline-block' }}>
      {detected_libraries.length > 0 ? (
        detected_libraries.map(lib => (
          <a
            href={lib.project_url}
            key={lib.name}
            rel="noopener noreferrer"
            style={{ display: 'flex' }}
            target="_blank"
          >
            <div className={classes.chip} key={lib.name}>
              <img
                src={lib.icon_url}
                style={{
                  height: 25,
                  marginRight: 5,
                  objectFit: 'contain',
                  width: 25,
                }}
              />
              <Typography component="span" variant="body1">
                {lib.name}
              </Typography>
            </div>
          </a>
        ))
      ) : (
        <Typography variant="body2">No technologies detected.</Typography>
      )}
    </div>
  );
});
