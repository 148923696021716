import { memo } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import { TextField } from '@mui/material';

const InventoryTableFilterKeywordComponent = function InventoryTableFilterKeyword({
  filters,
  onChange,
  onKeyPress,
  filterKey,
  label,
}) {
  const handleChangeKeyword = value => {
    const { ...rest } = filters;
    onChange({
      ...rest,
      [filterKey]: value || null,
    });
  };

  return (
    <TextField
      InputProps={{
        endAdornment: <SearchIcon />,
      }}
      fullWidth
      id="inventory-filter-keyword"
      label={label}
      onChange={e => handleChangeKeyword(e.target.value)}
      onKeyPress={onKeyPress}
      value={filters?.[filterKey] || ''}
    />
  );
};

export default memo(InventoryTableFilterKeywordComponent);
