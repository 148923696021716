import { memo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Snackbar, Stack } from '@mui/material';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Grid from '@mui/material/Grid';
import { makeStyles } from '@mui/styles';
import { useAuthorization, useSession } from '@dt/ahura/src/session';
import { ExtLink } from '@dt/material-components';
import { palette } from '@dt/theme';
import { reminderContents } from '../contents';
import {
  createSubscriptionAction,
  notifyBillingContactAction,
  openQouteUrlAction,
  openUploadPoComponentAction,
  redirectToPayNowAction,
} from '../redux/actions';
import style from '../style';
import BillingComponents from './BillingComponents';
import PopupReminder from './PopupReminder';

const useStyles = makeStyles(theme => ({
  ...style,
  button: {
    '&&': {
      ...style.button,
      color: theme.primaryColor,
    },
  },
  link: {
    '&&': {
      ...style.link,
      color: theme.primaryColor,
    },
  },
  root: {
    '&&': {
      ...style.root,
      background: theme.backgroundColor,
      color: theme.primaryColor,
    },
  },
  text: {
    '&&': {
      '& h3': {
        color: theme.primaryColor,
      },
      '& p': {
        color: theme.primaryColor,
      },
    },
  },
}));

function BillingReminderSnack() {
  const classes = useStyles();

  const { data } = useSession();
  const { isAuthorized } = useAuthorization(data, []);

  const billingInfo = useSelector(state => state.BillingInfo);
  const dispatch = useDispatch();

  let redirectTo;
  if (window && window.location) {
    if (window.location.pathname.indexOf('/mobile') === -1) {
      redirectTo = `/mobile/pricing`;
    }
  }

  useEffect(() => {
    if (data && data.user_account) {
      const { currentUser, accountInfo } = data.user_account;

      dispatch(createSubscriptionAction(accountInfo, currentUser));
    }
  }, [data, dispatch]);

  if (!isAuthorized || (isAuthorized && billingInfo.status === 'unsubscribed')) return null;

  const { purchaseQuote, reminder, notifyBilling, payNow, quoteUrl, poUpload, period } = billingInfo;

  return (
    <>
      {reminder.shouldShow && (
        <>
          {period.daysToExpire >= 0 ? (
            <Snackbar
              anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
              onClose={() => {}}
              open={reminder.shouldShow}
            >
              <Alert
                action={
                  <Grid container direction="row" justifyContent="flex-end">
                    <Stack direction="row" spacing={1}>
                      {notifyBilling.shouldShow && (
                        <Button
                          aria-label={'Notify billing contact'}
                          onClick={() => {
                            dispatch(notifyBillingContactAction());
                          }}
                          size={'small'}
                          style={{
                            borderColor: period.daysToExpire <= 60 ? palette.red30 : palette.gray10,
                            color: period.daysToExpire <= 60 ? palette.red30 : palette.gray10,
                          }}
                          variant="outlined"
                        >
                          {reminderContents.buttons['notifyBilling']}
                        </Button>
                      )}
                      {quoteUrl.shouldShow && (
                        <Button
                          aria-label={reminderContents.quote_url}
                          color={period.daysToExpire <= 60 ? 'secondary' : 'primary'}
                          onClick={() => {
                            dispatch(openQouteUrlAction());
                          }}
                          size={'small'}
                          variant="contained"
                        >
                          <div className={classes.button}>
                            <div>VIEW YOUR</div>
                            <div>PO QUOTE HERE</div>
                          </div>
                        </Button>
                      )}
                      {purchaseQuote.shouldShow &&
                        (redirectTo ? (
                          <ExtLink target={'_self'} to={redirectTo}>
                            <Button
                              aria-label={reminderContents.buttons['payByCard']}
                              color={period.daysToExpire <= 60 ? 'secondary' : 'primary'}
                              size={'small'}
                              variant="contained"
                            >
                              <div className={classes.button}>
                                <div>GET PRICING</div>
                              </div>
                            </Button>
                          </ExtLink>
                        ) : (
                          <Button
                            aria-label={reminderContents.buttons['payByCard']}
                            color={period.daysToExpire <= 60 ? 'secondary' : 'primary'}
                            onClick={() => {
                              window.location.href = '/mobile/pricing';
                            }}
                            size={'small'}
                            variant="contained"
                          >
                            <div className={classes.button}>
                              <div>GET PRICING</div>
                            </div>
                          </Button>
                        ))}
                      {payNow.shouldShow && (
                        <Button
                          aria-label={reminderContents.buttons['payByCard']}
                          color={period.daysToExpire <= 60 ? 'secondary' : 'primary'}
                          onClick={() => {
                            dispatch(redirectToPayNowAction());
                          }}
                          size={'small'}
                          variant="contained"
                        >
                          <div className={classes.button}>
                            <div>{reminderContents.buttons['payByCard']}</div>
                            <span>Credit Card / Wire / Bank</span>
                          </div>
                        </Button>
                      )}
                      {poUpload.shouldShow && (
                        <Button
                          aria-label={'Upload PO'}
                          color={period.daysToExpire <= 60 ? 'secondary' : 'primary'}
                          onClick={() => {
                            dispatch(openUploadPoComponentAction());
                          }}
                          size={'small'}
                          variant="contained"
                        >
                          <div className={classes.button}>
                            {purchaseQuote.shouldShow
                              ? 'Upload PO / Signed Contract'
                              : reminderContents.buttons['uploadPo']}
                          </div>
                        </Button>
                      )}
                    </Stack>
                  </Grid>
                }
                onClose={() => {}}
                severity={period.daysToExpire <= 60 ? 'error' : 'warning'}
                sx={{ width: '100%' }}
              >
                <AlertTitle>{reminder.title}</AlertTitle>
                {reminder.description}
              </Alert>
            </Snackbar>
          ) : (
            <PopupReminder
              notifyBilling={notifyBilling}
              payNow={payNow}
              poUpload={poUpload}
              purchaseQuote={purchaseQuote}
              quoteUrl={quoteUrl}
              reminder={reminder}
            />
          )}
        </>
      )}
      <BillingComponents />
    </>
  );
}

export default memo(BillingReminderSnack);
