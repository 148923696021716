import { memo, useState } from 'react';
import AppsIcon from '@mui/icons-material/Apps';
import { Menu, MenuItem, Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { palette } from '@dt/theme';
import ExtLink from '../ExtLink';

const useStyle = makeStyles({
  menuIconBox: {
    alignItems: 'center',
    display: 'flex',
    height: 40,
    justifyContent: 'center',
    marginRight: '16px',
    maxHeight: 40,
    maxWidth: 40,
    minHeight: 40,
    minWidth: 40,
    width: 40,
  },

  menuInner: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'flex-start',
  },

  menuOuter: {
    '&.active': {
      backgroundColor: palette.brand35,
    },
    '&.bottom': {
      marginTop: 'auto',
    },
    '&:hover': {
      backgroundColor: palette.brand20,
    },
    alignItems: 'center',
    borderRadius: 4,
    color: palette.white,
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'flex-start',
    marginBottom: 8,
    marginLeft: 16,
    marginRight: 16,
    paddingBottom: 0,
    paddingLeft: 0,

    paddingRight: 0,

    paddingTop: 0,
  },

  menuText: {
    '&.title': {
      color: palette.white,
      fontSize: 20,
    },
    alignItems: 'center',
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
});

function ConfigurableLHSMenuProductSwitcher({ products }) {
  const css = useStyle();

  const [switcherAnchorEl, setSwitcherAnchorEl] = useState(null);
  const handleOpenProductSwitcher = event => setSwitcherAnchorEl(event.currentTarget);
  const handleCloseProductSwitcher = () => setSwitcherAnchorEl(null);

  return (
    <>
      <div
        aria-controls="switcher-menu"
        aria-haspopup="true"
        aria-label="Switch To Menu"
        className={css.menuOuter}
        onClick={event => handleOpenProductSwitcher(event)}
        type="button"
      >
        <Tooltip disableInteractive title="Switch to">
          <div className={css.menuInner}>
            <div className={css.menuIconBox}>
              <AppsIcon color="inherit" />
            </div>
            <div className={css.menuText}>Products</div>
          </div>
        </Tooltip>
      </div>

      {/* Product Switcher Menu */}
      <Menu
        anchorEl={switcherAnchorEl}
        anchorOrigin={{ horizontal: 'right', vertical: 'center' }}
        anchorReference="anchorEl"
        getContentAnchorEl={null}
        id="switcher-menu"
        keepMounted
        onClose={handleCloseProductSwitcher}
        open={Boolean(switcherAnchorEl)}
      >
        <div>
          {/* Products section */}
          {products.map((product, idx) => {
            const DTProductIcon = product.icon;

            return (
              <ExtLink key={idx} onClick={handleCloseProductSwitcher} target="_self" to={product.to}>
                <MenuItem>
                  <div className={css.menuIconBox}>
                    {/* Blue icon */}
                    <DTProductIcon backgroundColor={palette.brand30} foregroundColor={palette.white} />
                  </div>
                  {product.name}
                </MenuItem>
              </ExtLink>
            );
          })}
        </div>
      </Menu>
    </>
  );
}

export default memo(ConfigurableLHSMenuProductSwitcher);
