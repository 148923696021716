import { memo } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Avatar, Grid } from '@mui/material';
import Box from '@mui/material/Box';
import { makeStyles } from '@mui/styles';
import { Text } from '@dt/material-components';
import { palette } from '@dt/theme';

const useStyles = makeStyles({
  avatar: {
    background: palette.gray45,
    height: 64,
    width: 64,
  },
  iconEmpty: {
    color: palette.gray20,
    height: 36,
    width: 36,
  },
});

const InventoryTableEmptyStateComponent = function InventoryTableEmptyState({ variant }) {
  const classes = useStyles();

  if (variant === 'no-match') {
    return (
      <Box component="div" m={0} p={6} width="100%">
        <Grid container spacing={2}>
          <Grid container item justifyContent={'center'} xs={12}>
            <Avatar>
              <SearchIcon color={'primary'} fontSize={'large'} />
            </Avatar>
          </Grid>
          <Grid container item xs={12}>
            <Grid container spacing={0}>
              <Grid container item justifyContent={'center'} xs={12}>
                <Text component={'div'} variant={'titleXS'}>
                  No assets match your search.
                </Text>
              </Grid>
              <Grid container item justifyContent={'center'} xs={12}>
                <Text component={'div'} variant={'titleXS'}>
                  Try changing your filters.
                </Text>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    );
  }

  if (variant === 'asset-group') {
    return (
      <Box component="div" m={0} p={6} width="100%">
        <Grid container spacing={2}>
          <Grid container item xs={12}>
            <Grid container spacing={0}>
              <Grid container item justifyContent={'center'} xs={12}>
                <Text component={'div'} variant={'titleXS'}>
                  This asset group is currently empty.
                </Text>
              </Grid>
              <Grid container item justifyContent={'center'} xs={12}>
                <Text component={'div'} variant={'titleXS'}>
                  Click <strong>EDIT ASSETS</strong> to move assets to this group.
                </Text>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    );
  }

  return (
    <Box component="div" m={0} p={6} width="100%">
      <Grid container spacing={2}>
        <Grid item justifyContent={'center'} xs={12}>
          <Avatar className={classes.avatar}>
            <VisibilityIcon className={classes.iconEmpty} fontSize={'large'} />
          </Avatar>
        </Grid>
        <Grid item justifyContent={'center'} xs={12}>
          <Text component={'div'} variant={'body'}>
            {"You don't seem to have any assets."}
          </Text>
        </Grid>
      </Grid>
    </Box>
  );
};

export default memo(InventoryTableEmptyStateComponent);
