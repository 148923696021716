import { memo } from 'react';
import { makeStyles } from '@mui/styles';
import PriorityBadge from './../../PriorityBadge';
import FindingsBadgeType from './FindingsBadgeTypeEnum';

const useStyles = makeStyles({
  container: {
    alignItems: 'center',
    display: 'flex',
    flexWrap: 'wrap',
    fontSize: 12,
    justifyContent: 'center',
  },

  count: {
    color: ({ type }) => {
      if (type === FindingsBadgeType.P1) {
        return '#f00';
      }

      return '#000';
    },
    fontWeight: 'bold',
    marginLeft: 5,
    marginRight: 5,
  },

  label: {
    color: 'rgb(119, 119, 119)',
    fontWeight: 'bold',
    textAlign: 'center',
    textTransform: 'uppercase',
  },
});

function _getBadgeElement(noLabel, type, classes) {
  switch (type) {
    case FindingsBadgeType.P1:
      return (
        <div className={classes.label}>
          <PriorityBadge type={'P1'} />
        </div>
      );

    case FindingsBadgeType.COMPLIANCE:
      return (
        <div className={classes.label}>
          <span>Compliance Issues</span>
        </div>
      );

    case FindingsBadgeType.INSIGHT:
      return (
        <div className={classes.label}>
          <span>Insights</span>
        </div>
      );

    case FindingsBadgeType.BLOCKER:
      return (
        <div className={classes.label}>
          <span>App/Play Store Blockers</span>
        </div>
      );
  }

  return <div />;
}

function FindingsBadge(props) {
  const { type, count, noLabel } = props;
  const classes = useStyles(props);

  return (
    <div className={classes.container}>
      {!noLabel ? _getBadgeElement(noLabel, type, classes) : null}

      <span className={classes.count}>{count}</span>
    </div>
  );
}

export default memo(FindingsBadge);
