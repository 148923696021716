import { Fragment, memo, useState } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import { Checkbox, Grid, List, ListItem, ListItemIcon, ListItemText, TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';
import _ from 'lodash';

const useStyles = makeStyles(theme => ({
  itemLogo: {
    justifyContent: 'center',
  },
  list: {
    padding: 0,
    paddingLeft: theme.spacing(3),
  },
  mainList: {
    maxHeight: 350,
    overflow: 'scroll',
    overflowX: 'auto',
  },
}));

const InventoryTableFilterGroupComponent = function InventoryTableFilterGroup({
  searchLabel,
  list,
  selectedList,
  onChange,
}) {
  const classes = useStyles();

  const [keywordState, setKeywordState] = useState('');

  const keywordContains = item => {
    return _.includes(item?.toLowerCase(), keywordState.toLowerCase());
  };

  const assetsWithoutCategory = list?.filter(asset => !asset?.value_category);

  const assetsWithCategory = _.chain((list || []).filter(asset => asset?.value_category))
    // Group the elements of Array based on `color` property
    .groupBy('value_category')
    // `key` is group's name (color), `value` is the array of objects
    .map((value, key) => ({ assets: value, value: key }))
    .value();

  const isAtLeastOneItemSelected = items => _.difference([...items], [...selectedList]).length < items.length;

  const areAllItemsSelected = items => _.difference([...items], [...selectedList]).length === 0;

  const handleToggleItems = selectedItems => {
    if (areAllItemsSelected(selectedItems)) {
      onChange(selectedList.filter(el => !selectedItems.includes(el)));
    } else {
      const restItems = selectedList.filter(el => !selectedItems.includes(el));
      onChange([...restItems, ...selectedItems]);
    }
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <TextField
          InputProps={{
            endAdornment: <SearchIcon />,
          }}
          fullWidth
          id="hosted-on-keyword"
          label={searchLabel || 'Search'}
          onChange={e => setKeywordState(e.target.value)}
          value={keywordState}
        />
      </Grid>
      <Grid item xs={12}>
        <List className={classes.mainList} component="nav">
          {assetsWithCategory.map((category, key) => (
            <Fragment key={key}>
              {category.value && !keywordState && (
                <ListItem button dense onClick={() => handleToggleItems(category.assets.map(el => el.value))}>
                  <Checkbox
                    checked={isAtLeastOneItemSelected(category.assets.map(el => el.value))}
                    indeterminate={(() => {
                      const categoryItems = category.assets.map(el => el.value);
                      return isAtLeastOneItemSelected(categoryItems) && !areAllItemsSelected(categoryItems);
                    })()}
                  />
                  <ListItemText primary={category.value} />
                </ListItem>
              )}
              <List className={category.value ? classes.list : null}>
                {category.assets.map(
                  (asset, key) =>
                    (!keywordState || keywordContains(asset?.name)) && (
                      <ListItem button dense key={key} onClick={() => handleToggleItems([asset?.value])}>
                        <Checkbox checked={selectedList.includes(asset?.value)} />
                        <ListItemIcon className={classes.itemLogo}>
                          <img alt={asset.name} height={24} src={asset.icon_url} width={24} />
                        </ListItemIcon>
                        <ListItemText primary={asset.name} />
                      </ListItem>
                    ),
                )}
              </List>
            </Fragment>
          ))}
          {assetsWithoutCategory?.map(
            (asset, key) =>
              (!keywordState || keywordContains(asset?.name)) && (
                <ListItem button dense key={key} onClick={() => handleToggleItems([asset?.value])}>
                  <Checkbox checked={selectedList.includes(asset?.value)} />
                  <ListItemIcon className={classes.itemLogo}>
                    <img alt={asset?.name} height={24} src={asset?.icon_url} width={24} />
                  </ListItemIcon>
                  <ListItemText primary={asset?.name} />
                </ListItem>
              ),
          )}
        </List>
      </Grid>
    </Grid>
  );
};

export default memo(InventoryTableFilterGroupComponent);
