import Add from '@mui/icons-material/Add';
import { Box } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { Markdown } from '@dt/material-components';
import { palette } from '@dt/theme';
import DOMPurify from 'dompurify';
import { PolicyViolationsCommentsItem } from './PolicyViolationsCommentsItem';
import { PolicyViolationsCommentsListEmpty } from './PolicyViolationsCommentsListEmpty';
import Text from './Text';

/*
 * DO NOT use directly.
 *
 * Body details for "Advice/Notes" used by the following components:
 *
 * - PolicyViolationsCommentsSection
 * - PolicyViolationsCommentsSectionCard
 */
const PolicyViolationsCommentsSectionBodyComponent = function PolicyViolationsCommentsSectionBody({
  isCommentsWorkflowOpen,
  sortedComments,
  latestComment,
  onOpenWorkflow,
  isV2,
  hideTag = false,
}) {
  const latestPx = isV2 ? 3 : 1;
  const latestPy = isV2 ? 2 : 1;

  return (
    <Box
      bgcolor={palette.white}
      borderRadius={4}
      display="flex"
      flexDirection="column"
      px={sortedComments.length <= 0 || !latestComment ? 3 : latestPx}
      py={sortedComments.length <= 0 || !latestComment ? 2 : latestPy}
    >
      <Box
        alignItems="center"
        display="flex"
        flexDirection="row"
        justifyContent={isV2 && 'space-between'}
        marginBottom={1}
        marginTop={isV2 && -1}
      >
        <Text style={{ margin: 0, padding: 0 }} variant="body">
          Latest Message
        </Text>
        {/* Do not show when comments column is open or if we don't have any comments to show */}
        {!isCommentsWorkflowOpen && sortedComments.length > 0 && latestComment && !isV2 && (
          <Text style={{ cursor: 'pointer', marginLeft: '16px' }} variant="link">
            <span onClick={onOpenWorkflow}>view all</span>
          </Text>
        )}

        {isV2 && (
          <IconButton aria-label="View all" onClick={onOpenWorkflow}>
            <Add style={{ fontSize: 18 }} />
          </IconButton>
        )}
      </Box>

      {sortedComments.length <= 0 || !latestComment ? (
        <PolicyViolationsCommentsListEmpty onAddNewClicked={onOpenWorkflow} variant="inline" />
      ) : (
        <Box bgcolor={palette.gray50} borderRadius={4}>
          <PolicyViolationsCommentsItem
            author={latestComment.author_email}
            date={latestComment.date}
            hideTag={hideTag}
            isFlat
            is_internal_comment={latestComment.is_internal_comment}
          >
            <Markdown text={DOMPurify.sanitize(latestComment.text, { RETURN_DOM: true }).innerHTML} />
          </PolicyViolationsCommentsItem>
        </Box>
      )}
    </Box>
  );
};

export const PolicyViolationsCommentsSectionBody = PolicyViolationsCommentsSectionBodyComponent;
