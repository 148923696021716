import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Button, DialogActions, DialogContent, Grid } from '@mui/material';
import Box from '@mui/material/Box';
// @ts-ignore: #TODO-IMPLEMENT-TYPES-MATERIAL-COMPONENTS
import { Text } from '@dt/material-components';
// @ts-ignore: #TODO-IMPLEMENT-TYPES-REACH-ROUTER
import { useNavigate } from '@reach/router';
import PolicyViolationExportToJiraDialogHeader from './PolicyViolationExportToJiraDialogHeader';
const PolicyViolationExportToJiraDialogStepNoIntegrations = ({ handleClose }) => {
    const navigate = useNavigate();
    return (_jsxs(_Fragment, { children: [_jsx(PolicyViolationExportToJiraDialogHeader, { handleClose: handleClose, title: "No Jira integration has been configured yet." }), _jsx(DialogContent, { children: _jsx(Grid, { container: true, spacing: 1, children: _jsx(Grid, { item: true, xs: 12, children: _jsx(Box, { mb: 2, mt: 2, children: _jsx(Text, { component: "span", variant: "body", children: "To export a policy violation to Jira, a Jira instance must be configured first." }) }) }) }) }), _jsxs(DialogActions, { children: [_jsx(Button, { onClick: handleClose, children: "Cancel" }), _jsx(Button, { color: "primary", onClick: () => {
                            navigate && navigate(`/devsecops/integrations/jira/configure`);
                        }, variant: "contained", children: "Onboard Jira Instance" })] })] }));
};
export default PolicyViolationExportToJiraDialogStepNoIntegrations;
