import { PureComponent } from 'react';
import Button from '@mui/material/Button';

export default class InquireButton extends PureComponent {
  render() {
    return (
      <Button aria-label="Upgrade" color="primary" onClick={this.props.onClickInquire} variant="outlined">
        Upgrade
      </Button>
    );
  }
}
