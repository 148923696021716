import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Button, CircularProgress, DialogActions, DialogContent, Box, useTheme } from '@mui/material';
// @ts-ignore: #TODO-IMPLEMENT-TYPES-MATERIAL-COMPONENTS
import { Text } from '@dt/material-components';
import PolicyViolationExportToJiraDialogHeader from './PolicyViolationExportToJiraDialogHeader';
const PolicyViolationExportToJiraDialogStep2 = ({ handleClose, handleNext, jiraIntegrationName, policyViolationName, errorMessage, loading, }) => {
    const { palette } = useTheme();
    return (_jsxs(_Fragment, { children: [_jsx(PolicyViolationExportToJiraDialogHeader, { handleClose: handleClose, title: "A new Jira ticket is going to be created:" }), _jsxs(DialogContent, { children: [_jsxs(Box, { display: "flex", flexDirection: "column", mb: 2, mt: 2, children: [_jsx(Text, { component: "span", variant: "body", children: `Policy violation: ${policyViolationName}` }), _jsx(Text, { component: "span", variant: "body", children: `Jira integration: ${jiraIntegrationName}` })] }), errorMessage && (_jsx(Box, { bgcolor: palette.red[50], mt: 2, padding: 1, children: _jsx(Text, { component: "span", mb: 1, style: {
                                color: palette.red[10],
                            }, variant: "body", children: errorMessage }) }))] }), _jsxs(DialogActions, { children: [_jsx(Button, { onClick: handleClose, children: "Cancel" }), _jsx(Button, { color: "primary", disabled: loading, onClick: handleNext, startIcon: loading ? _jsx(CircularProgress, { size: 18 }) : null, children: "Confirm" })] })] }));
};
export default PolicyViolationExportToJiraDialogStep2;
