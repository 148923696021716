import { PureComponent } from 'react';
import ErrorIcon from '@mui/icons-material/Error';
import { Analytics, dataCreators } from '@dt/analytics';
import PageLayout from './PageLayout';
import PageTitle from './PageTitle';

export default class NotFoundPage extends PureComponent {
  render() {
    return (
      <PageLayout>
        <Analytics trackOnMount={dataCreators.notFoundPage(window.location.pathname)} />
        <PageTitle title="Not Found" />

        <div style={{ margin: 50, padding: 50, textAlign: 'center' }}>
          <ErrorIcon style={{ height: 100, width: 100 }} />
          <p>
            The page you are looking for does not exist or has been moved. <br />
            <a href="#" onClick={() => window.history.back()}>
              Go back
            </a>{' '}
            or head over to our <a href="https://www.securetheorem.com/">homepage</a> to find a new direction
          </p>
        </div>
      </PageLayout>
    );
  }
}
