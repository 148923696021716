import { memo, useState } from 'react';
import Box from '@mui/material/Box';
import TableCell from '@mui/material/TableCell';
import { Tag } from '@components';
import { AssetTagsEditDialog } from '@dt/components';

const InventoryTableCellAssetTag = ({ assetId, assetName, assetTags }) => {
  const [isEditDialogOpen, setEditDialogOpen] = useState(false);
  return (
    <TableCell align="left">
      <Box display={'flex'} flexWrap={'wrap'}>
        {assetTags?.map(
          (assetTag, idx) =>
            assetTag && (
              <Box key={idx} onClick={() => setEditDialogOpen(true)} style={{ cursor: 'pointer' }}>
                <Tag
                  key={assetTag.id}
                  label={`${assetTag.tag}${assetTag.value ? `:${assetTag.value}` : ''}`}
                  maxWidth={100}
                />
              </Box>
            ),
        )}
      </Box>
      {isEditDialogOpen && (
        <AssetTagsEditDialog
          assetId={assetId}
          assetName={assetName}
          isOpen={isEditDialogOpen}
          onClose={() => setEditDialogOpen(false)}
        />
      )}
    </TableCell>
  );
};

export default memo(InventoryTableCellAssetTag);
