import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Tooltip, styled } from '@mui/material';
// @ts-expect-error ignore js file
import Label from '@dt/material-components/Label';
const TagContainer = styled('div')(({ width, small, verticalAdjustment, rounded, backgroundColor, color, maxWidth, theme }) => ({
    '&&': {
        alignItems: 'center',
        backgroundColor: typeof backgroundColor === 'string' ? backgroundColor : theme.palette.gray[45],
        borderRadius: rounded ? 16 : 4,
        color: typeof color === 'string' ? color : theme.palette.gray[20],
        display: 'inline-flex',
        fontSize: small ? 12 : 14,
        height: '1.8em',
        marginTop: verticalAdjustment || 'initial',
        maxWidth: maxWidth,
        padding: small ? '2px 4px' : '2px 8px',
        width: width || 'fit-content',
    },
}));
const TagLabel = styled('span')(({ icon }) => ({
    '&&': { marginLeft: icon ? 4 : 0, maxWidth: 250, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' },
}));
const TagIcon = styled('div')(({ icon }) => ({
    '&&': {
        '& img, & svg': {
            maxHeight: '100%',
        },
        alignItems: 'center',
        display: 'flex',
        height: icon ? '100%' : 14,
    },
}));
const Tag = ({ icon, small, label, backgroundColor, color, tooltipText, rounded = false, maxWidth, verticalAdjustment, noPadding, width, }) => {
    return (_jsx(Tooltip, { title: tooltipText ?? label, children: _jsx(Box, { component: "span", display: "inline-flex", whiteSpace: "nowrap", children: _jsx(Label, { noPadding: noPadding, rounded: rounded, variant: "transparent", children: _jsxs(TagContainer, { backgroundColor: backgroundColor, color: color, maxWidth: maxWidth, rounded: rounded, small: small, verticalAdjustment: verticalAdjustment, width: width, children: [_jsx(TagIcon, { icon: icon, children: icon ?? '' }), _jsx(TagLabel, { icon: icon, children: label })] }) }) }) }));
};
export default Tag;
