import { memo } from 'react';
import { makeStyles } from '@mui/styles';
import Badge from '../../internal/Badge';
import IntegrationBadgeType from './IntegrationBadgeTypeEnum';

const useStyles = makeStyles({
  badge: {
    marginLeft: 5,
    marginRight: 5,
  },

  container: {
    display: 'flex',
    height: '100%',
    justifyContent: 'space-around',
    paddingLeft: 10,
    paddingRight: 10,
    width: '100%',
  },
});

const IntegrationBadgeTypeLabel = {
  [IntegrationBadgeType.CI_CD]: 'CI | CD',
  [IntegrationBadgeType.TRUSTKIT]: 'TrustKit',
  [IntegrationBadgeType.ISSUE_TRACKER]: 'Issue Tracker',
};

const _getEnabled = value => {
  if (typeof value === 'number') {
    return value === 100;
  }

  return value;
};

function IntegrationStats(props) {
  const { badgeTypes } = props;
  const classes = useStyles();

  return (
    <div className={classes.container}>
      {/* TODO@nw: Badge values should return a percentage badge of sorts for a number value. */}
      {badgeTypes.map(([type, value], i) => (
        <div className={classes.badge} key={i}>
          <Badge
            backgroundColor={!_getEnabled(value) ? 'lightgray' : '#2AAF5A'}
            label={IntegrationBadgeTypeLabel[type]}
          />
        </div>
      ))}
    </div>
  );
}

IntegrationStats.defaultProps = {
  badgeTypes: [
    [IntegrationBadgeType.CI_CD, false],
    [IntegrationBadgeType.TRUSTKIT, false],
    [IntegrationBadgeType.ISSUE_TRACKER, false],
  ],
};
export default memo(IntegrationStats);
