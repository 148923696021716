import { memo, useMemo, useState } from 'react';
import { Waypoint } from 'react-waypoint';
import { ClickAwayListener, MenuItem, MenuList, Popover, TextField } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import { useLazyQuery } from '@dt/apollo-link-schema-rest';
import asset_tags from '@dt/graphql-support/horizon/asset_tags';
import debounce from 'lodash/fp/debounce';
import ErrorState from '../containers/ErrorState';

const AssetTagsEditDialogAssetTagRowKey = ({ assetTag, assetTagValidationMessage, onUpdate, disabled }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const handleActionClose = () => setAnchorEl(null);

  const [fetchAssetTagKeysQuery, { data, error, loading, fetchMore }] = useLazyQuery(asset_tags.list, {
    fetchPolicy: 'network-only',
  });

  const onTextFieldChange = useMemo(
    () =>
      debounce(500)(text => {
        fetchAssetTagKeysQuery({
          variables: {
            filter_by_text: text,
          },
        });
      }),
    [fetchAssetTagKeysQuery],
  );

  const onSelectAssetTagKey = assetTagKey => {
    onUpdate(assetTagKey);
    setAnchorEl(null);
  };

  return (
    <>
      <TextField
        InputLabelProps={{
          shrink: true,
        }}
        disabled={disabled}
        error={Boolean(assetTagValidationMessage)}
        fullWidth
        helperText={assetTagValidationMessage || null}
        label="Key"
        margin="dense"
        onChange={e => {
          onUpdate({
            ...assetTag,
            tag: e.target.value,
          });
          if (e.target.value) {
            setAnchorEl(e.currentTarget);
            onTextFieldChange(e.target.value);
          }
        }}
        placeholder="Enter asset tag"
        required
        value={assetTag.tag}
        variant="outlined"
      />
      <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
        <Popover
          anchorEl={anchorEl}
          anchorOrigin={{
            horizontal: 'left',
            vertical: 'bottom',
          }}
          disableAutoFocus={true}
          disableEnforceFocus={true}
          id={'inventory-edit-action'}
          onClose={handleActionClose}
          open={(loading || error || Boolean(data)) && Boolean(anchorEl)}
          transformOrigin={{
            horizontal: 'left',
            vertical: 'top',
          }}
        >
          <MenuList
            style={{
              maxHeight: 400,
              overflow: 'auto',
              paddingBottom: 0,
              width: 250,
            }}
          >
            {error ? (
              <ErrorState error={error} />
            ) : loading ? (
              [...new Array(6)].map((_, key) => (
                <MenuItem dense key={key}>
                  <Skeleton animation="wave" height={30} width={`100%`} />
                </MenuItem>
              ))
            ) : (
              data?.asset_tag_key_list.asset_tag_keys.map(
                (key, idx) =>
                  !key.imported_from && (
                    <MenuItem
                      key={idx}
                      onClick={() =>
                        onSelectAssetTagKey({
                          id: key.id,
                          tag: key.name,
                          tag_id: key.id,
                        })
                      }
                    >
                      {key.name}
                    </MenuItem>
                  ),
              )
            )}
            {!loading &&
            !error &&
            data &&
            !data?.asset_tag_key_list.asset_tag_keys.find(key => key.name === assetTag.tag) ? (
              <MenuItem onClick={() => onSelectAssetTagKey(assetTag)}>{assetTag.tag} (new tag key)</MenuItem>
            ) : null}
            {fetchMore && (
              <Waypoint
                onEnter={() => {
                  if (fetchMore) {
                    fetchMore();
                  }
                }}
              >
                <MenuItem dense>
                  <Skeleton animation="wave" height={40} width={`100%`} />
                </MenuItem>
              </Waypoint>
            )}
          </MenuList>
        </Popover>
      </ClickAwayListener>
    </>
  );
};

export default memo(AssetTagsEditDialogAssetTagRowKey);
