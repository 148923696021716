import { memo } from 'react';
import Box from '@mui/material/Box';
import { makeStyles } from '@mui/styles';
import { palette } from '@dt/theme';
import { dateFormats } from '../../../apps/ahura/src/util/dateFormats';
import { formatDateDefault } from '../../../apps/ahura/src/util/formatDateDefault';

const useStyles = makeStyles({
  discovered: {
    backgroundColor: palette.yellow40,
    borderRadius: 2,
    color: palette.gray30,
    fontSize: 14,
    padding: '2px 4px',
  },
  lastTest: {
    backgroundColor: palette.gray50,
    borderRadius: 2,
    color: palette.gray30,
    fontSize: 14,
    padding: '2px 4px',
  },
});

const ToolkitAssetTableLastTested = ({ lastScanStatus, lastScanDateCreated, discoveredDate }) => {
  const classes = useStyles();

  return (
    <Box display="flex" justifyContent="center">
      {lastScanStatus === 'no-scan' ? (
        <Box className={classes.discovered} container justifyContent="center">
          New - Discovered on {formatDateDefault({ date: discoveredDate, formatStr: dateFormats.MMMddyyyy })}
        </Box>
      ) : (
        <Box className={classes.lastTest} justifyContent="center">
          Last tested on {formatDateDefault({ date: lastScanDateCreated, formatStr: dateFormats.MMMddyyyy })}
        </Box>
      )}
    </Box>
  );
};

export default memo(ToolkitAssetTableLastTested);
