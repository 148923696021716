import gql from 'graphql-tag';
export const grouped_security_events_by_id = gql `
  query GroupedSecurityEventsById($eventId: String!) {
    grouped_security_events_by_id(eventId: $eventId) {
      id
      authorized_user_id
      is_authenticated
      user_agent
      start_date
      end_date
      count
      event_type
      event_type_name
      severity
      severity_name
      description
      action_taken
      action_color
      response_status_code
      affected_api_operation_path
      http_method
      source {
        source_ip_address
        source_country_name
        source_country_code
        source_subdivision_name
        source_subdivision_code
      }
      asset {
        asset_uuid
        asset_name
        asset_type
        asset_type_name
        asset_type_icon_url
        asset_url
      }
      events {
        detected_auth_header
        stored_request_response_url
        correlation_headers {
          location
          name
          value
        }
        raw_request
        raw_response
        request {
          highlighted_keys
          expanded_keys
          root
        }
        response {
          highlighted_keys
          expanded_keys
          root
        }
        id
        date
        event_type
        event_type_name
        additional_info
        source {
          source_ip_address
          source_country_name
          source_country_code
          source_subdivision_name
          source_subdivision_code
        }
        asset {
          asset_uuid
          asset_name
          asset_type
          asset_type_name
          asset_type_icon_url
          asset_url
        }
        severity
        severity_name
        path
        method
        raw_request
      }
    }
  }
`;
export const security_events_filter_values = gql `
  query SecurityEventsFilterValues {
    security_events_filter_values {
      security_events_filter_values {
        belongs_to_filter
        icon_url
        name
        value
        value_category
      }
    }
  }
`;
export const grouped_security_events = gql `
  query GroupedSecurityEvents(
    $filter_by_named_filter: String
    $filter_by_severity: [String]
    $filter_by_event_type: [String]
    $filter_by_source_country_code: [String]
    $filter_by_http_method: [String]
    $show_security_events_only: Boolean
    $cursor: String
  ) {
    grouped_security_events(
      filter_by_named_filter: $filter_by_named_filter
      filter_by_severity: $filter_by_severity
      filter_by_event_type: $filter_by_event_type
      filter_by_source_country_code: $filter_by_source_country_code
      filter_by_http_method: $filter_by_http_method
      show_security_events_only: $show_security_events_only
      cursor: $cursor
    ) {
      pagination_information {
        next_cursor
        total_count
      }
      grouped_security_events {
        id
        action_color
        action_taken
        start_date
        end_date
        count
        event_type
        event_type_name
        response_status_code
        affected_api_operation_path
        http_method
        source {
          source_ip_address
          source_country_name
          source_country_code
          source_subdivision_name
          source_subdivision_code
        }
        asset {
          asset_uuid
          asset_name
          asset_type
          asset_type_name
          asset_type_icon_url
          asset_url
        }
        severity
        severity_name
        authorized_user_id
        is_authenticated
      }
    }
  }
`;
export const security_events = gql `
  query SecurityEvents(
    $filter_by_asset_id: ID
    $filter_by_event_type: [String]
    $filter_by_source_country_code: String
    $filter_by_source_subdivision_code: String
    $filter_by_severity: String
    $filter_by_named_filter: String
  ) {
    security_events(
      filter_by_asset_id: $filter_by_asset_id
      filter_by_event_type: $filter_by_event_type
      filter_by_source_country_code: $filter_by_source_country_code
      filter_by_source_subdivision_code: $filter_by_source_subdivision_code
      filter_by_severity: $filter_by_severity
      filter_by_named_filter: $filter_by_named_filter
    ) {
      pagination_information {
        next_cursor
        total_count
      }
      security_events {
        date
        method
        path
        event_type
        event_type_name
        source {
          source_ip_address
          source_country_name
          source_country_code
          source_subdivision_name
          source_subdivision_code
        }
        asset {
          asset_uuid
          asset_url
          asset_name
          asset_type
          asset_type_name
          asset_type_icon_url
        }
        severity
        severity_name
      }
    }
  }
`;
export const per_country_events_stats = gql `
  query PerCountryStats($filter_by_named_filter: String) {
    per_country_events_stats(filter_by_named_filter: $filter_by_named_filter) {
      per_country_events_stats {
        country_code
        country_name
        total_events_count
        events_count {
          event_type
          event_type_name
          count
        }
        subdivisions {
          subdivision_code
          subdivision_name
          total_events_count
          events_count {
            event_type
            event_type_name
            count
          }
        }
      }
    }
  }
`;
