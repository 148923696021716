import { memo } from 'react';
import { Link } from '@reach/router';
import ExtLink from './ExtLink';

// This component is tailored to be used within apps that use
// `@reach/router`. We probably need something similar for apps
// that use `react-router` for when we need to add new left side
// navigation that `web` portal uses.
function NavLink({ children, activeStyle, to, text, exact = false, redirect = false, target = '_blank', ...rest }) {
  // TODO: Temporary hack to prevent highlighting duplicate menu entries
  const disableHighlight = to === '/cloud/policies/default' && text === 'CSPM';

  return redirect ? (
    <ExtLink {...rest} target={redirect ? target : '_self'} text={text} to={to}>
      {children}
    </ExtLink>
  ) : (
    <Link
      {...rest}
      getProps={({ isCurrent, isPartiallyCurrent }) => {
        let style = {};
        if (exact) {
          if (isCurrent && !disableHighlight) style = activeStyle;
        } else {
          if (isPartiallyCurrent && !disableHighlight) style = activeStyle;
        }
        return {
          style: { ...style },
        };
      }}
      text={text}
      to={to}
    >
      {children}
    </Link>
  );
}

export default memo(NavLink);
