import { Component } from 'react';
import { Link, Route } from 'react-router-dom';
import MenuItem from './Item';

export class MenuLinkItemReactRouter extends Component {
  render() {
    const { path, exact, strict, tooltip, replace, hasSubMenu, isSubMenu, isChildApp, ...appMenuItemProps } =
      this.props;

    return (
      <Route exact={exact} path={path} strict={strict}>
        {({ match }) => {
          if (path) {
            return (
              <Link replace={replace} style={{ display: 'block' }} to={path}>
                <MenuItem
                  active={!!match}
                  hasSubMenu={hasSubMenu}
                  isChildApp={isChildApp}
                  isSubMenu={isSubMenu}
                  tooltip={tooltip}
                  {...appMenuItemProps}
                />
              </Link>
            );
          }

          return <MenuItem {...appMenuItemProps} />;
        }}
      </Route>
    );
  }
}
