import { memo } from 'react';
import { Card, Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import { PolicyViolationLabel, RelevanceTag } from '@dt/material-components';
import ComplianceTagStrip from '@dt/material-components/compliance-tag/ComplianceTagStrip';
import { Link } from '@reach/router';
import { dateFormats } from '../../util/dateFormats';
import { formatDateDefault } from '../../util/formatDateDefault';
import { affectedComponentDescription } from './util';

const breakpoint = 1100;

const styles = () => {
  return {
    reflowContent: {
      margin: '0 8px',
      [`@media (min-width: ${breakpoint}px)`]: {
        display: 'none',
      },
    },
    reflowHide: {
      [`@media (max-width: ${breakpoint - 1}px)`]: {
        display: 'none',
      },
    },
    row: {
      alignItems: 'center',
      display: 'flex',
      margin: '8px 0',
      [`@media (max-width: ${breakpoint - 1}px)`]: {
        flexWrap: 'wrap',
      },
    },
    rowItem: {
      marginRight: 8,
    },
    rowLeft: {
      alignItems: 'center',
      display: 'flex',
      marginRight: 8,
      overflow: 'hidden',
      textOverflow: 'ellipsis',

      [`@media (max-width: ${breakpoint - 1}px)`]: {
        flexWrap: 'wrap',
        justifySelf: 'flex-start',
      },
    },
    rowRight: {
      alignItems: 'center',
      display: 'flex',
      whiteSpace: 'nowrap',
      [`@media (min-width: ${breakpoint}px)`]: {
        justifyContent: 'flex-end',
        marginLeft: 'auto',
      },
      [`@media (max-width: ${breakpoint - 1}px)`]: {
        marginTop: 8,
      },
    },
    violationTitle: {
      // 36 is the width of the Relevance Tag
      [`@media (max-width: ${breakpoint - 35}px)`]: {
        marginBottom: 8,
        marginTop: 16,
      },
    },
  };
};

function PolicyViolationCard({ policy_violation, productBasePath, classes, isV2 }) {
  return (
    <Link
      style={{ display: 'block' }}
      to={
        isV2
          ? `${productBasePath}/v2/policy-violations/${policy_violation.id}`
          : `${productBasePath}/policy-violations/${policy_violation.id}`
      }
    >
      <Card style={{ padding: 16, width: '100%' }}>
        <div className={classes.row}>
          <div className={classes.rowLeft}>
            <div className={classes.rowItem}>
              <RelevanceTag relevance={policy_violation.violated_policy_rule.relevance} size="medium" />
            </div>
            <Typography className={classes.violationTitle} component="div" variant="body1">
              {policy_violation.violated_policy_rule.policy_rule_type.title}
            </Typography>
          </div>

          <div className={classes.rowRight}>
            <Typography className={classes.rowItem} component="div" variant="body2">
              {`ID: ${policy_violation.id.substr(0, 8)}`}
            </Typography>

            <PolicyViolationLabel status={policy_violation.status} />

            <div className={classes.reflowContent}>
              <Typography className={classes.rowItem} component="div" variant="body2">
                {policy_violation.date_resolved
                  ? `Resolved ${formatDateDefault({
                      date: policy_violation.date_resolved,
                      formatStr: dateFormats.MMMddyyyy,
                    })}`
                  : policy_violation.exception_date_created
                    ? `Closed ${formatDateDefault({
                        date: policy_violation.date_created,
                        formatStr: dateFormats.MMMddyyyy,
                      })}`
                    : `Caught ${formatDateDefault({
                        date: policy_violation.date_created,
                        formatStr: dateFormats.MMMddyyyy,
                      })}`}
              </Typography>
            </div>
          </div>
        </div>

        <div className={classes.row}>
          <Typography className={classes.rowLeft} component="div" variant="body2">
            {policy_violation.affected_asset_name && policy_violation.affected_asset_type_name
              ? `${policy_violation.affected_asset_type_name} at ${policy_violation.affected_asset_name}`
              : affectedComponentDescription(policy_violation)}
          </Typography>

          <div className={classes.rowRight}>
            <Typography className={classes.reflowHide} component="div" variant="body2">
              {policy_violation.date_resolved
                ? `Resolved ${formatDateDefault({
                    date: policy_violation.date_resolved,
                    formatStr: dateFormats.MMMddyyyy,
                  })}`
                : policy_violation.exception_date_created
                  ? `Closed ${formatDateDefault({
                      date: policy_violation.date_created,
                      formatStr: dateFormats.MMMddyyyy,
                    })}`
                  : `Caught ${formatDateDefault({
                      date: policy_violation.date_created,
                      formatStr: dateFormats.MMMddyyyy,
                    })}`}
            </Typography>
          </div>
        </div>

        <div className={classes.row}>
          <ComplianceTagStrip
            complianceReferences={policy_violation.violated_policy_rule.policy_rule_type.compliance_policy_references.map(
              ({ ...other }) => other,
            )}
            granular
          />
        </div>
      </Card>
    </Link>
  );
}

export default memo(withStyles(styles)(PolicyViolationCard));
