import { memo } from 'react';
import { Tooltip, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import {
  getDisplayTextFromComplianceStandard,
  getDisplayTextFromComplianceStandardCriteria,
  getIconFromComplianceStandard,
} from '@dt/material-components/compliance-tag/util';
import { palette } from '@dt/theme';

const useStyles = makeStyles({
  container: {
    alignItems: 'center',
    backgroundColor: palette.gray45,
    borderRadius: 4,
    display: 'flex',
    margin: '8px 4px 0',
    padding: '4px 6px',
  },
  image: {
    height: '14px',
    marginRight: 4,
    width: '14px',
  },
});

/*
 * Policy violations compliance tag to show compliance standards along with corresponding compliance references.
 *
 * @param complianceStandard - Compliance data from the backend.
 * @param complianceReferences - Compliance data from the backend. References compliance standards.
 */
const PolicyViolationsComplianceMultiTagComponent = function PolicyViolationsComplianceMultiTag({
  complianceStandard,
  complianceReferences,
}) {
  const classes = useStyles();

  const tooltipText = (
    <>
      <strong>
        {getDisplayTextFromComplianceStandard(complianceStandard)} - {complianceReferences.length} Reference
        {complianceReferences.length > 1 ? 's' : ''}
      </strong>
      <br />
      {complianceReferences.map(r => (
        <div key={r.compliance_standard_criteria}>
          <br />
          {getDisplayTextFromComplianceStandardCriteria(r.compliance_standard_criteria)}
          <hr />
          {r.description}
        </div>
      ))}
    </>
  );

  return (
    <Tooltip title={tooltipText}>
      <div className={classes.container}>
        <img className={classes.image} src={getIconFromComplianceStandard(complianceStandard)} />
        <Typography variant="caption">{getDisplayTextFromComplianceStandard(complianceStandard)}</Typography>
      </div>
    </Tooltip>
  );
};

export const PolicyViolationsComplianceMultiTag = memo(PolicyViolationsComplianceMultiTagComponent);
