import { memo } from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  content: {
    minWidth: '200px',
    width: '60%',
  },
});

function DrawerRight({ open, onClose, children }) {
  const css = useStyles();
  return (
    <Drawer anchor="right" classes={{ paper: css.content }} onClose={onClose} open={open}>
      <Box height="100%" padding="16px">
        {children}
      </Box>
    </Drawer>
  );
}

export default memo(DrawerRight);
