import { useEffect, useRef } from 'react';
import { LinearProgress } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { palette } from '@dt/theme';

const timeToComplete = 0.95;
const useStyles = makeStyles({
  bar1Determinate: {
    backgroundColor: palette.green,
  },
  bar1Indeterminate: ({ delay }) => ({
    animationDelay: `${-1 * delay * timeToComplete}s`,
    backgroundColor: palette.red,
  }),
  bar2Indeterminate: ({ delay }) => ({
    animationDelay: `${-1 * (delay * timeToComplete + timeToComplete)}s`,
    backgroundColor: palette.red,
  }),
  colorPrimaryCompleted: {
    backgroundColor: palette.green50,
  },
  colorPrimaryInProgress: {
    backgroundColor: palette.red50,
  },
  root: {
    height: 8,
  },
});

/*
 * MaterialUI LinearProgress with the ability delay the progress animation.
 */
function LinearProgressIndeterminate(props) {
  // Default props - Used instead of defaultProps for randomness.
  let delay;
  if (typeof props.delay !== 'number') {
    delay = Math.random();
  } else {
    delay = props.delay;
  }
  let randomCompleteInterval;
  if (typeof props.randomCompleteInterval !== 'number') {
    randomCompleteInterval = Math.floor(Math.random() * 60 * 1) * 1000;
  } else {
    randomCompleteInterval = props.randomCompleteInterval;
  }

  const classes = useStyles({ ...props, delay });
  const { isCompleted, onRandomComplete, randomComplete } = props;

  const isRunning = useRef(false);

  useEffect(() => {
    // Force completion?
    if (isCompleted) {
      isRunning.current = false;
      return;
    }

    if (isRunning.current) {
      return;
    }

    // Complete within random time interval.
    let completeTimeout = null;
    if (randomComplete) {
      completeTimeout = setTimeout(() => {
        if (!isRunning.current) {
          return;
        }
        isRunning.current = false;
        onRandomComplete && onRandomComplete();
      }, randomCompleteInterval);
    }

    const reset = () => {
      completeTimeout && clearTimeout(completeTimeout);
      completeTimeout = null;
    };

    isRunning.current = true;
    return reset;
  }, [isCompleted]); // eslint-disable-line
  // When other state is passed to 'useEffect' completion stops working.

  return (
    <>
      {!isCompleted ? (
        <LinearProgress
          classes={{
            bar1Indeterminate: classes.bar1Indeterminate,
            bar2Indeterminate: classes.bar2Indeterminate,
            colorPrimary: classes.colorPrimaryInProgress,
            root: classes.root,
          }}
          variant="indeterminate"
        />
      ) : (
        <LinearProgress
          classes={{
            bar1Determinate: classes.bar1Determinate,
            colorPrimary: classes.colorPrimaryCompleted,
            root: classes.root,
          }}
          value={100}
          variant="determinate"
        />
      )}
    </>
  );
}

export default LinearProgressIndeterminate;
