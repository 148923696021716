import { memo } from 'react';
import { Avatar, Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { palette } from '@dt/theme';
import ToolkitIcon from './ToolkitIcon';

const useStyles = makeStyles({
  iconContainer: {
    background: palette.brand,
  },
});

function ToolkitTitle({ title }) {
  const classes = useStyles();
  return (
    <Grid alignItems="center" container spacing={1}>
      <Grid item>
        <Avatar className={classes.iconContainer} variant="circular">
          <ToolkitIcon
            fontSize="small"
            style={{
              color: palette.white,
            }}
          />
        </Avatar>
      </Grid>
      <Grid item>
        <Typography color="textSecondary" gutterBottom variant="subtitle1">
          {title}
        </Typography>
      </Grid>
    </Grid>
  );
}

export default memo(ToolkitTitle);
