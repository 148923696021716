import TableCell from '@mui/material/TableCell';
import { AssetsTypeIcon } from '../../../components/assets/AssetsTypeIcon';

export const InventoryTableCellAssetTypeIconUrl = ({ asset }) => {
  return (
    <TableCell align="center" height={60} padding="none">
      <AssetsTypeIcon
        asset_type_icon_url={asset.asset_type_icon_url}
        asset_type_name={asset.asset_type_name}
        iconSize={24}
      />
    </TableCell>
  );
};
