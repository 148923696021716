import { memo, useEffect, useState } from 'react';
import ShareIcon from '@mui/icons-material/Share';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Link,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import copy from '@dt/components/src/copyToClipboard.util';
import { getInvariantUserAccount } from '@dt/session';
import { Link as RouterLink } from '@reach/router';
import Banner from './Banner';

function TooltipWrap({ children, showTooltip }) {
  if (showTooltip) {
    return (
      <Tooltip disableInteractive title={'This feature has been disabled'}>
        <span>{children}</span>
      </Tooltip>
    );
  }

  return children;
}

function ConfirmScreen({ isLoading, onCancel, onComplete }) {
  const [expiration, setExpiration] = useState(7);

  let max_days = 0;
  try {
    const { accountInfo } = getInvariantUserAccount();
    max_days = Number(accountInfo.secure_share_link_max_days);
  } catch (error) {
    max_days = 0;
  }

  return (
    <>
      <DialogContent component="div">
        <Typography component="div" variant="body2">
          <span id="select-expiration">This link will expire in </span>
          <Select labelId="select-expiration" onChange={e => setExpiration(Number(e.target.value))} value={expiration}>
            <MenuItem value={7}>7 days</MenuItem>
            <MenuItem value={15}>15 days</MenuItem>
            <MenuItem value={30}>30 days</MenuItem>
            {max_days < 40 && max_days >= 37 && <MenuItem value={37}>37 days</MenuItem>}
            {max_days >= 60 && <MenuItem value={60}>60 days</MenuItem>}
            {max_days >= 90 && <MenuItem value={90}>90 days</MenuItem>}
            {max_days >= 100 && <MenuItem value={100}>100 days</MenuItem>}
            {max_days >= 180 && <MenuItem value={180}>180 days</MenuItem>}
            {max_days >= 365 && <MenuItem value={180}>365 days</MenuItem>}
          </Select>
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>Cancel</Button>
        <Button
          color="primary"
          disabled={isLoading}
          endIcon={isLoading && <CircularProgress size={15} />}
          onClick={() => void onComplete(expiration)}
          variant="contained"
        >
          Create
        </Button>
      </DialogActions>
    </>
  );
}

function SuccessScreen({ link, manageShareLink, onClose, useReachRouter = false }) {
  function onCopy() {
    copy(link);
    onClose();
  }
  return (
    <>
      <DialogContent>
        <TextField
          InputLabelProps={{
            readOnly: true,
            shrink: true,
          }}
          fullWidth
          size="small"
          value={link}
          variant="outlined"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
        {manageShareLink && useReachRouter ? (
          <RouterLink to={manageShareLink} underline="none">
            <Button>Manage Links</Button>
          </RouterLink>
        ) : manageShareLink ? (
          <Link to={manageShareLink} underline="none">
            <Button>Manage Links</Button>
          </Link>
        ) : null}
        <Button color="primary" onClick={onCopy} variant="contained">
          Copy Link & Close
        </Button>
      </DialogActions>
    </>
  );
}

/*
 * NOTE: Use the `SharedLinksButton` facade component.
 *
 * Common implementation details for `SharedLinksButton*` components.
 *
 * @param title - Share dialog custom title
 * @param size - Size of the button
 * @param loading - Optional loading state passed by the caller
 * @param error - Optional ApolloError passed by the caller
 * @param manageShareLink - Route to the page where shared links are managed
 * @param renderButton - @deprecated Render a different share button. Pass in button props instead like size, variant, etc.
 * @param children - Share dialog custom content
 * @param onCreate - Workflow implemented by `SharedLinksButton*` to create a particular shared link type
 */
const SharedLinksButtonCommonComponent = function SharedLinksButtonCommon({
  loading,
  error,
  title,
  variant,
  size,
  manageShareLink,
  renderButton,
  children,
  onCreate,
  useReachRouter = false,
}) {
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [link, setLink] = useState('');
  const [localError, setLocalError] = useState('');

  let max_days = undefined;
  try {
    const { accountInfo } = getInvariantUserAccount();
    max_days = Number(accountInfo.secure_share_link_max_days);
  } catch (error) {
    max_days = undefined;
  }

  useEffect(() => {
    error && setLocalError(error?.message);
  }, [error]);

  useEffect(() => {
    // This is in the clean up to avoid cleaning up too early
    // while the material-ui animation is still going on.
    return () => {
      if (!isDialogOpen) {
        setLink('');
        setLoading(false);
        setLocalError('');
      }
    };
  }, [isDialogOpen]);

  return (
    <>
      <TooltipWrap showTooltip={max_days === 0}>
        <>
          {renderButton ? (
            renderButton(() => setDialogOpen(isDialogOpen => !isDialogOpen), max_days === 0)
          ) : (
            <Button
              disabled={max_days === 0}
              onClick={() => setDialogOpen(isDialogOpen => !isDialogOpen)}
              size={size || 'small'}
              startIcon={<ShareIcon />}
              variant={variant || 'contained'}
            >
              Secure Share
            </Button>
          )}
        </>
      </TooltipWrap>

      <Dialog
        aria-labelledby="create-share-link"
        maxWidth={'md'}
        onClose={() => setDialogOpen(false)}
        open={isDialogOpen}
      >
        <DialogTitle>
          <span id="create-share-link">{title}</span>
        </DialogTitle>
        <DialogContent>{children}</DialogContent>
        {localError && (
          <DialogContent>
            <Banner heading="Error" inline status="critical">
              {localError}
            </Banner>
          </DialogContent>
        )}
        {!link ? (
          <ConfirmScreen
            isLoading={loading || isLoading}
            onCancel={() => setDialogOpen(false)}
            onComplete={async expirationInDays => {
              setLoading(true);
              try {
                // Create shared link type.
                const link = await onCreate(
                  // validity_duration is in minutes. Multiply by 24 to transform days into hours, then by 60 to transform hours into minutes.
                  expirationInDays * 24 * 60,
                );

                setLoading(false);
                setLink(link);
              } catch (err) {
                setLocalError(
                  error?.message ||
                    'There was an error while sharing this page. Please contact support@datatheorem.com or try again later.',
                );
              }
            }}
          />
        ) : (
          <SuccessScreen
            link={link}
            manageShareLink={manageShareLink}
            onClose={() => setDialogOpen(false)}
            useReachRouter={useReachRouter}
          />
        )}
      </Dialog>
    </>
  );
};

export const SharedLinksButtonCommon = memo(SharedLinksButtonCommonComponent);
