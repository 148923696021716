import { memo } from 'react';
import { makeStyles } from '@mui/styles';
import { palette } from '@dt/theme';

const useStyles = makeStyles({
  header: ({ backgroundColor }) => ({
    backgroundColor: typeof backgroundColor === 'string' ? backgroundColor : palette.white,
    display: 'flex',
    position: 'sticky',
    top: 0,
    zIndex: 3,
  }),
});

function StickyHeader(props) {
  const { children, header } = props;

  const classes = useStyles(props);

  return (
    <div>
      {/* Header */}
      <div className={classes.header}>
        <div style={{ width: '100%' }}>{header}</div>
      </div>

      {/* Content */}
      <div>{children}</div>
    </div>
  );
}

export default memo(StickyHeader);
