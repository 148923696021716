import { memo } from 'react';
import { Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { palette } from '@dt/theme';

const useStyles = makeStyles({
  root: {
    '&:hover': {
      backgroundColor: 'initial',
      color: palette.blue10,
    },
    color: palette.blue20,
    display: 'inline',
    fontSize: 'initial',
    lineHeight: 'initial',
    minWidth: 'initial',
    padding: '0px',
    textTransform: 'initial',
    verticalAlign: 'initial',
  },
});

function TextButton({ children, onClick }) {
  const css = useStyles();
  return (
    <Button
      classes={{
        root: css.root,
      }}
      disableRipple
      onClick={onClick}
    >
      {children}
    </Button>
  );
}

export default memo(TextButton);
