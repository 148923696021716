import { assets_searches } from '@dt/horizon-api';
import { select, callPromise } from '@dt/redux-saga-wrapped-effects';
import { all, call, put, takeEvery } from 'redux-saga/effects';
import { getAllCloudResourcesLazily } from '../../cloud_resources/sagas';
import { getNetworkServicesLazily } from '../../network_services/sagas';
import { takePatternAndFetch } from '../../resource_fetch/sagas';
import { getAllRestfulAPIsLazily } from '../../restful_apis/sagas';
import { getAllWebApplicationsLazily } from '../../web_applications/sagas';
import { inventoryCreateSearchFailed, inventoryCreateSearchSucceed, inventorySearchClicked } from './actions';
import { getAllCloudResourceTypes } from './resource_fetch.sagas';

export default function* watchForResourceFetching() {
  yield all([
    takeEvery(inventorySearchClicked.toString(), inventorySearchCreateSaga),
    call(takePatternAndFetch, 'inventory/search/mounted*', function* () {
      // Common calls
      const calls = [yield call(getAllCloudResourceTypes, {})];

      yield all(calls);
    }),
    call(takePatternAndFetch, 'inventory/search/fetch*', function* (action) {
      const { search_id } = action.payload;
      const calls = [
        call(getNetworkServicesLazily, { search_id }),
        call(getAllRestfulAPIsLazily, { search_id }),
        call(getAllCloudResourcesLazily, { search_id }),
        call(getAllWebApplicationsLazily, { search_id }),
      ];
      yield all(calls);
    }),
  ]);
}

function* inventorySearchCreateSaga() {
  const { query } = yield* select(state => state.inventory_search);

  try {
    const response = yield* callPromise(assets_searches.create, query);
    if (response._type === 'error') {
      throw new Error(response.title);
    }

    if (response.body.assets_searches) {
      const { id: asset_search_id } = response.body.assets_searches[0];
      yield put(inventoryCreateSearchSucceed(asset_search_id));
    } else {
      yield put(inventoryCreateSearchFailed());
    }
  } catch (e) {
    yield put(inventoryCreateSearchFailed());
  }
}
