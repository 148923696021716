import { memo, useState } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import { Button } from '@mui/material';
import InventoryTableEditDrawer from './InventoryTableEditDrawer';

const InventoryTableEditComponent = function InventoryTableEdit() {
  const [isEditDrawerOpen, setEditDrawerOpen] = useState(false);

  return (
    <>
      <Button onClick={() => setEditDrawerOpen(true)} size="small" startIcon={<EditIcon />} variant="contained">
        Edit Assets
      </Button>
      <InventoryTableEditDrawer onClose={() => setEditDrawerOpen(false)} open={isEditDrawerOpen} />
    </>
  );
};

export default memo(InventoryTableEditComponent);
