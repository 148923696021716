import { SpecialScanRequestTypeEnum, special_scan_requests } from '@dt/horizon-api';
import { callPromise } from '@dt/redux-saga-wrapped-effects';
import { all, call, delay, spawn, put, takeEvery } from 'redux-saga/effects';
import { getAllPolicies } from '../policies/sagas';
import { takePatternAndFetch } from '../resource_fetch/sagas';
import { getAllPiiReportsInApiResponses, getSpecialScanRequestTypeList } from '../special_scans/resource_fetch.sagas';
import { getInventoryStats } from './../dashboard/resource_fetch.sagas';
import { hackExtractForbidden, hackExtractMounted, hackExtractStartClicked } from './actions';

export default function* watchForResourceFetching() {
  yield all([
    call(takePatternAndFetch, hackExtractMounted.toString(), function* () {
      yield all([
        call(getInventoryStats, {}),
        call(getAllPolicies, {}),
        call(getAllPiiReportsInApiResponses, {}),
        call(getSpecialScanRequestTypeList, {
          type: SpecialScanRequestTypeEnum.HACK_AND_EXTRACT,
        }),
      ]);
    }),
    takeEvery(hackExtractStartClicked.toString(), hackExtractStartSaga),
  ]);
}

function* optimisticlyCreateScanRequest(piiTypeConfig) {
  // NOTE: We could do more with the response here to confirm request.
  const result = yield* callPromise(special_scan_requests.create, {
    scan_type: SpecialScanRequestTypeEnum.HACK_AND_EXTRACT,
    ...piiTypeConfig,
  });
  if (result.status === 403) {
    yield put(hackExtractForbidden());
  }
}

function* hackExtractStartSaga(action) {
  // NOTE: Not having to wait for this response allows the UI to respond faster.
  //       This is extremly optimistic though since the request might have failed.
  //       We are doing this to determine if users actually use this H&E feature or not.
  yield spawn(optimisticlyCreateScanRequest, action.payload.configuration);
  //       We need to wait to provide a slightly better gurantee that the backend received the request.
  yield delay(2000);
  yield call(
    getSpecialScanRequestTypeList,
    {
      type: SpecialScanRequestTypeEnum.HACK_AND_EXTRACT,
    },
    {
      forceUpdate: true,
    },
  );
}
