import { memo } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionActions, AccordionDetails, AccordionSummary, Chip, Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import { Markdown } from '@dt/material-components';

const styles = {
  expanded: {},
  ruleDescriptionBody: {},
  ruleDetails: {
    flexDirection: 'column',
  },
  summaryCenter: {
    flexGrow: 1,
    paddingRight: 8,
  },
  summaryContent: {
    '&$expanded': {
      marginRight: 32,
    },
    cursor: 'default',
    flexDirection: 'column',
    marginRight: 32,
  },
  summaryFirstRow: {
    '@media (max-width: 750px)': {
      flexWrap: 'wrap',
    },
    alignItems: 'center',
    display: 'flex',
  },
  summaryLeftSide: {
    display: 'flex',
    paddingRight: 8,
  },
  summaryRightSide: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  summaryRoot: {
    paddingLeft: 24,
    paddingRight: 24,
  },
  summarySecondRow: {
    display: 'flex',
    paddingTop: 8,
  },
};

/*
 * @deprecated In favor of using the 'components/policy_rules/PolicyRulesCard'.
 */
function PolicyRuleBase({ title, description, summaryRightSide, summarySecondRow, actions, children, classes, count }) {
  const id = `policy-rule-${title}`;
  return (
    <Accordion data-testid={id}>
      <AccordionSummary
        classes={{
          content: classes.summaryContent,
          expanded: classes.expanded,
          root: classes.summaryRoot,
        }}
        expandIcon={<ExpandMoreIcon style={{ cursor: 'pointer' }} />}
      >
        <div className={classes.summaryFirstRow}>
          <div className={classes.summaryLeftSide}>
            <Typography style={{ cursor: 'pointer', maxWidth: 600 }} variant="subtitle1">
              {title}
            </Typography>
          </div>

          <div className={classes.summaryCenter}>
            {typeof count === 'number' && (
              <Chip aria-label={`${count} Unresolved`} label={`${count} Unresolved`} size="small" />
            )}
          </div>

          <div className={classes.summaryRightSide}>{summaryRightSide}</div>
        </div>
        <div className={classes.summarySecondRow}>{summarySecondRow}</div>
      </AccordionSummary>
      <AccordionDetails classes={{ root: classes.ruleDetails }}>
        <div className={classes.ruleDescriptionBody}>
          <Typography component="div" gutterBottom variant="body2">
            <Markdown inline text={description} />
          </Typography>
          {children}
        </div>
      </AccordionDetails>
      <AccordionActions>{actions}</AccordionActions>
    </Accordion>
  );
}

export default memo(withStyles(styles)(PolicyRuleBase));
