import { memo, useRef, useState } from 'react';
import { makeStyles } from '@mui/styles';
import useMouseMove from './hooks/use_mouse_move';

const useStyles = makeStyles(() => ({
  container: props => ({
    height: props.height,
    paddingBottom: 4,
    position: 'relative',
  }),
  dragger: {
    background: 'black',
    cursor: 'row-resize',
    height: 4,
    left: 'calc(50% - 25px)',
    position: 'relative',
    top: 4,
    width: 25,
  },
}));

function ResizableBox({
  children,
  options = {
    defaultHeight: 300,
    maxHeight: 800,
    minHeight: 200,
  },
  className,
}) {
  const ref = useRef(null);
  const [h, setH] = useState(options?.defaultHeight);
  const classes = useStyles({
    height: h,
  });
  const [onDown] = useMouseMove(e => {
    if (ref.current) {
      const newH = e.clientY - ref.current.offsetTop;
      if (newH > options?.minHeight && newH < options?.maxHeight) {
        setH(newH);
      }
    }
  });

  return (
    <div className={classes.container} ref={ref}>
      {children}
      <div className={className || classes.dragger} draggable={false} onMouseDown={onDown} />
    </div>
  );
}

export default memo(ResizableBox);
