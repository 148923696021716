import { PureComponent } from 'react';

/**
 * Image will only display a image if it was successful at loading it.
 * Otherwise it will hide it.
 *
 * This is mostly just to hide image icons which do not comply with the current Content Security Policy
 * And fail when trying to load them
 */

export default class Image extends PureComponent {
  state = {
    failed: false,
    prevSrc: this.props.src,
  };

  onFail = () => {
    this.setState({ failed: true });
  };

  static getDerivedStateFromProps({ src }, { failed, prevSrc }) {
    if (failed && src !== prevSrc) {
      return {
        failed: false,
        prevSrc: src,
      };
    }

    return null;
  }

  render() {
    const { style, className, src, alt, onFailSrc, height, width } = this.props;
    const { failed } = this.state;

    const styles = {
      display: failed && !onFailSrc ? 'none' : 'block',
      maxHeight: '100%',
      maxWidth: '100%',
      ...style,
    };

    return (
      <img
        alt={alt}
        className={className}
        height={height}
        onError={this.onFail}
        src={failed ? onFailSrc : src}
        style={styles}
        width={width}
      />
    );
  }
}
