import { memo } from 'react';
import AndroidLogo from '@mui/icons-material/Android';
import GenericSmartphoneIcon from '@mui/icons-material/Smartphone';
import AmazonLogo from '@dt/material-components/svg_components/AmazonLogo';
import AppleLogo from '@dt/material-components/svg_components/AppleLogo';
import OculusLogo from '@dt/material-components/svg_components/OculusLogo';
import WindowsLogo from '@dt/material-components/svg_components/WindowsLogo';

const PLATFORM_TO_COMPONENT = {
  ANDROID: AndroidLogo,
  ANDROID_AMAZON: AmazonLogo,
  ANDROID_FACEBOOK_PORTAL: AndroidLogo,
  ANDROID_OCULUS: OculusLogo,
  ANDROID_OCULUS_QUEST: AndroidLogo,
  ANDROID_OCULUS_RIFT: AndroidLogo,
  ANDROID_SAMSUNG: AndroidLogo,
  CROSS_PLATFORM: GenericSmartphoneIcon,
  IOS: AppleLogo,
  IOS_ON_MAC: AppleLogo,
  MACOS: GenericSmartphoneIcon,
  MAC_CATALYST: AppleLogo,
  SERVER_SIDE: GenericSmartphoneIcon,
  TVOS: AppleLogo,
  UNKNOWN: GenericSmartphoneIcon,
  WATCHOS: AppleLogo,
  WINDOWS_PHONE: WindowsLogo,
};

export function isSupportedPlatform(platform) {
  return (
    platform === 'ANDROID' ||
    platform === 'IOS' ||
    platform === 'MACOS' ||
    platform === 'WINDOWS_PHONE' ||
    platform === 'ANDROID_OCULUS' ||
    platform === 'ANDROID_AMAZON' ||
    platform === 'ANDROID_FACEBOOK_PORTAL' ||
    platform === 'IOS_ON_MAC'
  );
}

const MobileAppsPlatformIconComponent = function MobileAppsPlatformIcon({ platform, ...rest }) {
  const Comp = PLATFORM_TO_COMPONENT[platform];
  return <Comp {...rest} />;
};

export const MobileAppsPlatformIcon = memo(MobileAppsPlatformIconComponent);
