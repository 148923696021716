import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Stack, styled } from '@mui/material';
import Text from '@dt/ahura/src/components/Text';
const LabelText = styled(Text)({
    margin: 0,
});
const TopStack = styled(Stack)(({ theme }) => ({
    maxWidth: '270px',
    padding: theme.spacing(1),
}));
export const MenuItemWithIcon = ({ icon, label, description }) => {
    return (_jsxs(TopStack, { direction: "row", spacing: 2, children: [_jsx("img", { alt: `${label} Icon`, src: icon }), _jsxs(Stack, { children: [_jsx(LabelText, { component: "p", children: label }), _jsx(Text, { component: "span", variant: "label", children: description })] })] }));
};
