import { memo } from 'react';
import { Tag } from '@components';
import { HostedOnEnum } from '@dt/horizon-api';
import HostedOnIcon from './HostedOnIcon';

const HostedOnDisplayNameDict = {
  [HostedOnEnum.AMAZON_WEB_SERVICES]: 'AWS',
  [HostedOnEnum.GOOGLE_CLOUD_PLATFORM]: 'GCP',
  [HostedOnEnum.APIGEE]: 'Apigee',
  [HostedOnEnum.AZURE]: 'Azure',
  [HostedOnEnum.MULESOFT]: 'Mulesoft',
  [HostedOnEnum.KONG]: 'Kong',
  [HostedOnEnum.DETECTION_FAILED]: 'Undetected',
  [HostedOnEnum.AXWAY]: 'Axway',
  [HostedOnEnum.ON_PREMISE]: 'On Premise',
  [HostedOnEnum.GITHUB]: 'Github',
  [HostedOnEnum.GITHUB_ENTERPRISE_SERVER]: 'Github Enterprise Server',
  [HostedOnEnum.BITBUCKET]: 'Bitbucket',
  [HostedOnEnum.CLOUDFLARE]: 'Cloudflare',
  [HostedOnEnum.AKAMAI]: 'Akamai',
  [HostedOnEnum.UNKNOWN]: 'Unknown',
};

export const getDisplayNameForHostedOn = hosted_on => {
  if (HostedOnDisplayNameDict[hosted_on]) {
    return HostedOnDisplayNameDict[hosted_on];
  } else {
    throw new Error(`Missing hosted on string for ${hosted_on}, HostedOn is likely out of date`);
  }
};

const TagHostedOn = ({ hosted_on, variant }) => (
  <Tag
    icon={<HostedOnIcon hosted_on={hosted_on} />}
    label={`${variant === 'large' ? 'Hosted on ' : ''}${getDisplayNameForHostedOn(hosted_on)}`}
  />
);

export default memo(TagHostedOn);
