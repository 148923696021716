import { memo } from 'react';
import { Tag } from '@components';
import AssetHostedOnIcon from './AssetHostedOnIcon';

const TagHostedOn = ({ maxWidth, name, iconUrl, variant }) => (
  <Tag
    icon={<AssetHostedOnIcon iconUrl={iconUrl} name={name} />}
    label={`${variant === 'large' ? 'Hosted on ' : ''}${name}`}
    maxWidth={maxWidth}
  />
);

export default memo(TagHostedOn);
