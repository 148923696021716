import { memo } from 'react';
import { Tooltip } from '@mui/material';
import Box from '@mui/material/Box';
import { makeStyles } from '@mui/styles';
import { palette } from '@dt/theme';
import HostedOnIcon from './HostedOnIcon';
import { getDisplayNameForHostedOn } from './TagHostedOn';

const useStyles = makeStyles({
  icon: {
    marginRight: 2,
    padding: 2,
  },
  label: {
    alignItems: 'center',
    backgroundColor: palette.gray45,
    borderRadius: 2,
    color: palette.gray30,
    display: 'flex',
    fontSize: 12,
    padding: '0 6px',
    width: 'auto',
  },
  text: {
    padding: '4px 2px',
  },
});

function CountHostedOnType({ hosted_on, count }) {
  const classes = useStyles();

  const hostedName = getDisplayNameForHostedOn(hosted_on);

  return (
    <Box display="inline-block" m="4px">
      <Tooltip aria-label={`${count} ${hostedName}`} title={`${count} ${hostedName}`}>
        <Box className={classes.label}>
          <HostedOnIcon hosted_on={hosted_on} />
          <span className={classes.text}>{`${count} ${hostedName}`}</span>
        </Box>
      </Tooltip>
    </Box>
  );
}

export default memo(CountHostedOnType);
