import { forwardRef, memo } from 'react';
import { Avatar, Typography, useMediaQuery } from '@mui/material';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { Tag } from '@components';
import { fromNowTime } from '../../apps/ahura/src/util/fromNowTime';

const useStyles = makeStyles(theme => ({
  author: {
    alignItems: 'center',
    color: theme.palette.gray[30],
    display: 'flex',
  },
  comment: {
    minWidth: 350,
  },
  date: {
    color: theme.palette.gray[35],
    lineHeight: '0.75',
  },
  internalNote: {
    backgroundColor: theme.palette.orange[50],
  },
  metaContainer: {
    alignItems: 'center',
    display: 'flex',
    fontSize: 14,
    marginBottom: 10,
  },
  text: ({ isFlat, matchesLg }) => ({
    backgroundColor: !isFlat ? theme.palette.white.main : 'unset',
    borderRadius: 5,
    color: theme.palette.gray[20],
    fontSize: 14,
    lineHeight: '1.65',
    marginLeft: matchesLg ? (!isFlat ? 45 : 50) : !isFlat ? 0 : 50,
    marginTop: matchesLg ? (!isFlat ? -24 : -30) : 0,
    padding: !isFlat ? 10 : 'unset',
    whiteSpace: 'pre-wrap',
  }),
}));

/*
 * Show a policy violation comment to the user.
 *
 * @param author - Author of the comment provided by the backend
 * @param date - Date posted of the comment provided by the backend
 * @param is_internal_comment - Indicates if the comment was marked as an internal comment provided by the backend
 * @param isFlat - Whether or not to render the component flattened or not
 * @param children - Content of the comment
 */
const PolicyViolationsCommentsItemComponent = function PolicyViolationsCommentsItem(
  { author, date, is_internal_comment, isFlat, children, hideTag },
  ref,
) {
  const { breakpoints, palette } = useTheme();
  const matchesLg = useMediaQuery(breakpoints.up('lg'));
  const classes = useStyles({ isFlat, matchesLg });

  const authorParts = author?.split(' ');

  return (
    <Box className={classes.comment} display="flex" flexDirection="column" px={2} py={1} ref={ref}>
      <div>
        <div className={classes.metaContainer}>
          <Avatar
            style={{
              backgroundColor: author === 'Data Theorem' ? palette.brand[40] : palette.gray[30],
              color: author === 'Data Theorem' ? palette.brand[50] : palette.gray[45],
              marginRight: 10,
              textTransform: 'uppercase',
            }}
          >
            {authorParts
              ? `${authorParts[0].charAt(0)}${
                  authorParts.length > 1 ? authorParts[authorParts.length - 1].charAt(0) : ''
                }`
              : '?'}
          </Avatar>

          <div
            style={{
              alignItems: matchesLg ? 'center' : 'flex-start',
              display: 'flex',
              flexDirection: matchesLg ? 'row' : 'column',
              justifyContent: 'space-between',
              marginTop: matchesLg ? -24 : 0,
              width: '100%',
            }}
          >
            {!hideTag && author && (
              <div className={classes.author}>
                <Typography style={{ fontSize: 14, fontWeight: 700 }} variant="body1">
                  {author}
                </Typography>
                {is_internal_comment && (
                  <Tag
                    backgroundColor={palette.orange[50]}
                    label="Internal Note"
                    tooltipText="Comment is an Internal Note"
                  />
                )}
              </div>
            )}

            <div className={classes.date}>{date && fromNowTime(date)}</div>
          </div>
        </div>
      </div>

      <div className={classes.text}>{children}</div>
    </Box>
  );
};

export const PolicyViolationsCommentsItem = memo(forwardRef(PolicyViolationsCommentsItemComponent));
