import Box from '@mui/material/Box';
import { PolicyViolationsStatusesTag } from './PolicyViolationsStatusesTag';

const containerStyle = {
  alignItems: 'flex-end',
  display: 'flex',
  marginLeft: -8,
  width: 'fit-content', // counteract padding on first child element
};

export const PolicyViolationsStatusesTagStripCounts = ({ urgent, important, proactive }) => {
  return (
    <div style={containerStyle}>
      <Box mr={1}>
        <PolicyViolationsStatusesTag count={urgent} type="urgent" />
      </Box>
      <Box mr={1}>
        <PolicyViolationsStatusesTag count={important} type="important" />
      </Box>
      <PolicyViolationsStatusesTag count={proactive} type="info" />
    </div>
  );
};
