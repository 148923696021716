import { memo } from 'react';
import Button from '@mui/material/Button';
import { HostedOnEnum } from '@dt/horizon-api';
import HostedOnIcon from './HostedOnIcon';

const HostedOnDisplayNameDict = {
  [HostedOnEnum.AMAZON_WEB_SERVICES]: 'AWS',
  [HostedOnEnum.GOOGLE_CLOUD_PLATFORM]: 'GCP',
  [HostedOnEnum.APIGEE]: 'Apigee',
  [HostedOnEnum.AZURE]: 'Azure',
  [HostedOnEnum.MULESOFT]: 'Mulesoft',
  [HostedOnEnum.KONG]: 'Kong',
  [HostedOnEnum.DETECTION_FAILED]: 'Undetected',
  [HostedOnEnum.AXWAY]: 'Axway',
  [HostedOnEnum.ON_PREMISE]: 'On Premise',
  [HostedOnEnum.GITHUB]: 'Github',
  [HostedOnEnum.GITHUB_ENTERPRISE_SERVER]: 'Github Enterprise Server',
  [HostedOnEnum.BITBUCKET]: 'Bitbucket',
  [HostedOnEnum.CLOUDFLARE]: 'Cloudflare',
  [HostedOnEnum.AKAMAI]: 'Akamai',
  [HostedOnEnum.UNKNOWN]: 'Unknown',
};

const getDisplayNameForHostedOn = hosted_on => {
  if (HostedOnDisplayNameDict[hosted_on]) {
    return HostedOnDisplayNameDict[hosted_on];
  } else {
    throw new Error(`Missing hosted on string for ${hosted_on}, HostedOn is likely out of date`);
  }
};

const CloudConsoleUrlButton = ({ hosted_on, cloud_console_url }) => {
  const hostedOnDisplayName = getDisplayNameForHostedOn(hosted_on);

  return (
    <a href={cloud_console_url} rel="noopener noreferrer" target="_blank">
      <Button
        aria-label={`View on ${hostedOnDisplayName}`}
        startIcon={<HostedOnIcon hosted_on={hosted_on} size={18} />}
        variant="contained"
      >
        <span>View in {hostedOnDisplayName}</span>
      </Button>
    </a>
  );
};

export default memo(CloudConsoleUrlButton);
