import { memo, useContext, useEffect, useMemo, useState } from 'react';
import { Card, Grid } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import Skeleton from '@mui/material/Skeleton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import { makeStyles } from '@mui/styles';
import { useQuery } from '@dt/apollo-link-schema-rest';
import { ErrorState } from '@dt/components';
import assets from '@dt/graphql-support/horizon/assets';
import { Button, Text } from '@dt/material-components';
import { palette } from '@dt/theme';
import useTableSort from '../../../hooks/use_table_sort';
import { ShadowAssetsContext } from '../../../pages/api/v2/ApiSecureShadowAssetsPage';
import InventoryTableCellDiscoveredVia from '../../assets/AssetTableCellDiscoveredVia';
import InventoryTableCellDiscoveryDate from '../../assets/AssetTableCellDiscoveryDate';
import InventoryTableCellHostedOn from '../../assets/AssetTableCellHostedOn';
import InventoryTableCellAssetGroupName from './InventoryTableCellAssetGroupName';
import InventoryTableCellAssetName from './InventoryTableCellAssetName';
import InventoryTableCellAssetTag from './InventoryTableCellAssetTag';
import { InventoryTableCellAssetTypeIconUrl } from './InventoryTableCellAssetTypeIconUrl';
import InventoryTableCellPolicyViolations from './InventoryTableCellPolicyViolations';
import InventoryTableCellRelatedAppsCount from './InventoryTableCellRelatedAppsCount';
import InventoryTableCellShadowReasons from './InventoryTableCellShadowReasons';
import InventoryTableCellVendor from './InventoryTableCellVendor';
import InventoryTableEmptyState from './InventoryTableEmptyState';

// prettier-ignore
export const columnEnum = {
  asset_group:         ('asset_group'),
  asset_name:          ('asset_name'),
  asset_tag:           ('asset_tag'),
  asset_type_icon_url: ('asset_type_icon_url'),
  discovered_date:     ('discovered_date'),
  discovered_via:      ('discovered_via'),
  hosted_on:           ('hosted_on'),
  policy_violations:   ('policy_violations'),
  related_apps:        ('related_apps'),
  shadow_reasons:      ('shadow_reasons'),
  vendor:              ('vendor'),
};

const columnType = {
  [columnEnum.asset_group]: {
    justify: 'center',
    title: 'Current Asset Group',
    width: 150,
  },
  [columnEnum.asset_name]: {
    justify: 'left',
    sortDirection: 'asc',
    sortField: 'asset_name',
    title: 'Asset Name',
    width: 220,
  },
  [columnEnum.asset_type_icon_url]: {
    justify: 'center',
    title: 'Type',
    width: 40,
  },
  [columnEnum.discovered_date]: {
    justify: 'center',
    sortDirection: 'desc',
    sortField: 'date_created',
    title: 'Discovery Date',
    width: 170,
  },
  [columnEnum.discovered_via]: {
    justify: 'left',
    sortDirection: 'asc',
    sortField: 'discovered_via',
    title: 'Discovered Via',
    width: 220,
  },
  [columnEnum.hosted_on]: {
    justify: 'left',
    sortDirection: 'asc',
    sortField: 'hosted_on',
    title: 'Hosted On',
    width: 150,
  },
  [columnEnum.policy_violations]: {
    justify: 'left',
    sortDirection: 'desc',
    sortField: 'policy_violations_count',
    title: 'Policy Violations',
    width: 180,
  },
  [columnEnum.asset_tag]: {
    justify: 'center',
    sortDirection: 'asc',
    sortField: 'asset_tags',
    title: 'Asset Tag',
    width: 200,
  },
  [columnEnum.related_apps]: {
    justify: 'left',
    title: 'Related Apps',
    width: 140,
  },
  [columnEnum.vendor]: {
    justify: 'left',
    title: 'Vendor',
    width: 140,
  },
  [columnEnum.shadow_reasons]: {
    justify: 'left',
    title: 'Shadow Reasons',
    width: 450,
  },
};

const useStyles = makeStyles({
  table: {
    '& td,th': {
      padding: 4,
    },
    '& td,th:first-child': {
      paddingLeft: 8,
    },
    backgroundColor: palette.white,
    tableLayout: 'fixed',
    width: '100%',
  },
  tableHeader: {
    '& th': {
      backgroundColor: palette.white,
    },
  },
});

const InventoryTableComponent = function InventoryTable({
  columns,
  filters,
  productBasePath,
  isLoading,
  emptyStateVariant,
  allAssetsCheckedCount,
  setAllAssetsCheckedCount,
  ...restProps
}) {
  const css = useStyles();
  const [page, setPage] = useState(0);
  const { sortBy, sortDirection, handleSortChange, order_by } = useTableSort('date_created', 'desc');
  const { latestRefetchDate, isPolling } = useContext(ShadowAssetsContext);
  const pageSize = 10;

  const {
    data,
    loading: loadingQuery,
    fetchMore,
    error,
    refetch,
  } = useQuery(assets.list, {
    variables: {
      ...filters,
      order_by,
      page_size: pageSize,
    },
  });

  const loading = isPolling || loadingQuery || isLoading;

  const { currentPageIdRange, currentPageIdRangeChecked } = useMemo(() => {
    let currentPageIdRange = [];
    let currentPageIdRangeChecked = [];

    if (restProps.checkboxSelection) {
      currentPageIdRange =
        data?.asset_list.assets.slice(page * pageSize, page * pageSize + pageSize).map(({ id }) => id) || [];
      currentPageIdRangeChecked = restProps.checkedIds.filter(id => currentPageIdRange.includes(id));
    }

    return {
      currentPageIdRange,
      currentPageIdRangeChecked,
    };
  }, [data?.asset_list.assets, page, restProps?.checkedIds]);

  // reset Page number on filter changes
  useEffect(() => {
    setPage(0);
  }, [filters]);

  useEffect(() => {
    refetch();
  }, [latestRefetchDate, refetch]);

  const handleChangePage = (event, newPage) => {
    if ((data?.asset_list.assets.length || 0) / pageSize / (newPage + 1) <= 1) {
      fetchMore?.();
    }
    if (restProps.checkboxSelection && restProps.clearCheckboxOnPageChange) {
      // uncheck all Ids
      restProps.onChangeSelection([]);
    }
    setPage(newPage);
  };

  /* Checkbox related functions start */
  const toggleCheckBoxId = id => {
    if (restProps.checkboxSelection) {
      if (restProps.checkedIds.includes(id)) {
        restProps.onChangeSelection((restProps.checkedIds || []).filter(el => el !== id) || []);
      } else {
        restProps.onChangeSelection([...restProps.checkedIds, id]);
      }
    }
  };

  const toggleAllAssetIdsWithinCurrentPage = () => {
    if (restProps.checkboxSelection) {
      if (currentPageIdRangeChecked.length === pageSize) {
        // uncheck all Ids on page
        restProps.onChangeSelection(restProps.checkedIds.filter(id => !currentPageIdRange.includes(id)));
        setAllAssetsCheckedCount && setAllAssetsCheckedCount(0);
      } else {
        //check all Ids
        restProps.onChangeSelection([...new Set([...restProps.checkedIds, ...currentPageIdRange])]);
      }
    }
  };
  /* Checkbox related functions ends */

  // Deselect ids that were removed through triaging.
  useEffect(() => {
    const assetIds = data?.asset_list.assets.map(a => a.id);
    if (!assetIds) {
      return;
    }
    if (restProps.checkboxSelection && restProps.checkedIds.length > 0) {
      const updatedCheckedIds = restProps.checkedIds.filter(id => assetIds.includes(id));
      if (updatedCheckedIds.length !== restProps.checkedIds.length) {
        restProps.onChangeSelection(updatedCheckedIds);
      }
    }
  }, [data, restProps]);

  if (error) {
    return <ErrorState error={error} />;
  }

  const paginationInformation = data?.asset_list.pagination_information;
  let paginationCount = paginationInformation?.total_count || 0;

  if (paginationInformation?.is_approximate_total_count_enabled) {
    if (paginationInformation?.exact_total_count) {
      paginationCount = paginationInformation.exact_total_count;
    } else {
      paginationCount = paginationInformation?.hint_for_total_count || 0;
    }
  }

  const items = data?.asset_list.assets || [];

  const tableContainerFragment = (
    <>
      <TableContainer>
        <Table className={css.table} size="small" stickyHeader>
          <TableHead className={css.tableHeader}>
            <TableRow>
              {restProps.checkboxSelection && (
                <TableCell width={48}>
                  {loading ? (
                    <Skeleton animation="wave" height={42} width={42} />
                  ) : (
                    <Checkbox
                      checked={currentPageIdRangeChecked.length > 0}
                      data-testid="select-all-checkbox"
                      disabled={restProps.isDisabled}
                      indeterminate={
                        currentPageIdRangeChecked.length > 0 && currentPageIdRangeChecked.length < pageSize
                      }
                      onClick={() => toggleAllAssetIdsWithinCurrentPage()}
                    />
                  )}
                </TableCell>
              )}

              {columns.map((c, key) => {
                const column = columnType[c];
                let columnSortDirection = sortDirection;

                // normalizes the view so the down arrow is always the defailt sort
                if (column.sortDirection === 'asc') {
                  columnSortDirection = sortDirection === 'desc' ? 'asc' : 'desc';
                }

                return (
                  <TableCell
                    align={column.justify}
                    key={`header-${key}`}
                    sortDirection={column.sortField ? sortDirection : false}
                    width={column.width}
                  >
                    {loading ? (
                      <Skeleton animation="wave" height={45} width={'100%'} />
                    ) : column.sortField ? (
                      <TableSortLabel
                        active={sortBy === column.sortField}
                        direction={sortBy === column.sortField ? columnSortDirection : 'desc'}
                        onClick={() => {
                          handleSortChange(column.sortField, column.sortDirection);
                          setPage(0);
                        }}
                      >
                        {column.title}
                      </TableSortLabel>
                    ) : (
                      <Text
                        component={'p'}
                        style={{
                          margin: 0,
                        }}
                        variant={'body'}
                      >
                        {column.title}
                      </Text>
                    )}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {loading
              ? [...new Array(10)].map((_, key) => (
                  <TableRow key={`loading-${key}`}>
                    {restProps.checkboxSelection && (
                      <TableCell>
                        <Skeleton animation="wave" height={47} width={32} />
                      </TableCell>
                    )}
                    {columns.map((column, key) => (
                      <TableCell align={'center'} key={`loading-cell-${key}`} width={columnType[column].width}>
                        <Skeleton animation="wave" height={47} width={'100%'} />
                      </TableCell>
                    ))}
                  </TableRow>
                ))
              : items.slice(page * pageSize, page * pageSize + pageSize).map(asset => (
                  <TableRow
                    key={asset.id}
                    onClick={() => {
                      restProps.checkboxSelection && toggleCheckBoxId(asset.id);
                      setAllAssetsCheckedCount && setAllAssetsCheckedCount(0);
                    }}
                  >
                    {restProps.checkboxSelection && (
                      <TableCell>
                        <Checkbox
                          checked={restProps.checkedIds.includes(asset.id)}
                          data-testid={`${asset.name}-checkbox`}
                          disabled={restProps.isDisabled}
                        />
                      </TableCell>
                    )}
                    {columns.map(column =>
                      column === columnEnum.asset_type_icon_url ? (
                        <InventoryTableCellAssetTypeIconUrl asset={asset} key={`cell-iconurl-${asset.id}`} />
                      ) : column === columnEnum.asset_name ? (
                        <InventoryTableCellAssetName
                          asset={asset}
                          key={`cell-name-${asset.id}`}
                          productBasePath={productBasePath}
                        />
                      ) : column === columnEnum.policy_violations ? (
                        <InventoryTableCellPolicyViolations asset={asset} key={`cell-pv-${asset.id}`} />
                      ) : column === columnEnum.hosted_on ? (
                        <InventoryTableCellHostedOn
                          iconUrl={asset.hosted_on_icon_url}
                          key={`cell-hosted-${asset.id}`}
                          name={asset.hosted_on_name}
                        />
                      ) : column === columnEnum.discovered_via ? (
                        <InventoryTableCellDiscoveredVia
                          iconUrl={asset.discovered_via_icon_url}
                          key={`cell-discovered-${asset.id}`}
                          maxWidth={columnType[column].width}
                          name={asset.discovered_via_name}
                        />
                      ) : column === columnEnum.discovered_date ? (
                        <InventoryTableCellDiscoveryDate
                          date_created={asset.date_created}
                          key={`cell-date-${asset.id}`}
                        />
                      ) : column === columnEnum.asset_group ? (
                        <InventoryTableCellAssetGroupName
                          asset_group={asset.belongs_to_asset_group}
                          key={`cell-asset-group-${asset.id}`}
                        />
                      ) : column === columnEnum.asset_tag ? (
                        <InventoryTableCellAssetTag
                          assetId={asset.id}
                          assetName={asset.name}
                          assetTags={asset.tags}
                          key={`cell-asset-tag-${asset.id}`}
                        />
                      ) : column === columnEnum.related_apps ? (
                        <InventoryTableCellRelatedAppsCount
                          included_supply_chain_secure_details={asset?.included_supply_chain_secure_details}
                          key={`cell-related-apps-${asset.id}`}
                        />
                      ) : column === columnEnum.vendor ? (
                        <InventoryTableCellVendor
                          key={`cell-vendor-${asset.id}`}
                          vendor={asset.included_supply_chain_secure_details?.vendor}
                        />
                      ) : column === columnEnum.shadow_reasons ? (
                        <InventoryTableCellShadowReasons
                          key={`cell-shadow-reasons-${asset.id}`}
                          shadowReasons={asset.shadow_reasons}
                        />
                      ) : null,
                    )}
                  </TableRow>
                ))}
          </TableBody>
        </Table>
      </TableContainer>

      {loading ? (
        <Grid container justifyContent={'flex-end'} spacing={1}>
          <Grid item style={{ marginRight: 15 }}>
            <Skeleton animation="wave" height={45} width={120} />
          </Grid>
        </Grid>
      ) : data?.asset_list.pagination_information.total_count === 0 ? (
        <InventoryTableEmptyState variant={emptyStateVariant} />
      ) : (
        <TablePagination
          component="div"
          count={paginationCount}
          labelDisplayedRows={({ from, to, count }) => {
            const isInexact =
              paginationInformation?.is_approximate_total_count_enabled &&
              !paginationInformation?.exact_total_count &&
              paginationInformation.hint_for_total_count;

            return `${from}-${to} of ${count}${isInexact ? '+' : ''}`;
          }}
          // @ts-ignore
          nextIconButtonProps={loading || restProps.isDisabled ? { disabled: true } : null}
          onPageChange={handleChangePage}
          page={page}
          rowsPerPage={pageSize}
          rowsPerPageOptions={[]}
        />
      )}
    </>
  );

  return (
    <>
      {restProps.checkboxSelection &&
        (currentPageIdRangeChecked.length === pageSize ? (
          <Grid
            alignContent="center"
            alignItems="center"
            container
            justifyContent="center"
            spacing={0}
            style={{
              background: palette.gray50,
              marginBottom: 12,
              marginTop: 12,
              paddingBottom: 8,
              paddingLeft: 12,
              paddingTop: 8,
            }}
          >
            <Grid item xs={6}>
              <Text style={{ display: 'flex', margin: 0, whiteSpace: 'pre' }} variant="body">
                {'All '}
                <Text style={{ margin: 0 }} variant="titleXS">
                  {allAssetsCheckedCount
                    ? data?.asset_list.pagination_information?.total_count
                    : currentPageIdRangeChecked.length}
                </Text>
                {' matching assets '}
                {Boolean(!allAssetsCheckedCount) && 'on this page '}
                {'are selected'}
              </Text>
            </Grid>
            <Grid item xs={6}>
              {allAssetsCheckedCount ? (
                <Button
                  onClick={() => {
                    toggleAllAssetIdsWithinCurrentPage();
                  }}
                >
                  {'CLEAR SELECTION'}
                </Button>
              ) : (
                <Button
                  onClick={() => {
                    setAllAssetsCheckedCount &&
                      setAllAssetsCheckedCount(
                        allAssetsCheckedCount === data?.asset_list.pagination_information?.total_count
                          ? 0
                          : Number(data?.asset_list.pagination_information?.total_count),
                      );
                  }}
                >
                  {'SELECT ALL '}
                  {data?.asset_list.pagination_information?.total_count}
                  {' ASSETS'}
                </Button>
              )}
            </Grid>
          </Grid>
        ) : null)}

      {restProps.isCard ? <Card>{tableContainerFragment}</Card> : tableContainerFragment}
    </>
  );
};

export default memo(InventoryTableComponent);
