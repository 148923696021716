import { CircularProgress } from '@mui/material';
import Box from '@mui/material/Box';

const CenteredCircularProgress = () => (
  <Box alignItems="center" display="flex" justifyContent="center" style={{ height: '100%', width: '100%' }}>
    <CircularProgress color="primary" size={45} />
  </Box>
);

export default CenteredCircularProgress;
