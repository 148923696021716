import { memo } from 'react';
import ChatIcon from '@mui/icons-material/Chat';
import Box from '@mui/material/Box';
import { palette } from '@dt/theme';
import Text from './Text';

/*
 * An empty state for when there are no policy violation comments.
 *
 * @param variant - Center or inline component
 * @param gutterBottom - Whether or not to put a margin bottom on the end of the component
 * @param onAddNewClicked - Triggered when the user wants to add a new comment
 */
const PolicyViolationsCommentsListEmptyComponent = props => {
  if (props.variant === 'center') {
    return (
      <Box
        alignItems="center"
        bgcolor="unset"
        borderRadius={0}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        marginBottom={!props.gutterBottom ? 0 : 40}
        p={0}
        style={{
          minHeight: 210,
          textAlign: 'center',
        }}
      >
        <ChatIcon style={{ color: palette.gray20, fontSize: '4rem' }} />
        <Text
          style={{
            color: palette.gray35,
            marginLeft: '0px',
            marginTop: '0px',
            maxWidth: '400px',
          }}
          variant="body"
        >
          You can use this section to send messages to Data Theorem&apos;s support team or make notes
        </Text>
      </Box>
    );
  } else if (props.variant === 'inline') {
    return (
      <Box
        alignItems="center"
        bgcolor={palette.gray50}
        borderRadius={4}
        display="flex"
        flexDirection="row"
        justifyContent="flex-start"
        style={{
          marginBottom: !props.gutterBottom ? 0 : 40,
          minHeight: 0,
          padding: 24,
          textAlign: 'left',
        }}
      >
        <ChatIcon style={{ color: palette.gray20, fontSize: '2.5rem' }} />
        <Box display="flex" flexDirection="column" marginLeft={3}>
          <Text color={palette.gray30} style={{ margin: 0, padding: 0 }} variant="titleXS">
            Zero Technical Questions/Notes
          </Text>
          <Text
            color={palette.gray35}
            style={{
              margin: 0,
              padding: 0,
            }}
            variant="body"
          >
            Click{' '}
            <Text style={{ cursor: 'pointer' }} variant="link">
              <span onClick={props.onAddNewClicked}>here</span>
            </Text>{' '}
            to send technical questions to the AppSec Research team
          </Text>
        </Box>
      </Box>
    );
  }

  // Should never happen, props are explicitly required.
  // Any malformed props will be caught by the type checker.
  throw new Error('No "PolicyViolationsCommentsListEmpty" component found.');
};

export const PolicyViolationsCommentsListEmpty = memo(PolicyViolationsCommentsListEmptyComponent);
