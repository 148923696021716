import { memo } from 'react';
import { Button, Grid } from '@mui/material';
import Box from '@mui/material/Box';
import { palette } from '@dt/theme';

const ConfirmMessageComponent = function ConfirmMessage({ message, onConfirm, onCancel }) {
  return (
    <Box
      bgcolor={palette.yellow50}
      borderRadius={2}
      color={palette.gray20}
      p={2}
      whiteSpace="pre-line" // Used to allow messages to be multiline strings.
    >
      <Grid alignItems={'center'} container spacing={2}>
        <Grid item xs={12}>
          {message}
        </Grid>
        <Grid item md={2} xs={12}>
          <Button onClick={onConfirm} variant="contained">
            Confirm
          </Button>
        </Grid>
        <Grid item md={2} xs={12}>
          <Button onClick={onCancel} variant="contained">
            Cancel
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default memo(ConfirmMessageComponent);
