import { isAfter } from 'date-fns';
export function sortObjectsByDateProperty(propertyName = 'date', a, b) {
    if (!a && !b) {
        return 0;
    }
    else if (!a) {
        return 1;
    }
    else if (!b) {
        return -1;
    }
    if (isAfter(a[propertyName] || new Date(0), b[propertyName] || new Date(0))) {
        return -1;
    }
    else {
        return 1;
    }
}
