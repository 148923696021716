import { memo } from 'react';
import DoneIcon from '@mui/icons-material/Done';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { palette } from '@dt/theme';

const useStyles = makeStyles({
  icon: {
    borderRadius: 100,
    height: 24,
    width: 24,
  },
  protectedContainer: {
    '& p': {
      color: palette.green30,
    },
    '& svg': {
      color: palette.green30,
    },
    display: 'flex',
    padding: 4,
    width: 140,
  },
  text: {
    color: palette.white,
    paddingLeft: 2,
  },
  unprotectedContainer: {
    '& p': {
      color: palette.red30,
    },
    '& svg': {
      color: palette.red30,
      fontSize: 20,
    },
    display: 'flex',
    padding: 4,
    width: 140,
  },
  wontFixContainer: {
    '& p': {
      color: palette.gray30,
    },
    '& svg': {
      color: palette.gray30,
      fontSize: 20,
    },
    display: 'flex',
    padding: 4,
    width: 140,
  },
});

const ToolkitAssetTableLastScanStatus = ({ lastScanStatus }) => {
  const classes = useStyles();

  return (
    <>
      {lastScanStatus === 'Protected' ? (
        <div className={classes.protectedContainer}>
          <Typography className={classes.icon}>
            <DoneIcon />
          </Typography>
          <Typography className={classes.text}>Protected</Typography>
        </div>
      ) : null}
      {lastScanStatus === 'Unprotected' ? (
        <div className={classes.unprotectedContainer}>
          <Typography className={classes.icon}>
            <ReportProblemIcon />
          </Typography>
          <Typography className={classes.text}>Unprotected</Typography>
        </div>
      ) : null}
      {lastScanStatus === 'By Design' ? (
        <div className={classes.wontFixContainer}>
          <Typography className={classes.icon}>
            <ReportProblemIcon />
          </Typography>
          <Typography className={classes.text}>By Design</Typography>
        </div>
      ) : null}
    </>
  );
};

export default memo(ToolkitAssetTableLastScanStatus);
