import { memo } from 'react';
import { FormControl, FormHelperText, Grid, MenuItem, Select } from '@mui/material';
import { UTCToLocalTime } from '../../apps/ahura/src/util/UtcToLocalTime';
import { roundHour } from '../../apps/ahura/src/util/roundHour';
import { hoursOfDay } from '../../apps/ahura/src/util/hoursOfDay';

function TimeInput({ time, onChange, showLocalTime = false, variant, classes }) {
  return (
    <Grid container>
      <FormControl aria-label="time-choice" component="fieldset">
        <Select
          MenuProps={{ disablePortal: true }}
          classes={classes}
          displayEmpty
          onChange={e => onChange(e.target.value)}
          value={roundHour(time)}
          variant={variant}
        >
          <MenuItem value=""> --:-- </MenuItem>
          {hoursOfDay.map(([h, offset]) => (
            <MenuItem key={h + offset} value={h + offset}>
              {h} UTC
            </MenuItem>
          ))}
        </Select>
        {showLocalTime && <FormHelperText>{time.length ? `Local time: ${UTCToLocalTime(time)}` : ' '}</FormHelperText>}
      </FormControl>
    </Grid>
  );
}

export default memo(TimeInput);
