import { Checkbox, FormControlLabel } from '@mui/material';
import Box from '@mui/material/Box';
import { MobileAppReleaseTypeValues } from '@dt/graphql-support/enums';
import { Text } from '@dt/material-components';

export const PopoutFilterPluginMobileAppReleaseTypeDisplayLookup = {
  [MobileAppReleaseTypeValues.PRE_PROD]: 'PreProd Apps',
  [MobileAppReleaseTypeValues.APP_STORE]: 'Prod Apps',
  [MobileAppReleaseTypeValues.ENTERPRISE]: 'Corporate Apps',

  // NOTE: Excluded now until we know what to do with these.
  [MobileAppReleaseTypeValues.APP_STORE_THIRD_PARTY]: null,
};

/*
 * Popout filter plugin.
 * User can select mobile app release types.
 *
 * @param value - Current state.
 * @param onChange - State change.
 */
export const PopoutFilterPluginMobileAppReleaseType = ({ value, onChange }) => {
  return (
    <Box display="flex" flexDirection="column">
      {Object.keys(PopoutFilterPluginMobileAppReleaseTypeDisplayLookup)
        .filter(key => !!PopoutFilterPluginMobileAppReleaseTypeDisplayLookup[key])
        .map(key => {
          const val = PopoutFilterPluginMobileAppReleaseTypeDisplayLookup[key] || '';
          return (
            <FormControlLabel
              control={
                <Checkbox
                  checked={!!value[key]}
                  color="primary"
                  inputProps={{
                    'aria-label': `${val}`,
                  }}
                  onChange={e => {
                    (value =>
                      onChange(prev => ({
                        ...prev,

                        [key]: value,
                      })))(e.target.checked);
                  }}
                />
              }
              key={key}
              label={<Text variant="body">{val}</Text>}
            />
          );
        })}
    </Box>
  );
};
