import { PureComponent } from 'react';
import withMeasurement from './withMeasurement';

export default withMeasurement({
  fixedWidth: true,
})(
  class MeasureableHeightDiv extends PureComponent {
    render() {
      const { children, ...props } = this.props;
      return <div {...props}>{children}</div>;
    }
  },
);
