import { memo, useMemo, useState } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import { Card, CardContent, Grid, InputAdornment, InputBase } from '@mui/material';
import { makeStyles } from '@mui/styles';
import debounce from 'lodash/fp/debounce';

const useStyles = makeStyles({
  CardContent: props => ({
    '&:last-child': {
      paddingBottom: props.size == 'small' ? `0px 8px;` : 8,
    },
    padding: props.size == 'small' ? `0px 8px;` : 8,
  }),
});

export default memo(function KeyWordSearchInput({
  id,
  ariaLabel,
  autoFocus,
  fullWidth,
  value,
  onChange,
  placeholder,
  size,
}) {
  const classes = useStyles({ size });

  const [inputValue, setInputValue] = useState(value);

  const handleChange = useMemo(() => debounce(500)(value => onChange(value)), [onChange]);

  return (
    <Grid container>
      <Grid item xs={12}>
        <Card>
          <CardContent classes={{ root: classes.CardContent }}>
            <InputBase
              aria-label={ariaLabel}
              autoFocus={autoFocus}
              fullWidth={typeof fullWidth !== 'boolean' ? true : fullWidth}
              id={id}
              inputProps={{ 'data-testid': '_keyword-search' }}
              onChange={e => {
                setInputValue(e.target.value);
                handleChange(e.target.value);
              }}
              placeholder={placeholder || 'Keyword Search'}
              startAdornment={
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              }
              value={inputValue || ''}
            />
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
});
