import { memo } from 'react';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import TableCell from '@mui/material/TableCell';
import { Text } from '@dt/material-components';
import { dateFormats } from '../../util/dateFormats';
import { formatDateDefault } from '../../util/formatDateDefault';

const InventoryTableCellDiscoveryDateComponent = function InventoryTableCellDiscoveryDate(props) {
  if (props.loading) {
    return (
      <TableCell align={'left'} component="th">
        <Box display={'flex'}>
          <Skeleton animation="wave" height={28} variant="text" width={80} />
        </Box>
      </TableCell>
    );
  }

  const { date_created, align } = props;

  return (
    <TableCell align={align || 'center'} component="th">
      <Text component={'div'} variant={'body'}>
        {formatDateDefault({ date: date_created, formatStr: dateFormats.MMddyyyy })}
      </Text>
    </TableCell>
  );
};

export default memo(InventoryTableCellDiscoveryDateComponent);
