import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { memo, useEffect } from 'react';
import { Waypoint } from 'react-waypoint';
import { Checkbox, ListItem } from '@mui/material';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import { makeStyles } from '@mui/styles';
// @ts-ignore JS component
import { useLazyQuery } from '@dt/apollo-link-schema-rest';
// @ts-ignore JS component
import { ErrorState } from '@dt/components';
// @ts-ignore JS component
import asset_tags from '@dt/graphql-support/horizon/asset_tags';
// @ts-ignore JS component
import { Text } from '@dt/material-components';
const useStyles = makeStyles(() => ({
    item: {
        alignItems: 'center',
    },
}));
const TableFiltersAssetTagKeyValues = ({ assetTagKey, selectedAssetTags, onToggleAssetTagValue, setTotalValues, }) => {
    const classes = useStyles();
    const shouldSelectAllValues = selectedAssetTags[assetTagKey.name] === null;
    const [fetchAssetTagValuesQuery, { data, error, loading, called, fetchMore, pageNumber }] = useLazyQuery(asset_tags.values.list, {
        fetchPolicy: 'network-only',
    });
    useEffect(() => {
        fetchAssetTagValuesQuery({
            variables: {
                tag_uuid: assetTagKey.id,
            },
        });
    }, [fetchAssetTagValuesQuery, called, assetTagKey.id]);
    useEffect(() => {
        if (data?.asset_tag_value_list?.pagination_information?.total_count) {
            setTotalValues(Number(data?.asset_tag_value_list?.pagination_information?.total_count));
        }
    }, [data]);
    return (_jsxs(_Fragment, { children: [error ? (_jsx(ErrorState, { error: error })) : !pageNumber && loading ? ([0, 1, 2, 3, 4].map((_, key) => (_jsx(ListItem, { button: true, dense: true, children: _jsx(Skeleton, { animation: "wave", height: 40, width: `100%` }) }, key)))) : !data?.asset_tag_value_list?.asset_tags?.filter((assetTag) => assetTag.value).length ? (_jsx(Box, { ml: 4, children: _jsx(Text, { component: 'div', noWrap: true, children: "There is no value for this asset tag." }) })) : (data?.asset_tag_value_list?.asset_tags.map((assetTag, key) => assetTag?.value && (_jsxs(ListItem, { button: true, className: classes.item, dense: true, onClick: () => onToggleAssetTagValue(assetTag.tag, assetTag.value), children: [_jsx(Checkbox, { checked: Boolean(selectedAssetTags[assetTag.tag] === null) ||
                            Boolean(selectedAssetTags[assetTag.tag]?.includes(assetTag.value)), color: shouldSelectAllValues ? 'default' : 'primary', style: { opacity: shouldSelectAllValues ? '0.4' : '1' } }), _jsx(Text, { component: 'div', noWrap: true, children: assetTag.value })] }, key)))), fetchMore && (_jsxs(_Fragment, { children: [_jsxs(Box, { component: 'div', children: [_jsx(ListItem, { button: true, dense: true, children: _jsx(Skeleton, { animation: "wave", height: 40, width: `100%` }) }), _jsx(ListItem, { button: true, dense: true, children: _jsx(Skeleton, { animation: "wave", height: 40, width: `100%` }) }), _jsx(ListItem, { button: true, dense: true, children: _jsx(Skeleton, { animation: "wave", height: 40, width: `100%` }) })] }), _jsx(Waypoint, { onEnter: () => {
                            if (fetchMore) {
                                fetchMore();
                            }
                        } })] }))] }));
};
export default memo(TableFiltersAssetTagKeyValues);
