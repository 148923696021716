import { memo } from 'react';
import { Grid, useTheme } from '@mui/material';
import { Text } from '@dt/material-components';
import Log4ShellDiagram from '@dt/material-components/svg_components/Log4ShellDiagram';

const PolicyRuleTypesCardLog4ShellDiagram = () => {
  const { palette } = useTheme();
  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Text variant={'body'}>How Data Theorem Analyzer Scans Log4j:</Text>
      </Grid>
      <Grid item xs={12}>
        <Log4ShellDiagram style={{ border: `1px solid ${palette.gray[40]}` }} />
      </Grid>
    </Grid>
  );
};

export default memo(PolicyRuleTypesCardLog4ShellDiagram);
