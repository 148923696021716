import { memo } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { getBadgeDescriptionFromBadgeType } from '@dt/components';
import Header from '@dt/components/src/Header';
import ProtectionBadge from '@dt/components/src/app-protection-badges/Badge';
import { fontFamilies, fontSizes, palette } from '@dt/theme';
import AppProtectionTaskCard from './AppProtectionTaskCard';

const useStyles = makeStyles({
  badge: {
    height: 83,
    margin: '0 auto',
    width: 83,
  },
  buttonIcon: {
    marginLeft: 4,
  },
  content: {
    backgroundColor: palette.tableDark,
    fontFamily: fontFamilies.primary,
    marginTop: 50,
    padding: '10px 80px',
    paddingBottom: 50,
    paddingTop: 50,
  },
  description: {
    marginBottom: 40,
    marginTop: 10,
    textAlign: 'center',
  },
  dialogSectionSubtitle: {
    display: 'inline-block',
    float: 'right',
    fontSize: fontSizes.medium,
  },
  dialogSectionTitle: {
    display: 'inline-block',
    fontSize: fontSizes.medium,
    margin: 0,
    marginBottom: 10,
  },
  title: {
    textAlign: 'center',
  },
});

export default memo(function AppProtectionBadgeTasksDialog({ tasksForBadge, onClose, badgeType, enabled }) {
  const classes = useStyles();
  const description = badgeType && getBadgeDescriptionFromBadgeType(badgeType);

  return (
    <>
      <Header
        secondaryNavigationItems={
          <Button onClick={onClose} variant="outlined">
            Close <CloseIcon className={classes.buttonIcon} />
          </Button>
        }
        style={{
          wrapper: {
            backgroundColor: palette.darkBg,
            color: palette.inacitveColor,
            justifyContent: 'space-between',
            paddingLeft: 10,
          },
        }}
      >
        <div style={{ alignItems: 'center', display: 'flex' }}>{}</div>
      </Header>
      <div className={classes.content}>
        <h2 className={classes.title}>
          {tasksForBadge.length > 1 ? 'Tasks ' : 'Task '}
          {'For Badge'}
        </h2>

        <div>
          <div className={classes.badge}>
            <ProtectionBadge enabled={enabled} type={badgeType} />
          </div>

          <div className={classes.description}>{description}</div>
        </div>

        {tasksForBadge.map((task, i) => (
          <div key={task.issue_type_id} style={{ marginBottom: 10 }}>
            {i === 0 && enabled && (
              <div>
                <span className={classes.dialogSectionTitle}>Completed</span>
                <span className={classes.dialogSectionSubtitle}>Included in score</span>
              </div>
            )}
            {i === 0 && !enabled && task.security_finding_id && (
              <div>
                <span className={classes.dialogSectionTitle}>Incomplete</span>
                <span className={classes.dialogSectionSubtitle}>Complete to earn badge</span>
              </div>
            )}
            {i === 0 && !enabled && !task.security_finding_id && (
              <div>
                <span className={classes.dialogSectionTitle}>Not Applicable</span>
              </div>
            )}
            <AppProtectionTaskCard key={task.issue_type_id} task={task} />
          </div>
        ))}
      </div>
    </>
  );
});
