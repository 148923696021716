import { memo } from 'react';
import { TableCell } from '@mui/material';
import InventoryLayoutApiAssetsTabTableRowTags from '../../../layouts/inventory/InventoryLayoutApiAssetsTabTableRowTags';

const InventoryTableCellShadowReasons = ({ shadowReasons }) => {
  if (!shadowReasons) return null;

  return (
    <TableCell>
      <InventoryLayoutApiAssetsTabTableRowTags
        largeTags
        tags={shadowReasons.map(a => ({
          id: a.shadow_reason,
          tag: a.shadow_reason_name,
        }))}
      />
    </TableCell>
  );
};

export default memo(InventoryTableCellShadowReasons);
