import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import { useEffect } from 'react';
// @ts-ignore: @reach/router types
import { useLocation } from '@reach/router';
const ScrollToTopOnNavigate = () => {
    const { pathname } = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);
    return _jsx(_Fragment, {});
};
export default ScrollToTopOnNavigate;
