import { memo, useEffect, useState } from 'react';
import ErrorIcon from '@mui/icons-material/Error';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import { useLazyQuery } from '@apollo/client';
import { Tag } from '@components';
import assets from '@dt/graphql-support/horizon/assets';
import { Text } from '@dt/material-components';
import useGetHorizonAssetIdFromAppId from '@dt/material-components/hooks/use_get_horizon_asset_id_from_app_id';
import { palette } from '@dt/theme';
import AssetTagsEditDialog from './AssetTagsEditDialog';

const AssetTagList = props => {
  const [isAssetTagsEditDialogOpen, setAssetTagsEditDialogOpen] = useState(false);

  const [horizonId, setHorizonId] = useState(props.assetId || null);

  const mobileApp = useGetHorizonAssetIdFromAppId(props?.appId || null);

  useEffect(() => {
    if (mobileApp?.id) {
      setHorizonId(mobileApp?.id);
    }
  }, [mobileApp]);

  const [assetTagQuery, { data, loading, error }] = useLazyQuery(assets.asset_tags.get);

  useEffect(() => {
    if (horizonId) {
      assetTagQuery({
        variables: {
          uuid: horizonId,
        },
      });
    }
  }, [assetTagQuery, props, horizonId]);

  if (loading || mobileApp?.loading) {
    return (
      <Box display={'flex'}>
        <Box ml={1}>
          <Skeleton animation="wave" height={40} variant="text" width={80} />
        </Box>
        <Box ml={1}>
          <Skeleton animation="wave" height={40} variant="text" width={80} />
        </Box>
        <Box ml={1}>
          <Skeleton animation="wave" height={40} variant="text" width={80} />
        </Box>
      </Box>
    );
  }

  if (mobileApp?.error) {
    const error = mobileApp?.error;
    return (
      <Tag
        backgroundColor={palette.red30}
        color={palette.white}
        icon={<ErrorIcon />}
        label={error.message.replace('GraphQL error: ', '')}
      />
    );
  }

  if (error) {
    return (
      <Tag
        backgroundColor={palette.red30}
        color={palette.white}
        icon={<ErrorIcon />}
        label={error.message.replace('GraphQL error: ', '')}
      />
    );
  }

  if (!props.assetId && !mobileApp?.id) {
    return <Tag backgroundColor={palette.red30} color={palette.white} icon={<ErrorIcon />} label={'Unknown Error'} />;
  }

  return (
    <>
      <Box display={'flex'} flexWrap={'wrap'}>
        {data?.asset_tags?.asset_tags?.map(assetTag => (
          <Box key={assetTag.id} onClick={() => setAssetTagsEditDialogOpen(true)} style={{ cursor: 'pointer' }}>
            <Tag label={`${assetTag.tag}${assetTag.value ? `:${assetTag.value}` : ''}`} maxWidth={100} />
          </Box>
        ))}
        {!data?.asset_tags?.asset_tags?.length ? (
          <Box onClick={() => setAssetTagsEditDialogOpen(true)} style={{ cursor: 'pointer' }}>
            <Text component={'div'} variant={'link'}>
              Add New
            </Text>
          </Box>
        ) : null}
      </Box>
      {mobileApp?.id && props.appName ? (
        <AssetTagsEditDialog
          assetId={mobileApp?.id}
          assetName={props.appName}
          isOpen={isAssetTagsEditDialogOpen}
          onClose={() => setAssetTagsEditDialogOpen(false)}
        />
      ) : null}
      {props.assetId && props.assetName ? (
        <AssetTagsEditDialog
          assetId={props.assetId}
          assetName={props.assetName}
          isOpen={isAssetTagsEditDialogOpen}
          onClose={() => setAssetTagsEditDialogOpen(false)}
        />
      ) : null}
    </>
  );
};

export default memo(AssetTagList);
