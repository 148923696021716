import { formatDistanceToNow, isDate, isSameDay, isThisYear, parse } from 'date-fns';
import { formatDateDefault } from './formatDateDefault';
import { ISO8601UTCStringCast } from './iso8601UtcStringCast';
import { dateFormats } from './dateFormats';
export function formattedFromNow(formats = {}) {
    const { short, long } = formats;
    return function fromNow(date) {
        const dateParsed = isDate(date) ? date : parse(ISO8601UTCStringCast(date), dateFormats.MMddyyyy, new Date());
        if (isSameDay(new Date(), dateParsed)) {
            return formatDistanceToNow(dateParsed, {
                addSuffix: true,
                includeSeconds: true,
            });
        }
        else if (isThisYear(dateParsed)) {
            return formatDateDefault({ date: dateParsed, formatStr: short || 'MMM d' });
        }
        else {
            return formatDateDefault({ date: dateParsed, formatStr: long || dateFormats.yyyyMMdd });
        }
    };
}
