import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext, useEffect, useRef } from 'react';
import { useQuery } from '@apollo/client';
import Text from '@dt/ahura/src/components/Text';
import { shadow_assets_stats } from '@dt/graphql-support/horizon/ts/analytics';
import { formatDateDefault } from '@utils/formatDateDefault';
import useRefWidth from '../../../hooks/use_ref_width';
import { ShadowAssetsContext } from './ApiSecureShadowAssetsPage';
import ApiShadowAssetsPageChartDonut from './ApiShadowAssetsPageChartDonut';
const ApiShadowAssetsPageChartDonutContainer = () => {
    const divRef = useRef(null);
    const { width } = useRefWidth({ ref: divRef });
    const { isPolling, latestRefetchDate, filter_by_named_filter } = useContext(ShadowAssetsContext);
    const { data, loading: loadingQuery, error, refetch, } = useQuery(shadow_assets_stats, {
        variables: {
            filter_by_named_filter: filter_by_named_filter || null,
        },
    });
    const loading = loadingQuery || isPolling;
    useEffect(() => {
        refetch();
    }, [latestRefetchDate, refetch]);
    return (_jsxs("div", { children: [_jsx(Text, { component: "div", style: { marginBottom: 8 }, variant: "titleXS", children: "Total shadow apps/APIs have been found in a variety of places:" }), _jsx(Text, { component: "div", style: { marginBottom: 24 }, variant: "helperText", children: `as of today import ${formatDateDefault({ date: new Date() })}` }), _jsx(ApiShadowAssetsPageChartDonut, { data: data, error: error, loading: loading, width: width })] }));
};
export default ApiShadowAssetsPageChartDonutContainer;
