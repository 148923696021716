import { jsx as _jsx } from "react/jsx-runtime";
import { useState } from 'react';
import { animated, to, useTransition } from '@react-spring/web';
const fromLeaveTransition = ({ endAngle }) => ({
    endAngle: endAngle > Math.PI ? 2 * Math.PI : 0,
    opacity: 0,
    // enter from 360° if end angle is > 180°
    startAngle: endAngle > Math.PI ? 2 * Math.PI : 0,
});
const enterUpdateTransition = ({ startAngle, endAngle }) => ({
    endAngle,
    opacity: 1,
    startAngle,
});
const transitionDuration = 300;
function AnimatedPie({ arcs, path, getKey, getColor, onClickDatum, onTouchStart, onTouchMove, onMouseMove, onMouseLeave, setActiveArc, }) {
    const [shouldHaveHoverTransition, setShouldHaveHoverTransition] = useState(false);
    const transitions = useTransition(arcs, {
        config: {
            duration: transitionDuration,
        },
        enter: enterUpdateTransition,
        from: fromLeaveTransition,
        keys: getKey,
        leave: fromLeaveTransition,
        update: enterUpdateTransition,
    });
    return transitions((props, arc, { key }) => {
        setTimeout(() => {
            setShouldHaveHoverTransition(true);
        }, transitionDuration + 200);
        return (_jsx("g", { children: _jsx(animated.path
            // compute interpolated path d attribute from intermediate angle values
            , { 
                // compute interpolated path d attribute from intermediate angle values
                d: to([props.startAngle, props.endAngle], (startAngle, endAngle) => path({
                    ...arc,
                    endAngle,
                    startAngle,
                })), fill: getColor(arc), onClick: () => onClickDatum(arc), onMouseLeave: event => {
                    setActiveArc(null);
                    return onMouseLeave(arc.data)(event);
                }, onMouseMove: event => {
                    setActiveArc(arc.data);
                    return onMouseMove(arc.data)(event);
                }, onTouchMove: event => {
                    setActiveArc(arc.data);
                    return onTouchMove(arc.data)(event);
                }, onTouchStart: event => {
                    setActiveArc(arc.data);
                    return onTouchStart(arc.data)(event);
                }, style: {
                    transition: shouldHaveHoverTransition ? 'all 0.1s ease-in-out' : '',
                } }) }, key));
    });
}
export default AnimatedPie;
