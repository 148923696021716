import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext, useEffect, useState } from 'react';
import DoneIcon from '@mui/icons-material/Done';
import { Button, Chip, CircularProgress, Grid } from '@mui/material';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import { useMutation, useQuery } from '@apollo/client';
import { Message } from '@components';
import Text from '@dt/ahura/src/components/Text';
import { get, put } from '@dt/graphql-support/horizon/ts/configurations_shadow';
import { ShadowAssetsContext } from './ApiSecureShadowAssetsPage';
const ApiShadowAssetsPageChartConfigurationItems = () => {
    const { setBulkId, isPolling } = useContext(ShadowAssetsContext);
    const [initialConfig, setInitialConfig] = useState({});
    const [togglableConfig, setTogglableConfig] = useState({});
    const [hasChanged, setChanged] = useState(false);
    const { data, error, loading } = useQuery(get);
    const [putConfigurationsShadow, { loading: putConfigurationsLoading }] = useMutation(put, {
        update: (cache, result) => {
            if (result?.errors) {
                return;
            }
            const bulkId = result.data?.configurations_shadow_put.bulk_action_id;
            if (bulkId) {
                setBulkId(bulkId);
            }
            setChanged(false);
            setInitialConfig(togglableConfig);
        },
    });
    const cancel = () => {
        setTogglableConfig(initialConfig);
        setChanged(false);
    };
    const handleApplyClick = () => {
        putConfigurationsShadow({
            variables: {
                body: {
                    configuration: {
                        approved_app_stores: [
                            ...(!togglableConfig['Apple - App Store'] ? ['APPLE_APP_STORE'] : []),
                            ...(!togglableConfig['Google - Play Store'] ? ['GOOGLE_PLAY_STORE'] : []),
                        ],
                        approved_asset_types: [
                            ...(!togglableConfig['GraphQL APIs'] ? ['GRAPHQL_API'] : []),
                            ...(!togglableConfig['RESTful APIs'] ? ['RESTFUL_API'] : []),
                            ...(!togglableConfig['Single Page Applications'] ? ['WEB_APPLICATION'] : []),
                            ...(!togglableConfig['Mobile Applications'] ? ['MOBILE_APPLICATION'] : []),
                            ...(!togglableConfig['API Operations'] ? ['API_OPERATION'] : []),
                            ...(!togglableConfig['Cloud Resources'] ? ['CLOUD_RESOURCE'] : []),
                            ...(!togglableConfig['Domains'] ? ['DOMAIN_NAME'] : []),
                            ...(!togglableConfig['Web Servers'] ? ['NETWORK_SERVICE'] : []),
                        ],
                        approved_cloud_providers: [
                            ...(!togglableConfig['Unknown Host'] ? ['UNKNOWN'] : []),
                            ...(!togglableConfig['Amazon Web Services (AWS)'] ? ['AMAZON_WEB_SERVICES'] : []),
                            ...(!togglableConfig['Google Cloud Platform (GCP)'] ? ['GOOGLE_CLOUD_PLATFORM'] : []),
                            ...(!togglableConfig['Microsoft Azure'] ? ['AZURE'] : []),
                            ...(!togglableConfig['Apigee'] ? ['APIGEE'] : []),
                            ...(!togglableConfig['Mulesoft'] ? ['MULESOFT'] : []),
                            ...(!togglableConfig['Kong'] ? ['KONG'] : []),
                            ...(!togglableConfig['Axway'] ? ['AXWAY'] : []),
                            ...(!togglableConfig['On-prem'] ? ['ON_PREMISE'] : []),
                        ],
                        // Not supported.
                        public_asm_enabled: true,
                        should_mark_new_apis_not_going_through_gateway: togglableConfig['APIs Detached from Gateway'],
                        should_mark_new_assets_except_on_approved_asset_types: true,
                        should_mark_new_cloud_resources_except_on_approved_cloud_providers: true,
                        should_mark_new_mobile_apps_except_on_approved_app_stores: true,
                        should_mark_new_mobile_apps_on_3rd_party_stores: togglableConfig['Mobile Apps on 3rd Party Stores'],
                        should_mark_new_mobile_apps_on_official_stores: togglableConfig['Mobile Apps on Official Stores'],
                        should_mark_new_mobile_apps_on_unofficial_app_stores: togglableConfig['Unofficial App Stores'],
                        should_mark_new_network_services_with_dev_in_url: togglableConfig['Domains with DEV in URL'],
                        should_mark_new_network_services_with_preprod_in_url: togglableConfig['Domains with PREPROD in URL'],
                        should_mark_new_network_services_with_uat_in_url: togglableConfig['Domains with UAT in URL'],
                    },
                },
            },
        });
    };
    useEffect(() => {
        const initialBulkId = data?.configurations_shadow_get.bulk_action_id;
        setBulkId(initialBulkId || '');
    }, [data, setBulkId]);
    useEffect(() => {
        const config = data?.configurations_shadow_get.configuration;
        if (!config)
            return;
        const initialConfig = {
            'API Operations': !config.approved_asset_types.includes('API_OPERATION'),
            'APIs Detached from Gateway': config.should_mark_new_apis_not_going_through_gateway,
            'Amazon Web Services (AWS)': !config.approved_cloud_providers.includes('AMAZON_WEB_SERVICES'),
            Apigee: !config.approved_cloud_providers.includes('APIGEE'),
            // Approved App Stores
            'Apple - App Store': !config.approved_app_stores.includes('APPLE_APP_STORE'),
            Axway: !config.approved_cloud_providers.includes('AXWAY'),
            'Cloud Resources': !config.approved_asset_types.includes('CLOUD_RESOURCE'),
            Domains: !config.approved_asset_types.includes('DOMAIN_NAME'),
            'Domains with DEV in URL': config.should_mark_new_network_services_with_dev_in_url,
            'Domains with PREPROD in URL': config.should_mark_new_network_services_with_preprod_in_url,
            // Marks
            'Domains with UAT in URL': config.should_mark_new_network_services_with_uat_in_url,
            'Google - Play Store': !config.approved_app_stores.includes('GOOGLE_PLAY_STORE'),
            'Google Cloud Platform (GCP)': !config.approved_cloud_providers.includes('GOOGLE_CLOUD_PLATFORM'),
            'GraphQL APIs': !config.approved_asset_types.includes('GRAPHQL_API'),
            Kong: !config.approved_cloud_providers.includes('KONG'),
            'Microsoft Azure': !config.approved_cloud_providers.includes('AZURE'),
            'Mobile Applications': !config.approved_asset_types.includes('MOBILE_APPLICATION'),
            'Mobile Apps on 3rd Party Stores': config.should_mark_new_mobile_apps_on_3rd_party_stores,
            'Mobile Apps on Official Stores': config.should_mark_new_mobile_apps_on_official_stores,
            Mulesoft: !config.approved_cloud_providers.includes('MULESOFT'),
            'On-prem': !config.approved_cloud_providers.includes('ON_PREMISE'),
            'RESTful APIs': !config.approved_asset_types.includes('RESTFUL_API'),
            'Single Page Applications': !config.approved_asset_types.includes('WEB_APPLICATION'),
            // Approved Cloud Providers
            'Unknown Host': !config.approved_cloud_providers.includes('UNKNOWN'),
            'Unofficial App Stores': config.should_mark_new_mobile_apps_on_unofficial_app_stores,
            'Web Servers': !config.approved_asset_types.includes('NETWORK_SERVICE'),
        };
        setInitialConfig(initialConfig);
        setTogglableConfig(initialConfig);
    }, [data]);
    if (loading) {
        return _jsx(Skeleton, { height: 50 });
    }
    if (error) {
        return _jsx(Message, { m: 0, message: error.message, variant: 'error' });
    }
    if (!data) {
        // Shouldn't happen.
        return null;
    }
    return (_jsxs(Grid, { container: true, spacing: 2, children: [_jsx(Grid, { item: true, xs: 12, children: _jsx(Text, { component: "div", variant: "titleXS", children: "Choose one or multiple rules to define shadow assets" }) }), _jsx(Grid, { item: true, xs: 12, children: Object.keys(togglableConfig).map(key => (_jsx(Box, { display: "inline-block", mb: 1, mr: 1, children: _jsx(Chip, { icon: togglableConfig[key] ? _jsx(DoneIcon, {}) : _jsx(_Fragment, {}), label: key, onClick: () => {
                            if (!hasChanged) {
                                setChanged(true);
                            }
                            setTogglableConfig(config => ({
                                ...config,
                                [key]: !config[key],
                            }));
                        }, size: "medium", style: { cursor: 'pointer' }, variant: togglableConfig[key] ? undefined : 'outlined' }) }, key))) }), _jsx(Grid, { item: true, xs: 12, children: _jsxs(Box, { mt: 1, children: [_jsx(Button, { color: "primary", disabled: !hasChanged || putConfigurationsLoading || isPolling, onClick: () => {
                                handleApplyClick();
                            }, size: "small", startIcon: putConfigurationsLoading || isPolling ? _jsx(CircularProgress, { size: 16 }) : null, style: { marginRight: 8 }, variant: "contained", children: "Apply" }), _jsx(Button, { disabled: !hasChanged || putConfigurationsLoading || isPolling, onClick: () => {
                                cancel();
                            }, size: "small", variant: "contained", children: "Cancel" })] }) })] }));
};
export default ApiShadowAssetsPageChartConfigurationItems;
